import { action, computed, decorate, observable } from 'mobx';
import debounce from 'lodash/debounce';
import { deleteMaterial, saveMaterial } from '../../../api';

class Material {
  id = null;

  stage = null;
  orderNr = null;
  type = null;
  name = '';
  instructions = '';

  textContent = '';
  youtubeId = '';
  videoStart = null;
  videoEnd = null;
  imageUrl = '';
  pdfUrl = '';
  embedSrc = '';
  embedType = '';

  error = null;

  store = null;

  static MAX_TEXT_CONTENT_BYTES = 4 * 1024 * 1024;

  constructor(store, id, stage, json) {
    this.id = id;
    this.store = store;
    this.stage = stage;
    this.updateFromJson(json);
  }

  isValid() {
    if (this.type && this.type === 'TEXT') {
      let textContentSize = new Blob([this.textContent]).size;
      if (textContentSize > Material.MAX_TEXT_CONTENT_BYTES) {
        this.setError({
          message:
            'Materjali ei salvestatud, kuna sisu on liiga suur (üle 4 MB)'
        });
        return false;
      }
    }
    if (this.type && this.type === 'EMBED') {
      return !!this.embedType && !!this.embedSrc;
    }
    let isValid = !(!this.type || !this.orderNr);
    if (isValid) this.setError(null);
    return isValid;
  }

  setError(error) {
    this.error = error;
  }

  persistChanges = debounce(async () => {
    if (!this.isValid()) return;
    const { data } = await saveMaterial(this.asJson);
    if (typeof this.id === 'string') {
      this.id = data.id;
    }
  }, 500);

  updateFromJson(json) {
    this.orderNr = json.orderNr;
    this.name = json.name ? json.name : '';
    this.type = json.type;
    this.instructions = json.instructions ? json.instructions : '';
    this.textContent = json.textContent ? json.textContent : '';
    this.youtubeId = json.youtubeId;
    this.videoStart = json.videoStart;
    this.videoEnd = json.videoEnd;
    this.imageUrl = json.imageUrl;
    this.pdfUrl = json.pdfUrl;
    this.embedSrc = json.embedSrc;
    this.embedType = json.embedType;
  }

  get asJson() {
    return {
      id: typeof this.id === 'string' ? null : this.id,
      stageId: this.stage.id,
      type: this.type,
      instructions: this.instructions,
      name: this.name,
      orderNr: this.orderNr,
      textContent: this.textContent,
      youtubeId: this.youtubeId,
      videoStart: this.videoStart,
      videoEnd: this.videoEnd,
      imageUrl: this.imageUrl,
      pdfUrl: this.pdfUrl,
      embedSrc: this.embedSrc,
      embedType: this.embedType
    };
  }

  get previousVideo() {
    const previousStageVideo = this.stage.orderedMaterials
      .filter(m => m.type === 'VIDEO' && m.orderNr < this.orderNr)
      .slice(-1)[0];
    if (previousStageVideo) return previousStageVideo;
    const reversedPreviousStages = this.stage.lesson.orderedStages
      .slice()
      .reverse()
      .filter(s => s.orderNr < this.stage.orderNr);
    for (const stage of reversedPreviousStages) {
      let latestVideo = stage.latestVideo;
      if (latestVideo) return latestVideo;
    }
    return null;
  }

  updateField(name, value, persistChanges = true) {
    this[name] = value;
    // Not using reactions as there doesn't seem to be a good way to disable them for some updates.
    // This is needed for sending the changing of one materials orderNr to server
    // and inferring the changed orderNrs of other materials in the client.
    // See: https://github.com/mobxjs/mobx/issues/823#issuecomment-282541165
    if (persistChanges) this.persistChanges();
  }

  async remove() {
    if (typeof this.id !== 'string') {
      await deleteMaterial(this.id);
    }
    this.stage.removeMaterial(this);
  }
}

export default decorate(Material, {
  id: observable,
  stage: observable,
  orderNr: observable,
  type: observable,
  name: observable,
  instructions: observable,
  textContent: observable,
  youtubeId: observable,
  videoStart: observable,
  videoEnd: observable,
  imageUrl: observable,
  pdfUrl: observable,
  embedSrc: observable,
  embedType: observable,
  error: observable,

  setError: action,
  updateField: action,
  remove: action,

  asJson: computed,
  previousVideo: computed
});
