import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

const LANG_STORAGE_KEY = 'masteryLang';

export function getLanguage() {
  let langFromStorage = localStorage.getItem(LANG_STORAGE_KEY);
  return langFromStorage ? langFromStorage : 'et';
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector) // TODO Turn detector back on once EN is translated
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'et',
    lng: getLanguage(),
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    load: 'languageOnly',
    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
      useSuspense: false // TODO See: https://react.i18next.com/latest/usetranslation-hook#not-using-suspense
    }
  });

i18n.on('languageChanged', lang => {
  localStorage.setItem(LANG_STORAGE_KEY, lang);
});
