import * as Sentry from '@sentry/browser';
import React from 'react';
import { Button, Container, Message } from 'semantic-ui-react';

class AppErrorBoundary extends React.Component {
  state = { error: null, eventId: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { error, eventId } = this.state;
    if (error) {
      //render fallback UI
      return (
        <Container>
          <Message negative>
            <Message.Header>Vabandame, rakenduses esines viga</Message.Header>
            <p>
              Kui leiad aega, siis oleksime tänulikud kui Sa kirjeldaksid mida
              enne vea esinemist tegid.
            </p>
            <Button
              onClick={() => Sentry.showReportDialog({ eventId, lang: 'et' })}
            >
              Anna tagasiside
            </Button>
            <p>
              Avalehele naasemiseks vajuta <a href="/">siia</a>.
            </p>
          </Message>
        </Container>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default AppErrorBoundary;
