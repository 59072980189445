import { Quill } from 'react-quill';

initImageBlot();

function initImageBlot() {
  let BlockEmbed = Quill.import('blots/block/embed');

  class ImageBlot extends BlockEmbed {
    static create(value) {
      let node = super.create();
      node.setAttribute('alt', value.alt ? value.alt : 'Kirjelduseta pilt');
      node.setAttribute('src', value.src);
      return node;
    }

    static value(node) {
      return {
        alt: node.getAttribute('alt'),
        src: node.getAttribute('src')
      };
    }
  }

  ImageBlot.blotName = 'image';
  ImageBlot.tagName = 'img';
  Quill.register(ImageBlot, true);
}

export const TOOLBAR_CONTAINER = [
  [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ['link', 'image'],
  ['clean']
];

export const FORMATS = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
];
