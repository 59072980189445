import { isInIframe, postMessageToParent } from './iframeUtils';

function sendLTIStartEvent(performanceId) {
  postMessageToParent({
    type: 'START',
    performanceId
  });
}

function sendLTIFinishEvent(performanceId) {
  postMessageToParent({
    type: 'FINISH',
    performanceId
  });
}

function initLTIListener(handler) {
  if (isInIframe()) {
    window.addEventListener('message', function(evt) {
      if (evt.origin !== process.env.REACT_APP_MASTERY_API) {
        return;
      }
      if (typeof handler === 'function') {
        handler(evt.data);
      }
    });
  }
}

function isLTILaunchError(originEventType) {
  return [LTIEventType.START, LTIEventType.FINISH].includes(originEventType);
}

const LTIEventType = {
  START: 'START',
  FINISH: 'FINISH',
  DEEPLINK: 'DEEPLINK'
};

export {
  sendLTIStartEvent,
  sendLTIFinishEvent,
  initLTIListener,
  isLTILaunchError
};
