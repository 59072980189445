import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router';

class SaveGroupButton extends Component {
  handleSave = e => {
    if (this.props.match.params.id) {
      this.props.groupStore.updateGroup(this.props.match.params.id).then(() => {
        this.props.appState.addMessage({
          success: true,
          text: 'Grupp salvestatud'
        });
      });
    } else {
      this.props.groupStore.saveGroup().then(response => {
        this.props.history.push(`/groups/${response.data}`);
      });
    }
  };
  render() {
    return <Button primary content="Salvesta" onClick={this.handleSave} />;
  }
}

export default withRouter(inject('groupStore', 'appState')(SaveGroupButton));
