import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

function InfoIcon({ content }) {
  return !content ? null : (
    <Popup
      position="top center"
      content={content}
      basic
      openOnTriggerMouseEnter={true}
      openOnTriggerClick={true}
      trigger={
        <Icon
          name="question circle outline"
          style={{ pointerEvents: 'auto' }}
        />
      }
    />
  );
}

export default InfoIcon;
