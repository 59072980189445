import axios from 'axios';

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_MASTERY_API
      : ''
});

const API = {
  get: (path, config) => {
    return axiosInstance.get(path, config);
  },

  post: (path, data, config) => {
    return axiosInstance.post(path, data, config);
  },

  put: (path, data, config) => {
    return axiosInstance.put(path, data, config);
  },

  delete: (path, config) => {
    return axiosInstance.delete(path, config);
  }
};

export default API;
