import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import PdfViewer from '../../common/PdfViewer';

function PerformancePdf({ material }) {
  return (
    <Container>
      <PdfViewer file={material.pdfUrl} />
    </Container>
  );
}

PerformancePdf.propTypes = {
  material: PropTypes.object.isRequired
};

export default observer(PerformancePdf);
