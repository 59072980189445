import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Input, Message } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';

import Materials from './Materials';
import Questions from './Questions';
import FlexAccordionTitle from '../FlexAccordionTitle';
import DeleteButton from '../DeleteButton';
import DragHandle from '../DragHandle';
import { withTranslation } from 'react-i18next';
import InfoIcon from '../../../common/InfoIcon';

const StageContentContainer = styled(Accordion.Content)`
  padding: 1em !important;
  border-radius: 0.28571429rem;
`;

const InputWithInfoIcon = styled(Input)`
  & > .ui.icon.input > i.icon {
    pointer-events: auto !important;
  }
`;

const stageSource = {
  beginDrag(props, monitor, component) {
    const boundingRect = findDOMNode(component).getBoundingClientRect();
    return {
      id: props.id,
      index: props.index,
      width: boundingRect.width,
      height: boundingRect.height
    };
  }
};

const stageTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.onMove(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

class Stage extends Component {
  state = { isActive: !this.props.stage.name };

  toggleActive = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  handleDelete = e => {
    e.stopPropagation();
    this.props.stage.remove();
  };

  render() {
    const {
      stage,
      activeStageOrderNr,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      isDragging,
      t
      // index,
    } = this.props;
    const opacity = isDragging ? 0.3 : 1;
    const { isActive } = this.state;
    return connectDragPreview(
      connectDropTarget(
        <div
          style={{
            opacity,
            scrollMarginTop: `${54 + 50 + 64 + 14}px` // Nav header + LessonStatus + StagesBar + some breathing room
          }}
          ref={this.props.stageRef}
        >
          <Accordion
            styled
            fluid
            style={{
              marginTop: '1em',
              boxShadow:
                activeStageOrderNr === stage.orderNr
                  ? '0 0 0 2px #2185d0'
                  : null
            }}
          >
            <FlexAccordionTitle active={isActive} onClick={this.toggleActive}>
              {connectDragSource(
                <div>
                  <DragHandle content={stage.orderNr} />
                </div>
              )}
              {isActive ? (
                <React.Fragment>
                  <InputWithInfoIcon
                    icon={
                      <InfoIcon
                        content={t('lessonEdit.stages.stageNameInfo')}
                      />
                    }
                    iconPosition="left"
                    style={{ width: '100%' }}
                    onClick={e => e.stopPropagation()}
                    size="small"
                    id={`stage-name-${stage.id}`}
                    placeholder={t('lessonEdit.stages.stageNamePlaceholder')}
                    name="name"
                    value={stage.name}
                    onChange={(e, { name, value }) =>
                      stage.updateField(name, value)
                    }
                  />
                  <DeleteButton onClick={this.handleDelete} />
                </React.Fragment>
              ) : (
                <span>
                  {stage.name ? stage.name : t('lessonEdit.stages.noName')}
                </span>
              )}
            </FlexAccordionTitle>
            <StageContentContainer active={isActive}>
              {!stage.isFilled && (
                <Message warning>
                  Etapil peab vähemalt üks materjal või küsimus sisuks olema
                </Message>
              )}
              <Materials stage={stage} />
              <Questions stage={stage} />
            </StageContentContainer>
          </Accordion>
        </div>
      )
    );
  }
}

Stage.propTypes = {
  stage: PropTypes.object.isRequired,
  onMove: PropTypes.func.isRequired
};

export default withTranslation()(
  DropTarget('stage', stageTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  }))(
    DragSource('stage', stageSource, (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    }))(observer(Stage))
  )
);
