import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  Menu,
  Popup,
  Responsive,
  Transition
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import './Header.css';
import AppLogo from './AppLogo';
import LanguageSelect from './LanguageSelect';

function Header() {
  return (
    <>
      <Responsive as={AppMenu} minWidth={Responsive.onlyTablet.minWidth} />
      <Responsive
        as={AppMenu}
        isMobile
        maxWidth={Responsive.onlyMobile.maxWidth}
      />
    </>
  );
}

const AppMenu = inject(
  'appState',
  'auth',
  'groupStore',
  'lessonOverviewStore',
  'invitationStore'
)(
  observer(props => {
    const { auth, isMobile } = props;
    const { isInitialized, isAuthenticated, user, userMetadata } = auth;
    const { t, ready } = useTranslation();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(
      () => {
        if (props.auth.user) {
          // TODO there might be a better place to load the invitations...
          props.invitationStore.loadInvitations();
        }
      },
      [props.auth.user, props.invitationStore]
    );

    function handleLogin() {
      auth.login();
    }

    function handleLogout() {
      auth.logout();
    }

    return (
      <>
        {isMobile && (
          <Menu borderless fixed="top" as="header">
            <Menu.Item
              as={Link}
              to="/"
              active={location.pathname.length === 0}
              onClick={() => setMobileMenuOpen(false)}
            >
              <AppLogo />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item
                as={Button}
                icon={isMobileMenuOpen ? 'x' : 'bars'}
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              />
            </Menu.Menu>
          </Menu>
        )}

        <Transition duration={300} visible={!isMobile || isMobileMenuOpen}>
          <Menu
            as="header"
            borderless
            stackable
            className={isMobile ? 'mobile-menu' : 'desktop-menu'}
            fixed="top"
            attached={isMobile ? 'top' : null}
          >
            {!isMobile && (
              <Menu.Item
                key={uniqueId()}
                as={Link}
                to="/"
                active={location.pathname.length === 0}
                title="Avaleht"
              >
                <AppLogo />
              </Menu.Item>
            )}
            {ready && ( // Wait for translations to load before showing menu items that contain translations
              <>
                {isAuthenticated &&
                  auth.hasRole('TEACHER') && [
                    <Menu.Item
                      key={uniqueId()}
                      as={Link}
                      to="/lessons"
                      active={location.pathname.includes('lessons')}
                      onClick={() => setMobileMenuOpen(false)}
                      title={t('header.lessons')}
                    >
                      <span>{t('header.lessons')}</span>
                    </Menu.Item>,
                    <Menu.Item
                      key={uniqueId()}
                      as={Link}
                      to="/tasks"
                      active={location.pathname.includes('tasks')}
                      onClick={() => setMobileMenuOpen(false)}
                      title={t('header.tasks')}
                    >
                      <span>{t('header.tasks')}</span>
                    </Menu.Item>,
                    <Menu.Item
                      key={uniqueId()}
                      as={Link}
                      to="/market"
                      active={location.pathname.includes('market')}
                      onClick={() => setMobileMenuOpen(false)}
                      title={t('header.market')}
                    >
                      <span>{t('header.market')}</span>
                    </Menu.Item>,
                    <Menu.Item
                      key={uniqueId()}
                      as={Link}
                      to="/groups"
                      active={location.pathname.includes('groups')}
                      title={t('header.userGroups')}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span>{t('header.userGroups')}</span>
                    </Menu.Item>
                  ]}
                {isAuthenticated &&
                  auth.hasRole('STUDENT') && [
                    <Menu.Item
                      key={uniqueId()}
                      as={Link}
                      to="/performances"
                      active={location.pathname.includes('performances')}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <span>{t('header.myPerformances')}</span>
                    </Menu.Item>
                  ]}
                {isAuthenticated && (
                  <>
                    <Menu.Item
                      key={uniqueId()}
                      as={Link}
                      to="/recall"
                      active={location.pathname.includes('recall')}
                      onClick={() => setMobileMenuOpen(false)}
                      title={t('header.recall')}
                    >
                      <span>{t('header.recall')}</span>
                    </Menu.Item>
                  </>
                )}
                <Menu.Menu position="right">
                  <Menu.Item key={uniqueId()}>
                    <LanguageSelect />
                  </Menu.Item>
                  {isAuthenticated && [
                    <Menu.Item
                      key={uniqueId()}
                      as={Link}
                      to="/profile"
                      active={location.pathname.includes('profile')}
                      title="Kasutajaprofiil"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {!userMetadata || !userMetadata.role ? (
                        <Popup
                          content="Endale rolli määramiseks vajuta siia"
                          trigger={
                            <Icon.Group>
                              <Icon name="user circle" />
                              <Icon corner color="yellow" name="warning" />
                            </Icon.Group>
                          }
                        />
                      ) : (
                        <>
                          <Icon name="user circle" fitted={!isMobile} />
                          {isMobile && (user ? user.name : '')}
                        </>
                      )}
                    </Menu.Item>,
                    <Menu.Item
                      key={uniqueId()}
                      onClick={handleLogout}
                      title={t('header.signOut')}
                    >
                      <Icon name="sign out" fitted={!isMobile} />
                      {isMobile && t('header.signOut')}
                    </Menu.Item>
                  ]}
                  {isInitialized &&
                    !isAuthenticated && (
                      <Menu.Item
                        onClick={handleLogin}
                        title={t('header.signIn')}
                      >
                        <Icon name="sign in" />
                        <span>{t('header.signIn')}</span>
                      </Menu.Item>
                    )}
                  {!isInitialized && (
                    <Menu.Item>
                      <Icon loading name="spinner" />
                    </Menu.Item>
                  )}
                </Menu.Menu>
              </>
            )}
          </Menu>
        </Transition>
      </>
    );
  })
);

export default Header;
