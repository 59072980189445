import React, { useState } from 'react';
import { Button, Form, Header, Icon, Message, Modal } from 'semantic-ui-react';
import { getTaskLink } from '../../../../utils/taskUtils';
import { useTranslation } from 'react-i18next';
import CopyToClipboardButton from '../../../common/CopyToClipboardButton';
import InvitationForm from './InvitationForm';
import { inject, observer } from 'mobx-react';

function TaskShareButton({ taskCode, lessonName, primary, taskOverviewStore }) {
  const { t } = useTranslation();
  const taskLink = getTaskLink(taskCode);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  function handleInvitationChange({ selectedUsers, selectedGroups }) {
    setSelectedUsers(selectedUsers);
    setSelectedGroups(selectedGroups);
  }

  function sendInvites() {
    taskOverviewStore.activeTask.sendInvites(
      taskOverviewStore.activeTask.id,
      selectedUsers,
      selectedGroups
    );
  }

  function focusField(id) {
    const element = document.querySelector(`#${id}`);
    if (element) {
      if (typeof element.focus === 'function') {
        element.focus();
      }
      if (typeof element.select === 'function') {
        element.select();
      }
    }
  }

  return (
    <Modal
      centered={false}
      closeIcon
      trigger={
        <Button primary={primary}>
          <Icon name="share" /> {t('taskShare.buttonText')}
        </Button>
      }
    >
      <Modal.Header>
        <Header content={t('taskShare.modal.header')} subheader={lessonName} />
      </Modal.Header>
      <Modal.Content>
        <Message info>
          <Message.Header>{t('taskShare.modal.guide')}</Message.Header>
          <Message.List>
            <Message.Item>{t('taskShare.modal.linkGuide')}</Message.Item>
            <Message.Item>{t('taskShare.modal.codeGuide')}</Message.Item>
            <Message.Item>{t('taskShare.modal.invitationGuide')}</Message.Item>
          </Message.List>
        </Message>

        <Form>
          <Form.Input
            id="task-link"
            label={t('taskShare.modal.linkField')}
            readOnly
            size="big"
            value={taskLink}
            action={
              <CopyToClipboardButton
                buttonLabel={t('taskShare.modal.copyBtn')}
                textToCopy={taskLink}
                style={{ width: '150px' }}
                onCopy={() => focusField('task-link')}
              />
            }
            actionPosition="left"
          />
          <Form.Input
            id="task-code"
            label={t('taskShare.modal.codeField')}
            readOnly
            size="big"
            value={taskCode}
            action={
              <CopyToClipboardButton
                buttonLabel={t('taskShare.modal.copyBtn')}
                textToCopy={taskCode}
                style={{ width: '150px' }}
                onCopy={() => focusField('task-code')}
              />
            }
            actionPosition="left"
          />
        </Form>

        <Header>{t('taskShare.modal.invitationHeader')}</Header>
        <InvitationForm onChange={handleInvitationChange} />
        <Button
          primary
          disabled={selectedUsers.length === 0 && selectedGroups.length === 0}
          content={t('taskShare.modal.sendInvitesBtn')}
          onClick={sendInvites}
        />
      </Modal.Content>
    </Modal>
  );
}

export default inject('taskOverviewStore')(observer(TaskShareButton));
