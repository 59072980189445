import React from 'react';
import { inject, observer } from 'mobx-react';
import styled, { css } from 'styled-components';

import ProgressCell from './ProgressCell';
import { Icon, Label, Message, Segment } from 'semantic-ui-react';
import { PERF_PHASE } from '../../../stores/PerfStore';
import ProgressCellContainer from './ProgressCellContainer';
import { useTranslation } from 'react-i18next';

const ConnectingLine = styled.span`
  &&& {
    min-width: 15px !important;
  }
  border-bottom: 4px solid;
  display: inline-block;
  margin-left: -2px;
  margin-right: -2px;
  z-index: 0;
  transition: border-color 0.3s ease-in-out;

  ${props => css`
    border-color: ${props.isFinished ? '#21BA45' : '#E8E8E8'};
  `};
`;

const MarginlessIconLabel = styled(Label)`
  .icon {
    margin: 0 !important;
  }
`;

const PerformanceProgress = inject('perfStore')(
  observer(({ perfStore }) => {
    const { performance, activeOrderNr, activeStage, phase } = perfStore;
    if (!performance || !performance.stages || activeOrderNr < -1) {
      return null;
    }
    if ([PERF_PHASE.PRETEST, PERF_PHASE.POSTTEST].includes(phase)) {
      return null;
    }
    return (
      <PerformanceProgressInternal
        stages={performance.stages}
        activeStage={activeStage}
        isFinished={phase === PERF_PHASE.RESULT}
      />
    );
  })
);

const ProgressContainer = styled(Segment)`
  margin-bottom: 0 !important;
  z-index: 0;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;

  & > *:first-child {
    margin-left: auto;
    padding-left: 5px;
  }
  & > *:last-child {
    margin-right: auto;
    padding-right: 5px;
  }
`;

const PerformanceProgressInternal = observer(
  ({ stages, isFinished, activeStage }) => {
    const [t] = useTranslation();
    return (
      <>
        <ProgressContainer raised>
          {stages.map((stage, idx) => (
            <React.Fragment key={`progress${idx}`}>
              <ProgressCell stage={stage} />
              <ConnectingLine isFinished={isFinished} />
            </React.Fragment>
          ))}
          <ProgressCellContainer>
            <MarginlessIconLabel
              circular
              disabled
              color={isFinished ? 'green' : null}
              size="large"
              content={
                <Icon name="trophy" color={isFinished ? 'yellow' : null} />
              }
            />
          </ProgressCellContainer>
        </ProgressContainer>
        {activeStage &&
          (activeStage.isFailed || activeStage.isRepeat) && (
            <Message
              style={{ marginBottom: 0 }}
              negative={activeStage.isFailed}
              info={activeStage.isRepeat}
              attached="bottom"
            >
              <Message.Content>
                {activeStage.isFailed ? (
                  <div>
                    <Icon name="exclamation" /> {t('progressCell.tryAgain')}
                  </div>
                ) : (
                  <div>
                    <Icon name="refresh" /> {t('progressCell.repetition')}
                  </div>
                )}
              </Message.Content>
            </Message>
          )}
      </>
    );
  }
);

export default PerformanceProgress;
