import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Icon, Label, Popup } from 'semantic-ui-react';
import { DragSource } from 'react-dnd';
import GripIcon from '../../common/GripIcon';
import MonospaceSpan from '../../common/MonospaceSpan';

const source = {
  beginDrag(props) {
    return {
      answer: props.answer,
      lastGroup: props.answerGroup // used for consecutive hovers over groups to remove the answer from the last hovered group
    };
  },
  canDrag: props => {
    return !props.isReviewPhase;
  }
};

class PerfGroupAnswer extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    answer: PropTypes.object.isRequired
  };

  getCorrectGroupName = correctAnswerGroupId => {
    if (!correctAnswerGroupId) return null;
    const { question } = this.props;
    return question.content.answerGroups.find(
      it => it.id === correctAnswerGroupId
    ).text;
  };

  getLabelColor = correctAnswerGroupId => {
    const { answer } = this.props;
    return !!answer.groupId && answer.groupId === correctAnswerGroupId
      ? 'green'
      : 'red';
  };

  getCorrectAnswerGroupId = () => {
    const { answer } = this.props;
    const { correctAnswer } = this.props.perfStore;
    if (!correctAnswer) {
      // Absence of correctAnswer indicates that no mistake was made
      return answer.groupId;
    }
    const correctAnswerGroupAnswer = correctAnswer.answerGroupAnswers.find(
      it => it.id === answer.id
    );
    if (!correctAnswerGroupAnswer) {
      // Absence of answer in correctAnswer indicates that the answer was wrong,
      // but we shouldn't let the user know what the correct answer is.
      return null;
    }
    return correctAnswerGroupAnswer.answerGroupId;
  };

  renderIcon = () => {
    const { isReviewPhase } = this.props.perfStore;
    if (!isReviewPhase) return <GripIcon />;
    const correctAnswerGroupId = this.getCorrectAnswerGroupId();
    const isCorrect =
      this.props.answer.groupId &&
      correctAnswerGroupId === this.props.answer.groupId;
    const correctGroupName = this.getCorrectGroupName(correctAnswerGroupId);
    return isCorrect ? (
      <Icon name="check" color="green" />
    ) : (
      <Popup
        content={this.getCorrectGroupName(correctAnswerGroupId)}
        disabled={!correctGroupName}
        trigger={<Icon name="cancel" color="red" />}
      />
    );
  };

  render() {
    const { answer, isDragging, connectDragSource, question } = this.props;
    const answerIndex = question.content.answerGroupAnswers.indexOf(answer);
    return connectDragSource(
      <div
        style={{
          opacity: isDragging ? 0 : 1,
          cursor: 'move',
          display: 'inline-block',
          marginRight: '1em'
        }}
      >
        <Label>
          {this.renderIcon()} <MonospaceSpan>[{answerIndex + 1}]</MonospaceSpan>{' '}
          {answer.text}
        </Label>
      </div>
    );
  }
}

export default DragSource('perfGroup', source, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))(inject('perfStore')(observer(PerfGroupAnswer)));
