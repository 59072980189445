import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Container,
  Divider,
  Icon,
  Message,
  Segment
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import './Home.css';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import StudentDashboard from '../StudentDashboard';
import TeacherDashboard from '../TeacherDashboard';
import { Link } from 'react-router-dom';
import TaskChooser from './TaskChooser';
import styled from 'styled-components';

const UnauthenticatedWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  height: 100%;
  margin-right: 1em;
  margin-left: 1em;
`;

const UnauthenticatedContainer = styled(Container)`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 350px !important;
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    align-self: center;
  }
`;

function Home(props) {
  const history = useHistory();
  const location = useLocation();
  const [t] = useTranslation();

  function performTask(taskCode) {
    history.push('/perform/' + taskCode);
  }

  if (
    location.search.includes('code=') &&
    window.location.search.includes('state=')
  ) {
    return (
      <Container>
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>Sisselogimine pooleli...</Message.Content>
        </Message>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>Mastery • Avaleht</title>
      </Helmet>

      {props.auth.isInitialized &&
        props.auth.isAuthenticated && (
          <Container style={{ paddingBottom: 'calc(168px + 1em)' }}>
            {props.auth.isInitialized &&
              props.auth.isAuthenticated &&
              (!props.auth.userMetadata || !props.auth.userMetadata.role) && (
                <Message
                  info
                  icon="user"
                  header={t('home.roleSelectMsg.header')}
                  content={
                    <p>
                      {t('home.roleSelectMsg.explanation')} -{' '}
                      <Link to="/profile">
                        {t('home.roleSelectMsg.profileLink')}.
                      </Link>
                    </p>
                  }
                />
              )}

            {props.auth.hasRole('STUDENT') && <StudentDashboard />}
            {props.auth.hasRole('TEACHER') && <TeacherDashboard />}
          </Container>
        )}

      {!props.auth.isAuthenticated && (
        <UnauthenticatedWrapper>
          <UnauthenticatedContainer>
            <TaskChooser
              style={{ width: '100%' }}
              onChoose={performTask}
              buttonText={t('home.taskCode.startBtn')}
            />
            <Divider content={t('home.divider.or')} hidden />
            <Segment style={{ width: '100%' }}>
              <Button
                fluid
                content={t('home.logInOrSignUp')}
                onClick={() => props.auth.login()}
              />
            </Segment>
          </UnauthenticatedContainer>
        </UnauthenticatedWrapper>
      )}
    </>
  );
}

export default inject('auth', 'perfStore')(observer(Home));
