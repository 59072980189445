import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Header } from 'semantic-ui-react';

import CustomQuill, { TYPE } from '../../common/CustomQuill';
import PerfChooseQuestion from './PerfChooseQuestion';
import PerfOrderQuestion from './PerfOrderQuestion';
import PerfGroupQuestion from './PerfGroupQuestion';
import PerfOpenQuestion from './PerfOpenQuestion';
import { withTranslation } from 'react-i18next';

class PerformanceQuestions extends Component {
  render() {
    // TODO Question phase but no question?
    const { question } = this.props.perfStore;
    return (
      <React.Fragment>
        <Header>{question.name}</Header>
        <CustomQuill
          value={question.text}
          modules={{ toolbar: false }}
          variation={TYPE.ACTIVE}
          readOnly
          style={{ marginBottom: '1em' }}
        />
        {question.type === 'CHOOSE' && (
          <PerfChooseQuestion question={question} />
        )}
        {question.type === 'ORDER' && <PerfOrderQuestion question={question} />}
        {question.type === 'GROUP' && <PerfGroupQuestion question={question} />}
        {question.type === 'OPEN' && <PerfOpenQuestion question={question} />}
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  inject('perfStore')(observer(PerformanceQuestions))
);
