import React from 'react';
import { Image, Menu } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Footer = styled.footer`
  padding-top: 1em;
  ${props =>
    props.isHome &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    `};
`;

function AppFooter() {
  const location = useLocation();

  return (
    <Footer isHome={location.pathname === '/'}>
      <Menu borderless fluid stackable secondary={location.pathname !== '/'}>
        <Menu.Menu position="right">
          <Menu.Item as={Link} to="/terms" content={'Kasutustingimused'} />
          <Menu.Item as={Link} to="/privacy" content={'Privaatsus'} />
          <Image
            style={{ padding: '0.5em' }}
            size="small"
            src="https://res.cloudinary.com/mastery-assets/image/upload/v1615285169/static/el_sotsiaalfond_horisontaalne_ij9y6u.jpg"
            alt="Euroopa Sotsiaalfondi kaksiklogo"
          />
        </Menu.Menu>
      </Menu>
    </Footer>
  );
}

export default AppFooter;
