import React, { useEffect, useState } from 'react';
import { updateLTIConfiguration } from '../../api/lti-client';
import { Button, Divider, Form, Header, Message } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import CopyToClipboardIcon from '../common/CopyToClipboardIcon';

const FieldSet = styled.fieldset`
  border: none;
  padding: 0;
`;

const MonospaceTextarea = styled.textarea`
  font-family: monospace;
`;

/**
 * TODO i18n
 */
function LTIConfigurationForm({ configuration }) {
  const { register, handleSubmit, errors, reset } = useForm();
  const [redirectionUrls, setRedirectionUrls] = useState('');
  const [submitStatus, setSubmitStatus] = useState('PENDING');

  useEffect(
    () => {
      if (configuration) {
        reset();
        setRedirectionUrls(configuration.tool.redirectionUrls.join('\n'));
      }
    },
    [configuration, reset]
  );

  async function onSubmit(data) {
    setSubmitStatus('IN_PROGRESS');
    try {
      await updateLTIConfiguration({
        id: configuration.id,
        ...data
      });
      configuration.description = data.description;
      configuration.clientId = data.clientId;
      configuration.authorizationUri = data.authorizationUri;
      configuration.tokenUri = data.tokenUri;
      configuration.jwkSetUri = data.jwkSetUri;
      setSubmitStatus('SUCCESS');
    } catch (e) {
      setSubmitStatus('ERROR');
    }
  }

  function onFieldCopy(fieldName) {
    const elInput = document.querySelector(`[name=${fieldName}]`);
    if (elInput && typeof elInput.select === 'function') {
      elInput.select();
    }
  }

  return configuration ? (
    <Form
      key={configuration.id}
      onSubmit={handleSubmit(onSubmit)}
      success={submitStatus === 'SUCCESS'}
      error={submitStatus === 'ERROR'}
    >
      <Form.Field>
        <label htmlFor="description">Platvormi kirjeldus</label>
        <input
          type="text"
          name="description"
          id="description"
          ref={register}
          defaultValue={configuration.description}
        />
      </Form.Field>

      <FieldSet>
        <Header as="legend">Platvormi antavad väärtused</Header>
        <Message info>
          <Message.List>
            <Message.Item>
              Sisesta järgnevad väärtused liidestuvas platvormis
            </Message.Item>
            <Message.Item>
              Seejärel{' '}
              <a href="#platform-details">jätka allpool oleva vormi täitmist</a>{' '}
              platvormist saadud väärtustega
            </Message.Item>
          </Message.List>
        </Message>
        <Form.Field>
          <label htmlFor="toolUrl">
            <CopyToClipboardIcon
              textToCopy={configuration.tool.toolUrl}
              onCopy={() => onFieldCopy('toolUrl')}
            />{' '}
            Tool URL
          </label>
          <Form.Input
            readOnly
            type="text"
            name="toolUrl"
            defaultValue={configuration.tool.toolUrl}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="publicKey">
            <CopyToClipboardIcon
              textToCopy={configuration.tool.publicKey}
              onCopy={() => onFieldCopy('publicKey')}
            />{' '}
            Public key
          </label>
          <MonospaceTextarea
            readOnly
            name="publicKey"
            defaultValue={configuration.tool.publicKey}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="jwkSetUrl">
            <CopyToClipboardIcon
              textToCopy={configuration.tool.jwkSetUrl}
              onCopy={() => onFieldCopy('jwkSetUrl')}
            />{' '}
            Keyset URL
          </label>
          <Form.Input
            readOnly
            type="text"
            name="jwkSetUrl"
            defaultValue={configuration.tool.jwkSetUrl}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="initiateLoginUrl">
            <CopyToClipboardIcon
              textToCopy={configuration.tool.initiateLoginUrl}
              onCopy={() => onFieldCopy('initiateLoginUrl')}
            />{' '}
            Initiate login URL
          </label>
          <Form.Input
            readOnly
            type="text"
            name="initiateLoginUrl"
            defaultValue={configuration.tool.initiateLoginUrl}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="publicKey">
            <CopyToClipboardIcon
              textToCopy={redirectionUrls}
              onCopy={() => onFieldCopy('redirectionUrls')}
            />{' '}
            Redirection URI(s)
          </label>
          <textarea
            readOnly
            name="redirectionUrls"
            defaultValue={redirectionUrls}
            rows="1"
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="deeplinkUrl">
            <CopyToClipboardIcon
              textToCopy={configuration.tool.deeplinkUrl}
              onCopy={() => onFieldCopy('deeplinkUrl')}
            />{' '}
            Content selection URL (deeplink)
          </label>
          <Form.Input
            readOnly
            type="text"
            name="deeplinkUrl"
            defaultValue={configuration.tool.deeplinkUrl}
          />
        </Form.Field>
      </FieldSet>

      <Divider />

      <FieldSet id="platform-details">
        <Header as="legend">Platvormist saadud väärtused</Header>
        <Message info>
          Sisesta siia platvormist saadud väärtused ning salvesta liidestuse
          lõpule viimiseks
        </Message>
        <Form.Field required error={!!errors.clientId}>
          <label htmlFor="clientId">Client ID</label>
          <input
            type="text"
            name="clientId"
            id="clientId"
            ref={register({ required: true })}
            defaultValue={configuration.platform.clientId}
          />
        </Form.Field>
        <Form.Field required error={!!errors.jwkSetUri}>
          <label htmlFor="jwkSetUri">Public keyset URL</label>
          <input
            type="text"
            name="jwkSetUri"
            id="jwkSetUri"
            ref={register({ required: true })}
            defaultValue={configuration.platform.jwkSetUri}
          />
        </Form.Field>
        <Form.Field required error={!!errors.tokenUri}>
          <label htmlFor="tokenUri">Access token URL</label>
          <input
            type="text"
            name="tokenUri"
            id="tokenUri"
            ref={register({ required: true })}
            defaultValue={configuration.platform.tokenUri}
          />
        </Form.Field>
        <Form.Field required error={!!errors.authorizationUri}>
          <label htmlFor="authorizationUri">Authentication request URL</label>
          <input
            type="text"
            name="authorizationUri"
            id="authorizationUri"
            ref={register({ required: true })}
            defaultValue={configuration.platform.authorizationUri}
          />
        </Form.Field>
      </FieldSet>

      <Divider />

      <Message
        success
        header="Salvestatud"
        content="Liidestuse tõõtamise kinnitamiseks proovi platvormist avada Mastery sisu."
      />
      <Message
        error
        header="Salvestamine ebaõnnestus"
        content="Proovi mõne aja pärast uuesti."
      />
      <Form.Field>
        <Button
          primary
          type="submit"
          loading={submitStatus === 'IN_PROGRESS'}
          disabled={submitStatus === 'IN_PROGRESS'}
        >
          Salvesta
        </Button>
      </Form.Field>
    </Form>
  ) : null;
}

export default LTIConfigurationForm;
