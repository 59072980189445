import React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';

export const TYPE = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  ACTIVE: 'active',
  NEUTRAL: 'neutral'
};

const getColorByType = type => {
  switch (type) {
    case TYPE.POSITIVE:
      return '#2c662d';
    case TYPE.NEGATIVE:
      return '#912d2b';
    default:
      return '';
  }
};

const getBackgroundColorByType = type => {
  switch (type) {
    case TYPE.POSITIVE:
      return '#fcfff5';
    case TYPE.NEGATIVE:
      return '#fff6f6';
    default:
      return '';
  }
};

const getBoxShadowColorByType = type => {
  switch (type) {
    case TYPE.POSITIVE:
      return '#a3c293';
    case TYPE.NEGATIVE:
      return '#e0b4b4';
    case TYPE.NEUTRAL:
      return '#ccc';
    default:
      return 'transparent';
  }
};

const getBoxShadowOutsetByType = type => {
  switch (type) {
    case TYPE.ACTIVE:
      return '0 0 0 4px #2185D0';
    default:
      return '0 0 0 0 transparent';
  }
};

const BorderlessQuill = styled(ReactQuill)`
  color: ${props => getColorByType(props.variation)};
  background-color: ${props => getBackgroundColorByType(props.variation)};
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px ${props => getBoxShadowColorByType(props.variation)}
      inset,
    ${props => getBoxShadowOutsetByType(props.variation)};

  .ql-container {
    border: none !important;
  }
`;

export default ({ variation, ...rest }) => {
  return <BorderlessQuill variation={variation} {...rest} />;
};
