import { action, computed, decorate, observable } from 'mobx';
import findIndex from 'lodash/findIndex';
import {
  deleteTask,
  invite,
  unInviteGroup,
  unInviteUser
} from '../../../api/tasks';

class TaskOverview {
  id = null;
  lessonId = null;
  originLessonId = null;
  taskName = '';
  lessonName = '';
  description = '';
  userId = '';
  created = '';
  nrOfStages = 0;
  code = '';
  isPretestEnabled = false;
  isPosttestEnabled = false;
  isAuthenticationRequired = null;
  allowLatePerformance = null;
  autoEnrollmentGroup = null;

  invitedUsers = [];
  invitedGroups = [];

  userPerformances = [];
  userGroups = [];

  store = null;

  constructor(store, id) {
    this.id = id;
    this.store = store;
  }

  updateFromJson(json) {
    this.lessonId = json.lessonId;
    this.originLessonId = json.originLessonId;
    this.taskName = json.taskName;
    this.lessonName = json.lessonName;
    this.status = json.status;
    this.description = json.description;
    this.userId = json.userId;
    this.created = json.created;
    this.nrOfStages = json.nrOfStages;
    this.code = json.code;
    this.startTime = json.startTime;
    this.endTime = json.endTime;
    this.isPretestEnabled = json.isPretestEnabled;
    this.isPosttestEnabled = json.isPosttestEnabled;
    this.isAuthenticationRequired = json.isAuthenticationRequired;
    this.allowLatePerformance = json.allowLatePerformance;
    this.autoEnrollmentGroup = json.autoEnrollmentGroup;
    this.invitedUsers = json.invitedUsers ? json.invitedUsers : [];
    this.invitedGroups = json.invitedGroups ? json.invitedGroups : [];
    this.otherPerformers = json.otherPerformers ? json.otherPerformers : [];

    this.userPerformances = json.userPerformances ? json.userPerformances : [];
    this.userGroups = json.userGroups ? json.userGroups : [];
  }

  get isActive() {
    return this.store.activeTask && this.store.activeTask.id === this.id;
  }

  async setActive() {
    this.store.setActiveTask(this);
  }

  sendInvites(taskId, invitedUsers, userGroupIds) {
    invite(taskId, invitedUsers, userGroupIds).then(() => {
      // This refreshes the current lesson data from server.
      // Might be better to avoid this extra server request and add
      // the users from client side data (group users data should come
      // in the response of invitation request though).
      this.setActive();
    });
  }

  unInviteGroup(taskId, groupId) {
    unInviteGroup(taskId, groupId).then(() => {
      this.removeGroupFromLesson(groupId);
    });
  }

  unInviteUser(taskId, userId) {
    unInviteUser(taskId, userId).then(() => {
      this.removeUserFromLesson(userId);
    });
  }

  removeGroupFromLesson(groupId) {
    const i = findIndex(this.invitedGroups, group => group.id === groupId);
    this.invitedGroups.splice(i, 1);
  }

  removeUserFromLesson(userId) {
    const i = findIndex(this.invitedUsers, user => user.id === userId);
    this.invitedUsers.splice(i, 1);
  }

  async delete() {
    try {
      await deleteTask(this.id);
      this.store.removeTask(this.id);
    } catch (e) {
      // TODO show error
    }
  }
}

export default decorate(TaskOverview, {
  invitedUsers: observable,
  invitedGroups: observable,

  userPerformances: observable,
  userGroups: observable,

  isActive: computed,

  setActive: action,
  sendInvites: action,
  unInviteGroup: action,
  unInviteUser: action,
  removeGroupFromLesson: action,
  removeUserFromLesson: action
});
