import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Image, Segment } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import ImageUploadModal from '../../../common/ImageUploadModal';

function ImageMaterial({ material }) {
  const [t] = useTranslation();
  const [imageUrl, setImageUrl] = useState(material.imageUrl);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  function handleStateChange(e, { value }) {
    setImageUrl(value);
  }

  function handleSelectImage(e) {
    e.preventDefault();
    material.updateField('imageUrl', imageUrl);
  }

  function handleImageModalClose(result) {
    if (result) {
      material.updateField('imageUrl', result.src);
    }
    setIsImageModalOpen(false);
  }

  return (
    <>
      <Form>
        <Form.Input
          label={t('lessonEdit.stages.materials.imageUrl')}
          fluid
          name="imageUrl"
          value={imageUrl}
          onChange={handleStateChange}
          action={{
            content: t('lessonEdit.stages.materials.imageSelectBtn'),
            onClick: handleSelectImage
          }}
        />

        <Button type="button" fluid onClick={() => setIsImageModalOpen(true)}>
          {t('lessonEdit.stages.materials.imageSelectorModalBtn')}
        </Button>

        {material.imageUrl && (
          <Segment inverted textAlign="center">
            <Image centered src={material.imageUrl} alt={material.name} />
          </Segment>
        )}
      </Form>
      <ImageUploadModal
        isOpen={isImageModalOpen}
        onClose={handleImageModalClose}
      />
    </>
  );
}

ImageMaterial.propTypes = {
  material: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

export default withTranslation()(observer(ImageMaterial));
