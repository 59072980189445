import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import { observer } from 'mobx-react';
import { Input, Label } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import DeleteButton from '../../DeleteButton';

const orderedAnswerSource = {
  beginDrag(props, monitor, component) {
    const boundingRect = findDOMNode(component).getBoundingClientRect();
    return {
      id: props.id,
      index: props.index,
      width: boundingRect.width,
      height: boundingRect.height
    };
  }
};

const orderedAnswerTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveAnswer(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

class OrderedAnswer extends Component {
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      isDragging,
      index,
      answer,
      t
    } = this.props;
    return connectDragSource(
      connectDropTarget(
        <div
          style={{
            opacity: isDragging ? 0.3 : 1,
            cursor: 'move'
          }}
        >
          <Input
            fluid
            name="text"
            value={answer.text}
            placeholder={t(
              'lessonEdit.questions.orderQuestion.answerPlaceholder'
            )}
            labelPosition="left"
            onChange={(e, { name, value }) => {
              answer.updateField(name, value);
            }}
          >
            <Label>{index + 1}</Label>
            <input />
            <DeleteButton onClick={() => answer.remove()} />
          </Input>
        </div>
      )
    );
  }
}

OrderedAnswer.propTypes = {};

export default withTranslation()(
  DropTarget('orderedAnswer', orderedAnswerTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  }))(
    DragSource('orderedAnswer', orderedAnswerSource, (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    }))(observer(OrderedAnswer))
  )
);
