import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Icon, Message } from 'semantic-ui-react';
import { getUserSettings, saveUserSettings } from '../../api/users';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { setLocalStorageSettings } from '../../utils/userSettings';

const SettingType = {
  BOOLEAN: 'BOOLEAN'
};

function UserSettings({ appState }) {
  const { t } = useTranslation();
  const [settings, setSettings] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    getUserSettings()
      .then(response => {
        setSettings(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  async function handleSave() {
    setSaving(true);
    try {
      await saveUserSettings(settings);
      appState.addMessage({
        success: true,
        text: t('userSettings.savedMsg')
      });
      setLocalStorageSettings(settings);
    } finally {
      setSaving(false);
    }
  }

  return isLoading ? (
    <Message icon>
      <Icon name="circle notched" loading />
      <Message.Content>{t('loading')}</Message.Content>
    </Message>
  ) : (
    <Form onSubmit={handleSave}>
      {settings.map((setting, idx) => (
        <SettingField key={setting.code + idx} setting={setting} />
      ))}
      <Form.Button primary loading={isSaving} disabled={isSaving}>
        {t('userSettings.save')}
      </Form.Button>
    </Form>
  );
}

function SettingField({ setting }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(setting.value);

  function handleChange(newValue) {
    setValue(newValue);
    setting.value = newValue; // Mutating object from props, iffy but works eh
  }

  switch (setting.type) {
    case SettingType.BOOLEAN:
      return (
        <Form.Field>
          <Checkbox
            checked={value === 'true'}
            onClick={() => handleChange(value === 'true' ? 'false' : 'true')}
            label={t(`userSettings.${setting.code}`, {
              defaultValue: setting.code
            })}
          />
        </Form.Field>
      );
    default:
      return null;
  }
}

export default inject('appState')(UserSettings);
