import React, { createRef, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { DragDropContext } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';

import Stage from './Stage';
import AddButton from '../AddButton';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../../common/InfoIcon';
import { Menu, Sticky } from 'semantic-ui-react';
import StagesBar from './stages/StagesBar';

// Ref forwarding due to React-DnD (it seems needed for function components)
const LessonStages = React.forwardRef((props, _ref) => {
  const { t } = useTranslation();
  const { lesson, activeStageOrderNr } = props.lessonEditStore;
  const [stageRefs, setStageRefs] = useState([]);

  useEffect(
    () => {
      setStageRefs(lesson.orderedStages.map(() => createRef()));
    },
    [lesson.orderedStages]
  );

  useEffect(
    () => {
      if (activeStageOrderNr && stageRefs.length >= activeStageOrderNr) {
        stageRefs[activeStageOrderNr - 1].current.scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    [activeStageOrderNr, stageRefs]
  );

  function handleMove(dragIndex, hoverIndex) {
    let lesson = props.lessonEditStore.lesson;
    lesson.moveStage(dragIndex, hoverIndex);
  }

  return (
    <React.Fragment>
      <Sticky context={props.containerRef} offset={54 + 50}>
        <Menu>
          <Menu.Item>
            <h3>
              <InfoIcon content={t('lessonEdit.stages.info')} />{' '}
              {t('lessonEdit.stages.header')}
            </h3>
          </Menu.Item>
          <Menu.Item style={{ marginRight: '1em' }}>
            <AddButton
              content={t('lessonEdit.stages.addStageBtn')}
              onClick={() => lesson.createStage()}
            />
          </Menu.Item>
          <StagesBar />
        </Menu>
      </Sticky>

      {lesson &&
        lesson.orderedStages &&
        lesson.orderedStages.map((stage, index) => (
          <Stage
            stage={stage}
            key={stage.id}
            onMove={handleMove}
            index={index}
            activeStageOrderNr={activeStageOrderNr}
            stageRef={stageRefs[index]}
          />
        ))}
    </React.Fragment>
  );
});

export default DragDropContext(MultiBackend(HTML5toTouch))(
  inject('lessonEditStore')(observer(LessonStages))
);
