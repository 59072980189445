import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Confirm,
  Dropdown,
  Header,
  Icon,
  Loader,
  Menu,
  Message,
  Segment
} from 'semantic-ui-react';
import { Redirect, useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { inject, observer } from 'mobx-react';
import throttle from 'lodash/throttle';

import PublishButton from './PublishButton';
import { fmtDateTime } from '../../../../utils/dateUtils';
import './LessonOverviewDetail.css';
import styled from 'styled-components';
import { getLessonHTML, getLessonPDF } from '../../../../api/lessons';
import { useTranslation } from 'react-i18next';
import CopyToClipboardIcon from '../../../common/CopyToClipboardIcon';
import { getTaskLink } from '../../../../utils/taskUtils';

function LessonOverviewDetail(props) {
  const [t] = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const { lessonOverviewStore } = props;
  const {
    activeLesson,
    sortedActiveLessonTasks,
    isLoadingLesson
  } = lessonOverviewStore;
  const [open, setOpen] = useState(false);
  const [newLessonId, setNewLessonId] = useState(null);
  const throttleTimeInMs = 3000;
  const throttledGetPDF = useCallback(
    throttle(getLessonPDF, throttleTimeInMs),
    []
  );
  const throttledGetHTML = useCallback(
    throttle(getLessonHTML, throttleTimeInMs),
    []
  );
  const [isThrottling, setThrottling] = useState(false);

  function onDelete() {
    props.lessonOverviewStore.activeLesson.delete();
    setOpen(false);
    history.push('/lessons');
  }

  function handleClone() {
    activeLesson.clone().then(clonedLesson => {
      props.appState.addMessage({
        text: t('lessons.overview.cloneMsg'),
        success: true
      });
      setNewLessonId(clonedLesson.id);
    });
  }

  function handlePublish(taskId) {
    props.lessonOverviewStore.loadLesson(match.params.lessonId);
    // Relying on reloading lesson to re-render and cause the first task tab to be opened
    // (which would be the currently created task).
  }

  function handleDownloadPDF() {
    const promise = throttledGetPDF(match.params.lessonId);
    if (promise) {
      setThrottling(true);
      setTimeout(() => setThrottling(false), throttleTimeInMs);
    }
  }

  function handleDownloadHTML() {
    const promise = throttledGetHTML(match.params.lessonId);
    if (promise) {
      setThrottling(true);
      setTimeout(() => setThrottling(false), throttleTimeInMs);
    }
  }

  useEffect(
    () => {
      props.lessonOverviewStore.loadLesson(match.params.lessonId);
    },
    [props.lessonOverviewStore, match.params.lessonId]
  );

  return isLoadingLesson || !activeLesson ? (
    <Loader active />
  ) : (
    <>
      <Segment>
        {newLessonId && (
          <Redirect to={{ pathname: `/lessons/${newLessonId}/edit` }} />
        )}
        <Header>{activeLesson.name}</Header>

        <Menu stackable secondary>
          <Menu.Item>
            <Button primary as={Link} to={`/lessons/${activeLesson.id}/edit`}>
              {t('lessons.overview.editBtn')}
            </Button>
          </Menu.Item>
          <Menu.Item
            as={Link}
            to={`/preview/${activeLesson.id}`}
            target="_blank"
          >
            <Icon name="eye" /> {t('lessons.overview.previewBtn')}
          </Menu.Item>

          <Menu.Menu position="right">
            <Dropdown item icon="ellipsis vertical" text="Tegevused">
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleClone}>
                  <Icon name="copy" /> {t('lessons.overview.cloneBtn')}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleDownloadPDF}
                  disabled={isThrottling}
                >
                  <Icon name="external" /> {t('lessons.overview.pdfBtn')}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleDownloadHTML}
                  disabled={isThrottling}
                >
                  <Icon name="external" /> {t('lessons.overview.htmlBtn')}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setOpen(true)}>
                  <Icon name="trash" /> {t('lessons.overview.deleteBtn')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>

        <Confirm
          confirmButton="Kustuta"
          cancelButton="Katkesta"
          content="Kas oled kindel, et soovid tunni kustutada?"
          open={open}
          onCancel={() => setOpen(false)}
          onConfirm={onDelete}
        />
        {activeLesson.hasDescription && (
          <ReactQuill
            value={activeLesson.description}
            modules={{ toolbar: false }}
            readOnly
          />
        )}
      </Segment>
      <Segment className="tasks-segment">
        <TasksSection
          activeLesson={activeLesson}
          tasks={sortedActiveLessonTasks}
          isLoading={isLoadingLesson}
          onPublish={handlePublish}
        />
      </Segment>
    </>
  );
}

const TaskLinkSpan = styled.span`
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function TasksSection({ activeLesson, tasks, isLoading, onPublish }) {
  const [t] = useTranslation();

  return (
    <>
      <Header>{t('lessons.overview.tasks.header')}</Header>
      {activeLesson.id && (
        <PublishButton
          style={{ marginBottom: '1em' }}
          primary
          lessonId={activeLesson.id}
          lessonName={activeLesson.name}
          onPublish={onPublish}
        />
      )}
      {isLoading ? null : tasks.length === 0 ? (
        <Message>{t('lessons.overview.tasks.noData')}</Message>
      ) : (
        <Card.Group>
          {tasks.map(task => (
            <Card fluid key={task.id}>
              <Card.Content>
                <Card.Header>
                  <Link to={`/tasks/${task.id}`}>{task.taskCode}</Link>
                </Card.Header>
                <Card.Meta>{fmtDateTime(task.startTime)}</Card.Meta>
                <Card.Description>
                  {task.taskName !== task.lessonName && <p>{task.taskName}</p>}
                  <TaskLinkSpan>
                    <CopyToClipboardIcon
                      textToCopy={getTaskLink(task.taskCode)}
                    />
                    <a
                      id={`tasklink-${task.taskCode}`}
                      href={getTaskLink(task.taskCode)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {getTaskLink(task.taskCode)}
                    </a>
                  </TaskLinkSpan>
                </Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      )}
    </>
  );
}

export default inject('lessonOverviewStore', 'appState')(
  observer(LessonOverviewDetail)
);
