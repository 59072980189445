import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Embed, Loader, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { determineEmbedType } from '../../../utils/embedUtils';

function PerformanceEmbed({ material }) {
  const [validationStatus, setValidationStatus] = useState('LOADING');
  const [t] = useTranslation();
  useEffect(
    () => {
      const embedType = determineEmbedType(material.embedSrc);
      if (
        embedType != null &&
        embedType.length > 0 &&
        embedType === material.embedType
      ) {
        setValidationStatus('VALID');
      } else {
        setValidationStatus('INVALID');
      }
    },
    [material]
  );

  switch (validationStatus) {
    case 'LOADING':
      return <Loader active indeterminate />;
    case 'INVALID':
      return <Message negative>{t('performance.embed.invalidEmbed')}</Message>;
    case 'VALID':
      return <Embed url={material.embedSrc} />;
    default:
      return null;
  }
}

export default observer(PerformanceEmbed);
