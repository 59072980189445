import React from 'react';
import LessonOverviewDetail from './LessonOverviewDetail';
import { Container } from 'semantic-ui-react';

function LessonOverview() {
  return (
    <Container>
      <LessonOverviewDetail />
    </Container>
  );
}

export default LessonOverview;
