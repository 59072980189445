import React from 'react';
import { inject, observer } from 'mobx-react';

import PerformanceImage from './PerformanceImage';
import PerformanceVideo from './PerformanceVideo';
import PerformanceText from './PerformanceText';
import PerformancePdf from './PerformancePdf';
import { Header } from 'semantic-ui-react';
import PerformanceEmbed from './PerformanceEmbed';

function PerformanceMaterial({ material }) {
  return (
    <React.Fragment>
      {material.name && (
        <Header style={{ marginTop: '1em' }}>{material.name}</Header>
      )}
      {material.type === 'VIDEO' && <PerformanceVideo material={material} />}
      {material.type === 'IMAGE' && <PerformanceImage material={material} />}
      {material.type === 'TEXT' && <PerformanceText material={material} />}
      {material.type === 'PDF' && <PerformancePdf material={material} />}
      {material.type === 'EMBED' && <PerformanceEmbed material={material} />}
    </React.Fragment>
  );
}

export default inject('perfStore')(observer(PerformanceMaterial));
