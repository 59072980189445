import React, { useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { Container, Message, Segment } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import LessonStages from './LessonStages';
import LessonForm from './LessonForm';
import LessonStatus from './LessonStatus';
import LessonAdditions from './LessonAdditions';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet/es/Helmet';

function LessonEdit({ lessonEditStore }) {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const containerRef = useRef();
  const { lesson } = lessonEditStore;

  useEffect(
    () => {
      const lessonId = match.params.id;
      if (lessonId) {
        lessonEditStore.loadLesson(lessonId);
      }
    },
    [match, lessonEditStore]
  );

  return (
    <>
      <Helmet>
        <title>{lesson.name} • Mastery</title>
      </Helmet>
      <Container style={{ paddingBottom: '63px' }}>
        <div ref={containerRef}>
          <LessonStatus contextRef={containerRef} />
          {lesson &&
            lesson.status !== 'DRAFT' &&
            !lesson.isLoading && (
              <Message warning>
                <Message.Header>
                  {t('lessonEdit.taskEditWarning.header')}
                </Message.Header>
                <Message.Content>
                  {t('lessonEdit.taskEditWarning.content')}
                </Message.Content>
                <Message.List>
                  <Message.Item>
                    {t('lessonEdit.taskEditWarning.list1')}
                  </Message.Item>
                  <Message.Item>
                    {t('lessonEdit.taskEditWarning.list2')}
                  </Message.Item>
                </Message.List>
              </Message>
            )}
          <Segment>
            <LessonForm />
          </Segment>
          <LessonStages containerRef={containerRef} />
          <Segment>
            <LessonAdditions />
          </Segment>
        </div>
      </Container>
    </>
  );
}

export default inject('lessonEditStore')(observer(LessonEdit));
