import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Segment } from 'semantic-ui-react';
import { action, computed, decorate } from 'mobx';
import { inject, observer } from 'mobx-react';
import PerfGroup from './PerfGroup';
import PerfGroupAnswer from './PerfGroupAnswer';
import withDragDropContext from '../../common/withDragDropContext';
import Mousetrap from 'mousetrap';
import { withTranslation } from 'react-i18next';

function createKeybindCombinations(answersCount, groupsCount) {
  const combinations = [];
  for (let i = 1; i <= answersCount; i++) {
    for (let j = 1; j <= groupsCount; j++) {
      combinations.push(`${i} ${j}`);
    }
  }
  return combinations;
}

class PerfGroupQuestion extends Component {
  moveAnswer = (answer, group) => {
    answer.groupId = group.id;
  };

  filterGroupAnswers = groupId => {
    return this.props.question.content.answerGroupAnswers.filter(
      answer => answer.groupId === groupId
    );
  };

  get ungroupedAnswers() {
    return this.props.question.content.answerGroupAnswers.filter(
      answer => answer.groupId == null
    );
  }

  componentDidMount() {
    const { answerGroups, answerGroupAnswers } = this.props.question.content;
    let combinations = createKeybindCombinations(
      answerGroupAnswers.length,
      answerGroups.length
    );
    Mousetrap.bind(combinations, (e, combo) => {
      const [from, to] = combo.split(' ');
      // this.switchAnswers(from - 1, to - 1);
      this.moveAnswer(answerGroupAnswers[from - 1], answerGroups[to - 1]);
    });
  }

  componentWillUnmount() {
    const { answerGroups, answerGroupAnswers } = this.props.question.content;
    Mousetrap.unbind(
      createKeybindCombinations(answerGroupAnswers.length, answerGroups.length)
    );
  }

  render() {
    const { isReviewPhase } = this.props.perfStore;
    return (
      <Container>
        {this.ungroupedAnswers.length > 0 && (
          <Segment secondary>
            {this.ungroupedAnswers.map(answer => (
              <PerfGroupAnswer
                question={this.props.question}
                key={answer.id}
                answer={answer}
                isReviewPhase={isReviewPhase}
              />
            ))}
          </Segment>
        )}
        {this.props.question.content.answerGroups.map(group => (
          <PerfGroup
            key={group.id}
            group={group}
            moveAnswer={this.moveAnswer}
            answers={this.filterGroupAnswers(group.id)}
            question={this.props.question}
          />
        ))}
      </Container>
    );
  }
}

PerfGroupQuestion.propTypes = {
  question: PropTypes.object.isRequired
};

export default withTranslation()(
  withDragDropContext(
    inject('perfStore')(
      observer(
        decorate(PerfGroupQuestion, {
          moveAnswer: action,
          ungroupedAnswers: computed
        })
      )
    )
  )
);
