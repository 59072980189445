import React, { useState } from 'react';
import { Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

function LessonFilter(props) {
  const [t] = useTranslation();
  const [fullSearch, setFullSearch] = useState('');
  // const [isDetailedSearchOpen, setDetailedSearchOpen] = useState(false);

  function handleSearch(e, { value }) {
    setFullSearch(value);
    if (props.onSearch) {
      props.onSearch(value);
    }
  }

  return (
    <>
      <Input
        value={fullSearch}
        onChange={handleSearch}
        fluid
        icon="search"
        iconPosition="left"
        placeholder={t('lessons.filterPlaceholder')}
        // action={
        //   <Button onClick={() => setDetailedSearchOpen(!isDetailedSearchOpen)}>
        //     {isDetailedSearchOpen ? 'Peida' : 'Ava'} detailne otsing
        //   </Button>
        // }
      />
      {/*{isDetailedSearchOpen && <Segment>TERE</Segment>}*/}
    </>
  );
}

export default LessonFilter;
