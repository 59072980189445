import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

export function formatDate(iso8601date, template) {
  const date = parseISO(iso8601date);
  return isValid(date) ? format(date, template) : '-';
}

export function fmtDateTime(iso8601) {
  return formatDate(iso8601, 'd/M/yyyy H:mm');
}
