import API from './API';

const BASE_PATH = '/api/admin';

/**
 * @param simulation com.digitund.jobs.EmailService.EmailSimulation['SUCCESS', 'BOUNCE', 'COMPLAINT', 'CURRENT_USER']
 */
export const sendTestMail = simulation => {
  return API.post(`${BASE_PATH}/test-mail`, { simulation });
};

export const EmailSimulation = {
  SUCCESS: 'SUCCESS',
  BOUNCE: 'BOUNCE',
  COMPLAINT: 'COMPLAINT',
  CURRENT_USER: 'CURRENT_USER'
};
