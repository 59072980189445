import React from 'react';
import { inject, observer } from 'mobx-react';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { PERF_PHASE } from '../../../stores/PerfStore';
import styled from 'styled-components';

const LineBreakParagraph = styled.p`
  white-space: pre-wrap;
`;

function PerformanceMessage({ perfStore }) {
  const [t] = useTranslation();
  const {
    phase,
    wasPartiallyCorrect,
    hasFailedQuestion,
    activeMaterial,
    question,
    isPretestIntroShown,
    isPosttestIntroShown,
    activePretestQuestion,
    activePosttestQuestion
  } = perfStore;

  switch (phase) {
    case PERF_PHASE.REVIEW:
      return wasPartiallyCorrect ? (
        <Message warning attached="top">
          <Message.Header>
            {t('performance.question.partiallyCorrectHeader')}
          </Message.Header>
          <p>{t('performance.question.partiallyCorrectMsg')}</p>
        </Message>
      ) : hasFailedQuestion ? (
        <Message negative attached="top">
          <Message.Header>
            {t('performance.question.incorrectHeader')}
          </Message.Header>
          <p>{t('performance.question.incorrectMsg')}</p>
        </Message>
      ) : (
        <Message positive attached="top">
          <Message.Header>
            {t('performance.question.correctHeader')}
          </Message.Header>
          <p>{t('performance.question.correctMsg')}</p>
        </Message>
      );
    case PERF_PHASE.PRETEST:
    case PERF_PHASE.POSTTEST:
    case PERF_PHASE.QUESTION:
      // TODO Question phase but no question?
      if (isPretestIntroShown || isPosttestIntroShown) return null;
      let isPretestFinished =
        phase === PERF_PHASE.PRETEST && !activePretestQuestion;
      let isPosttestFinished =
        phase === PERF_PHASE.POSTTEST && !activePosttestQuestion;
      if (isPretestFinished || isPosttestFinished) {
        return null;
      }
      const activeQuestion = activePretestQuestion
        ? activePretestQuestion
        : activePosttestQuestion ? activePosttestQuestion : question;
      if (activeQuestion.type === 'OPEN') return null;
      return (
        <Message info attached="top">
          {activeQuestion.type === 'CHOOSE'
            ? t('performance.question.choice.guide')
            : activeQuestion.type === 'ORDER'
              ? t('performance.question.order.guide')
              : activeQuestion.type === 'GROUP'
                ? t('performance.question.group.guide')
                : null}
        </Message>
      );
    case PERF_PHASE.MATERIAL:
      return (
        activeMaterial &&
        activeMaterial.instructions && (
          <Message info attached="top">
            <Message.Content>
              <LineBreakParagraph>
                {activeMaterial.instructions}
              </LineBreakParagraph>
            </Message.Content>
          </Message>
        )
      );
    default:
      return null;
  }
}

export default inject('perfStore')(observer(PerformanceMessage));
