import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PerfQuestion from '../questions/PerfQuestion';
import { Message } from 'semantic-ui-react';
import { Trans, useTranslation } from 'react-i18next';
import FixedProgress from './FixedProgress';

function Posttest(props) {
  const [t] = useTranslation();
  const {
    posttestQuestionCount,
    answeredPosttestQuestionCount,
    activePosttestQuestion,
    isPosttestIntroShown
  } = props.perfStore;
  useEffect(
    () => {
      props.perfStore.setIsPosttestIntroShown(true);
    },
    [props.perfStore]
  );
  return (
    <div style={{ marginTop: '3em' }}>
      {isPosttestIntroShown ? (
        <Message>
          <Message.Content>
            <p>
              <Trans i18nKey="posttest.intro.1">
                Selle õppetüki lõpus tuleb sooritada <b>järeltest</b>.
              </Trans>
              <br />
              {t('posttest.intro.2')}
            </p>
          </Message.Content>
        </Message>
      ) : (
        <>
          <FixedProgress
            total={posttestQuestionCount}
            value={answeredPosttestQuestionCount}
            progress="percent"
          />
          {activePosttestQuestion ? (
            <div>
              <PerfQuestion question={activePosttestQuestion} />
            </div>
          ) : (
            <Message>
              <Message.Content>{t('posttest.successMsg')}</Message.Content>
            </Message>
          )}
        </>
      )}
    </div>
  );
}

export default inject('perfStore')(observer(Posttest));
