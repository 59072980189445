import React, { useEffect, useState } from 'react';
import { Button, Icon, Label, Message, Popup, Table } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { fmtDateTime } from '../../utils/dateUtils';
import LessonFilter from './LessonFilter';
import {
  sortByBoolean,
  sortByDate,
  sortByNumber,
  sortByObjectString,
  sortByString
} from '../../utils/tableUtils';
import { autorun } from 'mobx';
import { useTranslation } from 'react-i18next';

const columnNames = {
  created: 'created',
  updated: 'updated',
  isPublic: 'isPublic',
  creator: 'creator',
  name: 'name',
  storeCopyCount: 'storeCopyCount',
  taskCopyCount: 'taskCopyCount'
};
const columnDefs = {
  [columnNames.created]: {
    sort: sortByDate
  },
  [columnNames.updated]: {
    sort: sortByDate
  },
  [columnNames.isPublic]: {
    sort: sortByBoolean
  },
  [columnNames.creator]: {
    sort: (tableItems, column) =>
      sortByObjectString(tableItems, column, 'familyName')
  },
  [columnNames.name]: {
    sort: sortByString
  },
  [columnNames.storeCopyCount]: {
    sort: sortByNumber
  },
  [columnNames.taskCopyCount]: {
    sort: sortByNumber
  }
};

function LessonsTable(props) {
  const [t] = useTranslation();
  const {
    lessons,
    showDetailLink,
    showCreator,
    showStoreCopyCount,
    showTaskCopyCount
  } = props;
  const [tableItems, setTableItems] = useState([]);
  const [sortColumn, setSortColumn] = useState('created');
  const [sortDirection, setSortDirection] = useState('descending');
  useEffect(
    () => {
      return autorun(() => {
        setTableItems(
          columnDefs.created.sort(props.lessons, columnNames.created).reverse()
        );
      }); // autorun returns a disposer which will be called on component destroy
    },
    [props.lessons]
  );
  const history = useHistory();

  function handleSearch(filter) {
    const lowerCaseFilter = filter.toLowerCase();
    setTableItems(
      lessons.filter(it => {
        return (
          filter.length === 0 ||
          it.name.toLowerCase().includes(lowerCaseFilter) ||
          (it.creator &&
            it.creator.givenName &&
            it.creator.givenName.toLowerCase().includes(lowerCaseFilter)) ||
          (it.creator &&
            it.creator.familyName &&
            it.creator.familyName.toLowerCase().includes(lowerCaseFilter))
        );
      })
    );
  }

  function handleSort(column) {
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortDirection('ascending');
      const sortedItems = columnDefs[column].sort(tableItems, column);
      setTableItems(sortedItems);
      return;
    }
    setTableItems(tableItems.reverse());
    setSortDirection(
      sortDirection === 'ascending' ? 'descending' : 'ascending'
    );
  }

  function handleLessonClone(lesson) {
    lesson.clone().then(clonedLesson => {
      props.appState.addMessage({
        text: t('lessons.table.cloneMsg'),
        success: true
      });
      history.push(`/lessons/${clonedLesson.id}`);
    });
  }

  return (
    <>
      <LessonFilter onSearch={handleSearch} />
      <Table sortable fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              content={t('lessons.table.header.lessonName')}
              sorted={sortColumn === columnNames.name ? sortDirection : null}
              onClick={() => handleSort(columnNames.name)}
            />
            {showCreator && (
              <Table.HeaderCell
                content={t('lessons.table.header.creator')}
                sorted={
                  sortColumn === columnNames.creator ? sortDirection : null
                }
                onClick={() => handleSort(columnNames.creator)}
              />
            )}
            <Table.HeaderCell
              content={t('lessons.table.header.created')}
              sorted={sortColumn === columnNames.created ? sortDirection : null}
              onClick={() => handleSort(columnNames.created)}
            />
            <Table.HeaderCell
              content={t('lessons.table.header.updated')}
              sorted={sortColumn === columnNames.updated ? sortDirection : null}
              onClick={() => handleSort(columnNames.updated)}
            />
            {!showStoreCopyCount && (
              <Table.HeaderCell
                content={t('lessons.table.header.isPublic')}
                sorted={
                  sortColumn === columnNames.isPublic ? sortDirection : null
                }
                onClick={() => handleSort(columnNames.isPublic)}
              />
            )}
            {showStoreCopyCount && (
              <Table.HeaderCell
                content={t('lessons.table.header.storeCopyCount')}
                sorted={
                  sortColumn === columnNames.storeCopyCount
                    ? sortDirection
                    : null
                }
                onClick={() => handleSort(columnNames.storeCopyCount)}
              />
            )}
            {showTaskCopyCount && (
              <Table.HeaderCell
                content={t('lessons.table.header.taskCopyCount')}
                sorted={
                  sortColumn === columnNames.taskCopyCount
                    ? sortDirection
                    : null
                }
                onClick={() => handleSort(columnNames.taskCopyCount)}
              />
            )}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableItems.map(lesson => (
            <Table.Row key={lesson.id}>
              <Table.Cell>
                {showDetailLink ? (
                  <Link to={`/lessons/${lesson.id}`}>{lesson.name}</Link>
                ) : (
                  lesson.name
                )}
              </Table.Cell>
              {showCreator && (
                <Table.Cell>
                  {lesson.creator && lesson.creator.familyName
                    ? lesson.creator.familyName +
                      ', ' +
                      lesson.creator.givenName
                    : '-'}
                </Table.Cell>
              )}
              <Table.Cell>{fmtDateTime(lesson.created)}</Table.Cell>
              <Table.Cell>{fmtDateTime(lesson.updated)}</Table.Cell>
              {!showStoreCopyCount && (
                <Table.Cell>
                  {lesson.isPublic && (
                    <Label
                      title="Ava varamus"
                      icon
                      as={Link}
                      to={`/market/${lesson.id}`}
                    >
                      <Icon fitted name="check" />
                    </Label>
                  )}
                </Table.Cell>
              )}
              {showStoreCopyCount && (
                <Table.Cell>{lesson.storeCopyCount}</Table.Cell>
              )}
              {showTaskCopyCount && (
                <Table.Cell>{lesson.taskCopyCount}</Table.Cell>
              )}
              <Table.Cell textAlign="right">
                <Popup
                  content={t('lessons.table.previewPopup')}
                  trigger={
                    <Button
                      icon
                      as={Link}
                      to={`/preview/${lesson.id}`}
                      target="_blank"
                    >
                      <Icon name="eye" />
                    </Button>
                  }
                />
                <Popup
                  content={t('lessons.table.clonePopup')}
                  trigger={
                    <Button icon onClick={() => handleLessonClone(lesson)}>
                      <Icon name="copy" />
                    </Button>
                  }
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {tableItems.length === 0 && <Message>Ei leidnud tunde</Message>}
    </>
  );
}

export default inject('appState')(observer(LessonsTable));
