import React, { useCallback, useState } from 'react';
import {
  Button,
  Icon,
  Image,
  Input,
  Label,
  Modal,
  Segment
} from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';

import './ImageUploadModal.css';
import { useTranslation } from 'react-i18next';
import { saveToCloudinary } from '../../api/assets';
import AssetList from './AssetList';

/*
 * TODO: Consider integration something like marker.js for easy annotation of images
 * https://markerjs.com/
 */
function ImageUploadModal(props) {
  const { isOpen, onClose } = props;

  const [t] = useTranslation();
  const [previewURL, setPreviewURL] = useState(null);
  const [altText, setAltText] = useState('');
  const [filename, setFilename] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isNewUpload, setIsNewUpload] = useState(false);

  const onDrop = useCallback(files => {
    const file = files[0];
    setFilename(file.name);
    const reader = new FileReader();
    reader.onload = () => {
      setIsNewUpload(true);
      setPreviewURL(reader.result);
    };
    reader.readAsDataURL(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*'
  });

  function handleAltTextChange(e, { value }) {
    setAltText(value);
  }

  function clearState() {
    setPreviewURL(null);
    setAltText(null);
    setFilename(null);
  }

  async function handleSelectImage() {
    let url = previewURL;
    if (isNewUpload) {
      setIsLoading(true);
      url = await saveToCloudinary({
        file: previewURL,
        filename
      });
      setIsLoading(false);
    }
    clearState();
    onClose({
      src: url,
      alt: altText
    });
  }

  function handleAssetListSelect(url, name) {
    setPreviewURL(url);
    setFilename(name);
    setIsNewUpload(false);
  }

  function handleClose() {
    clearState();
    onClose();
  }

  return (
    <Modal id="add-image-modal" open={isOpen} onClose={handleClose}>
      <Modal.Header>{t('imageUploadModal.addImage')}</Modal.Header>
      <Modal.Content>
        {previewURL && (
          <>
            <Segment className="image-preview__container">
              <Image
                centered
                className="image-preview__image"
                src={previewURL}
                alt={t('imageUploadModal.preview')}
              />
              <Label attached="bottom">{filename}</Label>
            </Segment>
            <Input
              fluid
              name="alt-text"
              label={t('imageUploadModal.altText')}
              value={altText}
              onChange={handleAltTextChange}
            />
          </>
        )}
        {!previewURL && (
          <Segment>
            <div className="image-dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <>
                  <Icon name="drag" size="large" />
                  <p>{t('imageUploadModal.dragHere')}</p>
                </>
              ) : (
                <>
                  <Icon name="image" size="large" />
                  <p>{t('imageUploadModal.dragHereOrClick')}</p>
                </>
              )}
            </div>
          </Segment>
        )}
        <AssetList type="IMAGE" onSelect={handleAssetListSelect} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>{t('imageUploadModal.close')}</Button>
        <Button
          primary
          onClick={handleSelectImage}
          loading={isLoading}
          disabled={isLoading}
        >
          {t('imageUploadModal.add')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ImageUploadModal;
