import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';

import GroupForm from './GroupForm';
import Helmet from 'react-helmet/es/Helmet';

class Group extends Component {
  render() {
    const { group } = this.props.groupStore;
    return (
      <Container>
        <Helmet>
          <title>Mastery • Grupid • {group.name}</title>
        </Helmet>
        <GroupForm />
      </Container>
    );
  }
}

export default inject('groupStore')(observer(Group));
