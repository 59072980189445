import React, { useEffect, useRef } from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

function PerfOpenQuestion(props) {
  const [t] = useTranslation();
  const ref = useRef();
  useEffect(() => ref.current.focus(), []);
  return (
    <Form>
      <Form.Field required>
        <label>{t('performance.question.open.label')}</label>
        <TextArea
          key={props.question.id}
          ref={ref}
          value={props.question.openAnswer}
          placeholder={t('performance.question.open.placeholder')}
          onChange={(e, data) =>
            props.perfStore.onOpenAnswerChange(props.question, data.value)
          }
        />
      </Form.Field>
    </Form>
  );
}

export default inject('perfStore')(observer(PerfOpenQuestion));
