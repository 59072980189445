import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Label } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { action, decorate } from 'mobx';
import Mousetrap from 'mousetrap';

import PerfOrderAnswer from './PerfOrderAnswer';
import PreviewSegment from '../../common/PreviewSegment';
import withDragDropContext from '../../common/withDragDropContext';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

function createKeybindCombinations(length) {
  const combinations = [];
  for (let i = 1; i <= length; i++) {
    for (let j = 1; j <= length; j++) {
      if (i !== j) {
        combinations.push(`${i} ${j}`);
      }
    }
  }
  return combinations;
}

const AnswersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-auto-rows: 1fr 1fr;
  grid-row-gap: 1em;
`;

class PerfOrderQuestion extends Component {
  switchAnswers(dragIndex, hoverIndex) {
    const answers = this.props.question.content.orderedAnswers;
    const tmp = answers[dragIndex];
    answers[dragIndex] = answers[hoverIndex];
    answers[hoverIndex] = tmp;
  }

  moveAnswer = (dragIndex, hoverIndex) => {
    this.switchAnswers(dragIndex, hoverIndex);
  };

  componentDidMount() {
    let combinations = createKeybindCombinations(
      this.props.question.content.orderedAnswers.length
    );
    Mousetrap.bind(combinations, (e, combo) => {
      const [from, to] = combo.split(' ');
      this.switchAnswers(from - 1, to - 1);
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind(
      createKeybindCombinations(
        this.props.question.content.orderedAnswers.length
      )
    );
  }

  render() {
    const { isQuestionPhase, isReviewPhase } = this.props.perfStore;
    return (
      <Container>
        <AnswersContainer>
          {this.props.question.content.orderedAnswers.map(
            (randomizedAnswer, idx) => (
              <React.Fragment key={randomizedAnswer.id}>
                <Label
                  circular
                  style={{ justifySelf: 'center', alignSelf: 'center' }}
                >
                  {idx + 1}.
                </Label>
                <PerfOrderAnswer
                  id={randomizedAnswer.id}
                  index={idx}
                  orderedAnswer={randomizedAnswer}
                  isQuestionPhase={isQuestionPhase}
                  isReviewPhase={isReviewPhase}
                  moveAnswer={this.moveAnswer}
                />
              </React.Fragment>
            )
          )}
        </AnswersContainer>
        <PreviewSegment />
      </Container>
    );
  }
}

PerfOrderQuestion.propTypes = {
  question: PropTypes.object.isRequired
};

export default withTranslation()(
  withDragDropContext(
    inject('perfStore')(
      observer(
        decorate(PerfOrderQuestion, {
          switchAnswers: action
        })
      )
    )
  )
);
