import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  Label,
  Loader,
  Message,
  Modal,
  Segment,
  Table
} from 'semantic-ui-react';
import uniq from 'lodash/uniq';
import { fmtDateTime } from '../../../../utils/dateUtils';
import { useTranslation } from 'react-i18next';

import { useTable } from 'react-table';
import './TaskPerformances2.css';
import ChoiceAnswer from '../../../answers/ChoiceAnswer';
import OrderAnswer from '../../../answers/OrderAnswer';
import GroupAnswer from '../../../answers/GroupAnswer';
import OpenAnswer from '../../../answers/OpenAnswer';
import striptags from 'striptags';
import { getQuestionIconName } from '../../../../utils/lessonUtils';
import { fetchPerformance } from '../../../../api/tasks';

function TaskPerformances2(props) {
  const [t] = useTranslation();
  const { task } = props;
  const { userPerformances } = task;
  const [activeRow, setActiveRow] = useState(null);
  const [activeUserPerformances, setActiveUserPerformances] = useState(null);
  const [performanceDetails, setPerformanceDetails] = useState(null);
  const [pretestQuestionPanels, setPretestQuestionPanels] = useState([]);
  const [questionPanels, setQuestionPanels] = useState([]);
  const [posttestQuestionPanels, setPosttestQuestionPanels] = useState([]);
  const [isPerformanceStagesSkipped, setIsPerformanceStagesSkipped] = useState(
    false
  );
  const [
    activeUserSelectedPerformance,
    setActiveUserSelectedPerformance
  ] = useState(null);
  const [activeAnswerFilter, setActiveAnswerFilter] = useState('ALL');
  const [showAllAnswerContent, setShowAllAnswerContent] = useState(false);

  useEffect(
    () => {
      function initQuestionPanels(questions) {
        if (!questions || questions.length === 0) {
          return [];
        }
        return questions
          .filter(q => {
            switch (activeAnswerFilter) {
              case 'ALL':
                return true;
              case 'INCORRECT':
                return q.result === 'INCORRECT';
              case 'OPEN':
                return q.questionType === 'OPEN';
              default:
                return false;
            }
          })
          .map(q => {
            const panel = {
              key: `answer-panel-${q.eventId}-${q.inProgressQuestionType}`,
              title: {
                content: <AnsweredQuestionTitle question={q} />
              },
              content: {
                content: (
                  <Segment>
                    <AnsweredQuestionContent question={q} />
                  </Segment>
                )
              }
            };
            if (showAllAnswerContent) {
              panel.active = true;
            }
            return panel;
          });
      }

      function setupQuestionPanels() {
        if (performanceDetails) {
          const {
            pretestQuestions,
            questions,
            posttestQuestions
          } = partitionQuestions(performanceDetails.answeredQuestions);
          setPretestQuestionPanels(initQuestionPanels(pretestQuestions));
          setQuestionPanels(initQuestionPanels(questions));
          setPosttestQuestionPanels(initQuestionPanels(posttestQuestions));
        }
      }

      setupQuestionPanels();
    },
    [performanceDetails, showAllAnswerContent, activeAnswerFilter]
  );

  function getFullName(performance) {
    return [performance.familyName, performance.givenName]
      .filter(name => name)
      .join(', ');
  }

  function getUserIdsByStatus(status) {
    return userPerformances
      .filter(it => it.status === status)
      .map(it => it.userId);
  }

  function getFinishedUserCount() {
    return uniq(getUserIdsByStatus('FINISHED')).length;
  }

  function getInProgressUserCount() {
    const inProgressUserIds = getUserIdsByStatus('IN_PROGRESS');
    const finishedUserIds = getUserIdsByStatus('FINISHED');
    return uniq(inProgressUserIds).filter(it => !finishedUserIds.includes(it))
      .length;
  }

  function initPerformanceDataFromRow(row) {
    setActiveRow(row);
    const performances = row.original.performances;
    setActiveUserPerformances(
      performances.map(p => {
        return {
          key: p.id,
          text: fmtDateTime(p.startTime),
          value: p.id
        };
      })
    );
    const firstFinishedPerformance = performances.find(
      p => p.status === 'FINISHED'
    );
    const performanceId = firstFinishedPerformance
      ? firstFinishedPerformance.id
      : performances[performances.length - 1].id;
    selectActivePerformance(performanceId);
  }

  function selectActivePerformance(performanceId) {
    setActiveUserSelectedPerformance(performanceId);
    fetchPerformance(task.id, performanceId).then(response => {
      setIsPerformanceStagesSkipped(response.data.isContentSkipped);
      setPerformanceDetails(response.data);
    });
  }

  function partitionQuestions(answeredQuestions) {
    return {
      pretestQuestions: answeredQuestions.filter(
        q => q.inProgressQuestionType === 'PRETEST'
      ),
      questions: answeredQuestions.filter(
        q => !['PRETEST', 'POSTTEST'].includes(q.inProgressQuestionType)
      ),
      posttestQuestions: answeredQuestions.filter(
        q => q.inProgressQuestionType === 'POSTTEST'
      )
    };
  }

  function getIncorrectQuestionCount() {
    if (!performanceDetails || !performanceDetails.answeredQuestions) {
      return 0;
    }
    return performanceDetails.answeredQuestions.filter(
      q => q.result === 'INCORRECT'
    ).length;
  }

  const resultIcon = {
    CORRECT: 'plus',
    INCORRECT: 'minus',
    INDETERMINATE: null // TODO Consider states of open question - not reviewed, accepted, not accepted
  };

  const resultColor = {
    CORRECT: 'green',
    INCORRECT: 'red',
    INDETERMINATE: 'grey'
  };

  function AnsweredQuestionTitle({ question }) {
    return (
      <>
        <Icon name={getQuestionIconName(question.questionType)} />
        <span className="answer-title__question-text">
          {striptags(question.questionText, [], ' ')}
        </span>
        <div className="answer-title__result">
          {['REPETITION', 'CORRECTION'].includes(
            question.inProgressQuestionType
          ) && <Icon className="answer-title__result" name="repeat" />}
          <Icon
            name={resultIcon[question.result]}
            color={resultColor[question.result]}
          />
        </div>
        <span className="answer-title__timestamp">
          {fmtDateTime(question.timestamp)}
        </span>
      </>
    );
  }

  function AnsweredQuestionContent({ question }) {
    switch (question.questionType) {
      case 'CHOOSE':
        return <ChoiceAnswer answeredQuestion={question} />;
      case 'ORDER':
        return <OrderAnswer answeredQuestion={question} />;
      case 'GROUP':
        return <GroupAnswer answeredQuestion={question} />;
      case 'OPEN':
        return <OpenAnswer answeredQuestion={question} />;
      default:
        return null;
    }
  }

  function AnswersAccordion({ answerPanels, header }) {
    return (
      answerPanels &&
      answerPanels.length > 0 && (
        <>
          <Header as="h4">{header}</Header>
          <Accordion
            exclusive={!showAllAnswerContent}
            fluid
            className="answered-questions"
            panels={answerPanels}
          />
        </>
      )
    );
  }

  function showPreviousPerformance() {
    const newIndex =
      activeRow.index !== 0 ? activeRow.index - 1 : table.rows.length - 1;
    const previousRow = table.rows[newIndex];
    initPerformanceDataFromRow(previousRow);
  }

  function showNextPerformance() {
    const newIndex =
      activeRow.index >= table.rows.length - 1 ? 0 : activeRow.index + 1;
    const nextRow = table.rows[newIndex];
    initPerformanceDataFromRow(nextRow);
  }

  const columns = React.useMemo(
    () => {
      function getStatusIcon(status) {
        switch (status) {
          case 'NOT_VISITED':
            return (
              <Label color="grey">
                {t('taskPerformances.status.NOT_VISITED')}
              </Label>
            );
          case 'VISITED':
            return (
              <Label color="yellow">
                {t('taskPerformances.status.VISITED')}
              </Label>
            );
          case 'IN_PROGRESS':
            return (
              <Label color="yellow">
                {t('taskPerformances.status.IN_PROGRESS')}
              </Label>
            );
          case 'FINISHED':
            return (
              <Label color="green">
                {t('taskPerformances.status.FINISHED')}
              </Label>
            );
          default:
            return (
              <Label color="grey">
                {t('taskPerformances.status.NOT_VISITED')}
              </Label>
            );
        }
      }

      return [
        {
          Header: () => t('taskPerformances.table.name'),
          Cell: table => {
            const performance = table.row.original;
            return (
              <Header as="h5">
                <Header.Content>
                  {getFullName(performance)
                    ? getFullName(performance)
                    : performance.guestName}
                  {performance.email && (
                    <Header.Subheader>{performance.email}</Header.Subheader>
                  )}
                </Header.Content>
              </Header>
            );
          },
          id: 'performer'
        },
        {
          Header: () => t('taskPerformances.table.status'),
          Cell: ({ value }) => getStatusIcon(value),
          accessor: 'status'
        },
        {
          Header: () => t('taskPerformances.table.performanceCount'),
          Cell: table => {
            const userPerformances = table.row.original;
            return userPerformances.performances.length;
          },
          id: 'performanceCount'
        },
        {
          Header: () => t('taskPerformances.table.firstFinishedEndTime'),
          Cell: ({ value }) => fmtDateTime(value),
          accessor: 'firstEndTime'
        }
      ];
    },
    [t]
  );

  const table = useTable({ columns, data: userPerformances });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = table;

  return (
    <>
      <p>
        {t('taskPerformances.table.finishedUserCount')}:{' '}
        <strong>{getFinishedUserCount()}</strong>
      </p>
      <p>
        {t('taskPerformances.table.inProgressUserCount')}:{' '}
        <strong>{getInProgressUserCount()}</strong>
      </p>

      <Modal
        open={activeRow != null}
        onClose={() => setActiveRow(null)}
        closeIcon
        centered={false}
      >
        {activeRow ? (
          <>
            <Modal.Header>
              <div className="performance-header">
                <Button onClick={showPreviousPerformance} icon="left chevron" />
                <div className="current-performance">
                  {getFullName(activeRow.original)}
                  <span className="performance-index">
                    {' '}
                    ({activeRow.index + 1}/{userPerformances.length})
                  </span>
                </div>
                <Button onClick={showNextPerformance} icon="right chevron" />
              </div>
            </Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Select
                  label={t('userPerformances.select.label')}
                  options={activeUserPerformances}
                  value={activeUserSelectedPerformance}
                  onChange={(e, { value }) => selectActivePerformance(value)}
                />
              </Form>
              <Header as="h3">
                {t('userPerformances.answeredQuestions.header')}
              </Header>
              <div className="answers-filter">
                <div>
                  <Button
                    basic
                    active={activeAnswerFilter === 'ALL'}
                    onClick={() => setActiveAnswerFilter('ALL')}
                  >
                    {t('userPerformances.filter.all')}
                  </Button>
                  <Button
                    basic
                    icon="edit"
                    active={activeAnswerFilter === 'OPEN'}
                    onClick={() => setActiveAnswerFilter('OPEN')}
                    content={t('userPerformances.filter.open')}
                  />
                  <Button
                    basic
                    active={activeAnswerFilter === 'INCORRECT'}
                    onClick={() => setActiveAnswerFilter('INCORRECT')}
                    content={t('userPerformances.filter.incorrect')}
                    label={{
                      color: 'red',
                      content: getIncorrectQuestionCount()
                    }}
                  />
                </div>
                <Checkbox
                  toggle
                  label={t('userPerformances.filter.showAllAnswerContent')}
                  checked={showAllAnswerContent}
                  onChange={() =>
                    setShowAllAnswerContent(!showAllAnswerContent)
                  }
                />
              </div>

              <AnswersAccordion
                answerPanels={pretestQuestionPanels}
                header={t('userPerformances.answeredQuestions.pretest.header')}
              />
              <AnswersAccordion
                answerPanels={questionPanels}
                header={t('userPerformances.answeredQuestions.stages.header')}
              />
              <AnswersAccordion
                answerPanels={posttestQuestionPanels}
                header={t('userPerformances.answeredQuestions.posttest.header')}
              />
              {isPerformanceStagesSkipped && (
                <Message>{t('userPerformances.stagesSkipped')}</Message>
              )}
            </Modal.Content>
          </>
        ) : (
          <Loader />
        )}
      </Modal>

      <Table {...getTableProps()}>
        <Table.Header>
          {headerGroups.map(headerGroup => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Table.HeaderCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Table.Row
                {...row.getRowProps()}
                onClick={() => initPerformanceDataFromRow(row)}
                className="performance-row"
              >
                {row.cells.map(cell => (
                  <Table.Cell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>

        {/*<Table.Footer>*/}
        {/*  <Table.Row>*/}
        {/*    <Table.HeaderCell colSpan="4">*/}
        {/*      <Menu floated='right' pagination>*/}
        {/*        <Menu.Item as='a' icon>*/}
        {/*          <Icon name='chevron left' />*/}
        {/*        </Menu.Item>*/}
        {/*        <Menu.Item as='a'>1</Menu.Item>*/}
        {/*        <Menu.Item as='a'>2</Menu.Item>*/}
        {/*        <Menu.Item as='a'>3</Menu.Item>*/}
        {/*        <Menu.Item as='a'>4</Menu.Item>*/}
        {/*        <Menu.Item as='a' icon>*/}
        {/*          <Icon name='chevron right' />*/}
        {/*        </Menu.Item>*/}
        {/*      </Menu>*/}
        {/*    </Table.HeaderCell>*/}
        {/*  </Table.Row>*/}
        {/*</Table.Footer>*/}
      </Table>
    </>
  );
}

export default TaskPerformances2;
