import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import AppLogo from './AppLogo';
import { inject, observer } from 'mobx-react';
import { loginInNewTab } from '../../utils';
import { useTranslation } from 'react-i18next';

/**
 * TODO i18n
 */
function HeaderForIframe({ auth }) {
  const [t] = useTranslation();
  const { isInitialized, isAuthenticated, user } = auth;

  return (
    <Menu>
      <Menu.Item>
        <AppLogo />
      </Menu.Item>
      <Menu.Menu position="right">
        {isAuthenticated && (
          <Menu.Item title="Kasutajaprofiil">
            <Icon name="user circle" />
            <span>{user ? user.name : ''}</span>
          </Menu.Item>
        )}
        {isInitialized &&
          !isAuthenticated && (
            <Menu.Item onClick={loginInNewTab} title={t('header.signIn')}>
              <Icon name="sign in" />
              <span>{t('header.signIn')}</span>
            </Menu.Item>
          )}
        {!isInitialized && (
          <Menu.Item>
            <Icon loading name="spinner" />
          </Menu.Item>
        )}
      </Menu.Menu>
    </Menu>
  );
}

export default inject('auth')(observer(HeaderForIframe));
