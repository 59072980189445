import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import API from '../../../api/API';

class InvitationService extends Component {
  componentDidMount() {
    const { invitationId } = this.props.match.params;
    this.invitationId = invitationId;
    API.get(`/api/lessons/invitation/${invitationId}`).then(response => {
      this.props.auth.login(
        '/perform/' + response.data.lessonId,
        response.data.email
      );
    });
  }
  render() {
    return <div />;
  }
}

export default withRouter(inject('auth')(observer(InvitationService)));
