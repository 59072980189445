import React, { useState } from 'react';
import { Form, Input, Message, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import styled from 'styled-components';

const UppercaseInput = styled(Input)`
  input {
    text-transform: uppercase;
  }
  input::-webkit-input-placeholder {
    text-transform: none;
  }
  input::-moz-placeholder {
    text-transform: none;
  }
`;

function TaskChooser({ onChoose, messageText, perfStore, style }) {
  const [t] = useTranslation();
  const [taskCode, setTaskCode] = useState('');
  const [taskCodeError, setTaskCodeError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  function handleTaskCodeChange(event) {
    setTaskCode(event.target.value);
    setTaskCodeError(null);
  }

  function handleChoose() {
    if (!taskCode) {
      setTaskCodeError('Palun sisesta ülesande kood');
      return;
    }
    setLoading(true);
    const upperCasedCode = taskCode.toUpperCase();
    perfStore.isTaskCodeValid(upperCasedCode).then(
      isValid => {
        setLoading(false);
        if (!isValid) {
          setTaskCodeError('Sellise koodiga ülesannet ei leidu');
          return;
        }
        onChoose(upperCasedCode);
      },
      () => {
        setLoading(false);
      }
    );
  }

  return (
    <div style={{ ...style }}>
      <Message attached="top" error={!!taskCodeError}>
        {taskCodeError
          ? taskCodeError
          : messageText ? messageText : t('home.taskCode.label')}
      </Message>
      <Segment attached="bottom">
        <Form>
          <Form.Group>
            <Form.Field style={{ width: '100%' }} error={!!taskCodeError}>
              <UppercaseInput
                name="task-code"
                id="task-code-input"
                placeholder={t('home.taskCode.placeholder')}
                value={taskCode}
                onChange={handleTaskCodeChange}
                action={{
                  loading: isLoading,
                  disabled: isLoading,
                  icon: 'arrow right',
                  onClick: handleChoose,
                  primary: true,
                  title: t('home.taskCode.startBtn')
                }}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    </div>
  );
}

export default inject('perfStore')(TaskChooser);
