import React from 'react';
import { Container, List, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

function GroupAnswer(props) {
  const { answeredQuestion } = props;
  const [t] = useTranslation();

  function Answer({ option }) {
    return (
      <List.Item>
        <List.Icon
          name={option.isCorrect ? 'plus' : 'minus'}
          color={option.isCorrect ? 'green' : 'red'}
        />
        <List.Content>{option.text}</List.Content>
      </List.Item>
    );
  }

  return (
    <Container>
      {answeredQuestion.answer.ungroupedOptions &&
        answeredQuestion.answer.ungroupedOptions.length > 0 && (
          <>
            <h3>{t('groupAnswer.ungrouped')}</h3>
            <List>
              {answeredQuestion.answer.ungroupedOptions.map(option => (
                <Answer
                  option={option}
                  key={`${answeredQuestion.eventId}uaga${option.id}`}
                />
              ))}
            </List>
          </>
        )}
      {answeredQuestion.answer.groups.map(group => (
        <React.Fragment key={`${answeredQuestion.eventId}ag${group.id}`}>
          <h3>{group.text} </h3>
          {!group.options || group.options.length === 0 ? (
            <Message>{t('groupAnswer.emptyGroup')}</Message>
          ) : (
            <List>
              {group.options.map(option => (
                <Answer
                  option={option}
                  key={`${answeredQuestion.eventId}aga${option.id}`}
                />
              ))}
            </List>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
}

export default GroupAnswer;
