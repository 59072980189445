import API from './API';

export const getLTIConfigurations = () => {
  return API.get('/api/lti-configurations');
};

export const createLTIConfiguration = () => {
  return API.post('/api/lti-configurations');
};

export const updateLTIConfiguration = configuration => {
  return API.put(`/api/lti-configurations/${configuration.id}`, configuration);
};

export const deleteLTIConfiguration = configurationId => {
  return API.delete(`/api/lti-configurations/${configurationId}`);
};
