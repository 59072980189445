import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Header } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import TasksTable from '../../TasksTable';
import { withTranslation } from 'react-i18next';

class TaskList extends Component {
  componentDidMount() {
    this.props.taskOverviewStore.loadTasks();
  }

  render() {
    const { t } = this.props;
    const { tasks } = this.props.taskOverviewStore;
    return (
      <React.Fragment>
        <Helmet>
          <title>Mastery • Ülesannete haldus</title>
        </Helmet>
        <Header>{t('tasks.header')}</Header>
        <TasksTable tasks={tasks} />
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  inject('taskOverviewStore')(observer(TaskList))
);
