import React from 'react';
import { inject, observer } from 'mobx-react';
import { Checkbox, Icon } from 'semantic-ui-react';
import striptags from 'striptags';
import './TestQuestionPicker.css';
import { getQuestionIconName } from '../../../../utils/lessonUtils';
import { useTranslation } from 'react-i18next';

function TestQuestionPicker(props) {
  const [t] = useTranslation();
  const { lesson } = props.lessonEditStore;
  const { checkedQuestionIds, onQuestionToggle } = props;
  return (
    <>
      <p>
        <strong>{t('lessonEdit.additions.testQuestionPicker.guide')}</strong>
      </p>
      {lesson.questions.filter(q => q.type !== 'OPEN').map(question => (
        <Checkbox
          className="question-checkbox"
          key={question.id}
          checked={
            !!checkedQuestionIds && checkedQuestionIds.includes(question.id)
          }
          onClick={() => onQuestionToggle(question.id)}
          label={
            <label>
              <Icon name={getQuestionIconName(question.type)} />
              {striptags(question.text, [], ' ')}
            </label>
          }
        />
      ))}
    </>
  );
}

export default inject('lessonEditStore')(observer(TestQuestionPicker));
