import { autorun, computed, decorate, observable, runInAction } from 'mobx';
import createAuth0Client from '@auth0/auth0-spa-js';
import history from '../utils/history';
import { saveUserMetadata } from '../api/users';
import { getLanguage } from '../i18n';
import { axiosInstance } from '../api/API';
import { setupHotjar } from '../utils/hotjar';

const AUTH0_NAMESPACE = 'https://masteryapp'; // TODO Env specific?

class Auth {
  auth0Client;
  user;
  isAuthenticated = false;
  isInitialized = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initAuth0Client();
    this.initBearerTokenInterceptor();

    autorun(() => {
      if (this.isAuthenticated) {
        setupHotjar();
      }
    });
  }

  async initAuth0Client() {
    this.auth0Client = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      cacheLocation: 'localstorage',
      ui_locales: getLanguage()
    });

    if (
      window.location.search.includes('code=') &&
      window.location.search.includes('state=')
    ) {
      const { appState } = await this.auth0Client.handleRedirectCallback();
      this.onRedirectCallback(appState);
    }

    const isAuthenticated = await this.auth0Client.isAuthenticated();
    runInAction(() => {
      this.isAuthenticated = isAuthenticated;
    });

    if (isAuthenticated) {
      const user = await this.auth0Client.getUser();
      runInAction(() => (this.user = user));
    }
    runInAction(() => (this.isInitialized = true));
  }

  onRedirectCallback(appState) {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }

  initBearerTokenInterceptor() {
    axiosInstance.interceptors.request.use(async config => {
      if (this.auth0Client && this.isAuthenticated) {
        // TODO Should make sure that client is initialized before making any REST requests
        config.headers.Authorization =
          'Bearer ' + (await this.getBearerToken());
      }
      return config;
    });
  }

  async getBearerToken() {
    return await this.auth0Client.getTokenSilently();
  }

  async login(targetUrl) {
    const loginOpts = {
      appState: {
        targetUrl: targetUrl ? targetUrl : window.location.pathname
      },
      ui_locales: getLanguage()
    };
    await this.auth0Client.loginWithRedirect(loginOpts);
  }

  logout() {
    this.auth0Client.logout();
  }

  /**
   * Checks only user-assignable roles (from user metadata)
   */
  hasRole(role) {
    return (
      !!this.userMetadata &&
      (this.userMetadata.role === role || this.userMetadata.role === 'ADMIN')
    );
  }

  /**
   * Checks Auth0 Authorization Core roles from ID token claims.
   * If user has any of the specified roles, then the check passes.
   *
   * See:
   * https://auth0.com/docs/authorization/how-to-use-auth0s-core-authorization-feature-set
   * https://auth0.com/docs/authorization/sample-use-cases-rules-with-authorization#add-user-roles-to-tokens
   */
  async hasRbacRoles(roles) {
    const claims =
      this.auth0Client && (await this.auth0Client.getIdTokenClaims());
    const userRoles = claims && claims[`${AUTH0_NAMESPACE}/roles`];
    if (userRoles && userRoles.length > 0) {
      return userRoles.some(role => roles.includes(role));
    }
    return false;
  }

  get userMetadata() {
    return this.user && this.user[`https://masteryapp/user_metadata`];
  }

  saveProfile(metadata) {
    return saveUserMetadata(metadata);
  }

  async updateUser() {
    await this.auth0Client.getTokenSilently({ ignoreCache: true });
    const user = await this.auth0Client.getUser();
    runInAction(() => (this.user = user));
  }
}

export const Roles = {
  ADMIN: 'admin',
  LTI_ADMIN: 'lti_admin'
};

export default decorate(Auth, {
  user: observable,
  isAuthenticated: observable,
  isInitialized: observable,

  userMetadata: computed
});
