import React, { useState } from 'react';
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Message,
  Segment
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import UserSettings from './UserSettings';

function UserProfile({ auth, appState }) {
  const [t] = useTranslation();
  const { user, userMetadata } = auth;
  const [role, setRole] = useState(
    !userMetadata ? '' : !userMetadata.role ? '' : userMetadata.role
  );
  const [givenName, setGivenName] = useState(
    !userMetadata ? '' : !userMetadata.given_name ? '' : userMetadata.given_name
  );
  const [familyName, setFamilyName] = useState(
    !userMetadata
      ? ''
      : !userMetadata.family_name ? '' : userMetadata.family_name
  );

  function saveProfile(e) {
    e.preventDefault();
    auth
      .saveProfile({
        givenName,
        familyName,
        role
      })
      .then(() => {
        appState.addMessage({
          text: t('profile.saveMsg'),
          success: true
        });
        auth.updateUser();
      });
  }

  return (
    <>
      <Header>{t('profile.header')}</Header>
      <Form>
        <Form.Field required error={!givenName}>
          <label>{t('profile.givenName')}</label>
          <Input
            value={givenName}
            onChange={(e, { value }) => setGivenName(value)}
          />
        </Form.Field>
        <Form.Field required error={!familyName}>
          <label>{t('profile.familyName')}</label>
          <Input
            value={familyName}
            onChange={(e, { value }) => setFamilyName(value)}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('profile.email')}</label>
          <Input disabled value={user ? user.email : ''} />
        </Form.Field>
        {(!userMetadata || !userMetadata.role) && (
          <Message info>
            <Message.Content>{t('profile.chooseRole')}</Message.Content>
            <Message.List>
              <Message.Item>{t('profile.studentDesc')}</Message.Item>
              <Message.Item>{t('profile.teacherDesc')}</Message.Item>
            </Message.List>
          </Message>
        )}
        <Form.Group inline required>
          <label>{t('profile.role')}</label>
          <Form.Field required>
            <Form.Radio
              label={t('profile.student')}
              name="role"
              value="STUDENT"
              checked={role === 'STUDENT'}
              onChange={() => setRole('STUDENT')}
            />
          </Form.Field>
          <Form.Field>
            <Form.Radio
              label={t('profile.teacher')}
              name="role"
              value="TEACHER"
              checked={role === 'TEACHER'}
              onChange={() => setRole('TEACHER')}
            />
          </Form.Field>
        </Form.Group>
        <Button
          primary
          type="submit"
          onClick={saveProfile}
          disabled={!givenName || !familyName || !role}
        >
          {t('profile.save')}
        </Button>
      </Form>

      <Segment secondary>
        <Header>
          <Icon name="setting" />
          {t('profile.userSettings')}
        </Header>
        <UserSettings />
      </Segment>
    </>
  );
}

export default inject('auth', 'appState')(observer(UserProfile));
