import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Message } from 'semantic-ui-react';

class PerformancePause extends Component {
  render() {
    const { isQuestionPhase, isMaterialPhase } = this.props.perfStore;
    // TODO Can add a timer to automatically end pause after a specified time
    return (
      <Message>
        {isQuestionPhase && "Vajuta 'Jätka' et küsimustele vastata"}
        {isMaterialPhase && "Vajuta 'Jätka' et materjaliga alustada"}
      </Message>
    );
  }
}

export default inject('perfStore')(observer(PerformancePause));
