import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Header, Icon, Message, Modal } from 'semantic-ui-react';
import { publishLesson } from '../../../../api/lessons';
import { useTranslation } from 'react-i18next';
import TaskForm from './TaskForm';

function PublishButton({
  disabled,
  lessonId,
  lessonName,
  size,
  onPublish,
  appState,
  primary,
  style
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [task, setTask] = useState(null);

  function publish() {
    if (task) {
      // Relying on <TaskForm> to provide a valid task and null if not valid
      publishLesson(lessonId, task).then(response => {
        setOpen(false);
        appState.addMessage({
          text: t('lessons.publish.modal.publishedMsg'),
          success: true
        });
        if (typeof onPublish === 'function') {
          onPublish(response.data);
        }
      });
    }
  }

  return (
    <React.Fragment>
      <Button
        onClick={() => setOpen(true)}
        disabled={disabled}
        primary={primary}
        style={style}
      >
        {t('lessons.publish.openTaskBtn')}
      </Button>
      <Modal size={size} open={open} onClose={() => setOpen(false)} closeIcon>
        <Modal.Header>
          <Header>
            <Header.Content>{t('lessons.publish.modal.header')}</Header.Content>
            <Header.Subheader>{lessonName}</Header.Subheader>
          </Header>
        </Modal.Header>
        <Modal.Content>
          <Message info icon>
            <Icon name="info" />
            <Message.Content>
              {t('lessons.publish.modal.publishingCreatesCopyMessage')}
            </Message.Content>
          </Message>
          <TaskForm onChange={value => setTask(value)} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            {t('lessons.publish.modal.close')}
          </Button>
          <Button
            primary
            disabled={!task}
            icon="arrow right"
            labelPosition="right"
            content={t('lessons.publish.modal.publish')}
            onClick={publish}
          />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
}

export default inject('lessonOverviewStore', 'appState')(
  observer(PublishButton)
);
