import { action, computed, decorate, observable, runInAction } from 'mobx';
import debounce from 'lodash/debounce';
import remove from 'lodash/remove';
import uniqueId from 'lodash/uniqueId';
import xor from 'lodash/xor';
import Stage from './stage';
import { deleteLesson, saveLesson } from '../../../api/lessons';

class EditableLesson {
  id = null;
  userId = null;

  status = '';
  name = '';
  description = '';
  isPublic = false;
  isThrowbackEnabled = true;
  isPretestEnabled = false;
  isPosttestEnabled = false;
  strictness = 'STRICT';
  recallQuestionCount = 2;
  stages = [];
  pretestQuestionIds = [];
  posttestQuestionIds = [];
  isLoading = false;

  store = null;
  isSaveInProgress = false;

  constructor(store, id) {
    this.id = id;
    this.store = store;
  }

  persistChanges = debounce(async () => {
    this.isSaveInProgress = true;
    const { data } = await saveLesson(this.asJson);
    this.isSaveInProgress = false;
    if (typeof this.id === 'string') {
      this.id = data.id;
    }
    this.store.rootStore.stores.lessonOverviewStore.updateLesson(this);
  }, 500);

  get asJson() {
    return {
      id: typeof this.id === 'string' ? null : this.id,
      userId: this.userId,
      status: this.status,
      name: this.name,
      description: this.description,
      isPublic: this.isPublic,
      isThrowbackEnabled: this.isThrowbackEnabled,
      isPosttestEnabled: this.isPosttestEnabled,
      isPretestEnabled: this.isPretestEnabled,
      strictness: this.strictness,
      recallQuestionCount: this.recallQuestionCount,
      pretestQuestionIds: this.pretestQuestionIds,
      posttestQuestionIds: this.posttestQuestionIds
    };
  }

  get orderedStages() {
    return this.stages.sort((a, b) => a.orderNr - b.orderNr);
  }

  get questions() {
    return this.stages.reduce((result, stage) => {
      return result.concat(stage.questions.slice());
    }, []);
  }

  get isInvalid() {
    return (
      !this.stages ||
      this.stages.length === 0 ||
      this.stages.some(it => !it.isFilled)
    );
  }

  get isSaving() {
    return this.isSaveInProgress; // TODO also consider stages, materials, questions, etc...
  }

  updateFromJson(json) {
    this.id = json.id;
    this.status = json.status;
    this.name = json.name;
    this.userId = json.userId;
    this.description = json.description ? json.description : '';
    this.isPublic = json.isPublic;
    this.isThrowbackEnabled = json.isThrowbackEnabled;
    this.isPretestEnabled = json.isPretestEnabled;
    this.isPosttestEnabled = json.isPosttestEnabled;
    this.strictness = json.strictness;
    this.recallQuestionCount = json.recallQuestionCount;
    this.stages =
      json.stages &&
      json.stages.map(stageJson => {
        return new Stage(this.store, stageJson.id, this, stageJson);
      });
    this.pretestQuestionIds = json.pretestQuestionIds;
    this.posttestQuestionIds = json.posttestQuestionIds;
  }

  updateField(name, value, persistChanges = true) {
    this[name] = value;
    if (persistChanges) this.persistChanges();
  }

  moveStage(from, to) {
    const movedAhead = from < to;
    const movedBehind = from > to;
    const toAdjust = [];
    this.orderedStages.forEach((s, index) => {
      let increment = movedAhead && index > from && index <= to;
      let decrement = movedBehind && index >= to && index < from;
      if (increment || decrement) {
        toAdjust.push(s);
      }
    });
    const moved = this.orderedStages[from];
    moved.updateField('orderNr', to + 1);
    toAdjust.forEach(stage => {
      const newOrderNr = movedAhead ? stage.orderNr - 1 : stage.orderNr + 1;
      // Only make server request for actually moved item, adjustments are made server-side
      stage.updateField('orderNr', newOrderNr, false);
      this.store.setActiveStageOrderNr(null);
    });
  }

  async createStage(orderNr = this.stages.length + 1) {
    this.stages
      .filter(stage => stage.orderNr >= orderNr)
      .forEach(stage => stage.updateField('orderNr', stage.orderNr + 1));
    let newStage = new Stage(this.store, uniqueId('stage'), this, { orderNr });
    await newStage.persistChanges();
    runInAction(() => this.stages.push(newStage));
    this.store.setActiveStageOrderNr(newStage.orderNr);
  }

  removeStage(stage) {
    const { orderNr } = stage;
    const stagesToPullForward = this.stages.filter(s => s.orderNr > orderNr);
    remove(this.stages, s => s.id === stage.id);
    stagesToPullForward.forEach(s =>
      s.updateField('orderNr', s.orderNr - 1, false)
    );
    this.store.setActiveStageOrderNr(null);
  }

  async remove() {
    await deleteLesson(this.id);
    // TODO clean up state
  }

  togglePretestQuestion(questionId) {
    const toggledQuestionIds = xor(this.pretestQuestionIds, [questionId]);
    this.updateField('pretestQuestionIds', toggledQuestionIds);
  }

  togglePosttestQuestion(questionId) {
    const toggledQuestionIds = xor(this.posttestQuestionIds, [questionId]);
    this.updateField('posttestQuestionIds', toggledQuestionIds);
  }

  setLoading(loading) {
    this.isLoading = loading;
  }
}

export default decorate(EditableLesson, {
  name: observable,
  description: observable,
  isPublic: observable,
  isThrowbackEnabled: observable,
  isPretestEnabled: observable,
  isPosttestEnabled: observable,
  strictness: observable,
  recallQuestionCount: observable,
  stages: observable,
  pretestQuestionIds: observable,
  posttestQuestionIds: observable,
  isLoading: observable,

  asJson: computed,
  orderedStages: computed,
  isInvalid: computed,
  isSaving: computed,
  questions: computed,

  updateField: action,
  moveStage: action,
  removeStage: action,
  togglePretestQuestion: action,
  togglePosttestQuestion: action
});
