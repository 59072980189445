import { action, computed, decorate, observable, runInAction } from 'mobx';
import API from '../api/API';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { getI18n } from 'react-i18next';
import { sendLTIFinishEvent, sendLTIStartEvent } from '../utils/ltiUtils';

export const PERF_PHASE = {
  INTRO: 'INTRO',
  PRETEST: 'PRETEST',
  MATERIAL: 'MATERIAL',
  QUESTION: 'QUESTION',
  REVIEW: 'REVIEW',
  PAUSE: 'PAUSE',
  POSTTEST: 'POSTTEST',
  RESULT: 'RESULT'
};

class PerfStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  lesson = {};
  performance = {};
  materials = [];
  activeMaterial = {};
  question = {};

  materialsByStage = {};
  questionsByStage = {};
  pretestQuestions = [];
  pretestQuestionCount = 0;
  posttestQuestions = [];
  posttestQuestionCount = 0;

  phase = PERF_PHASE.INTRO;
  isStagesSkipAllowed = false;
  isPretestIntroShown = false;
  isPosttestIntroShown = false;
  isContinueDisabled = false;
  wasPartiallyCorrect = false;
  answer = {};
  correctAnswer = {};

  continuePerformanceData = null;

  async isTaskCodeValid(taskCode) {
    const response = await API.get(`/public-api/perform/${taskCode}/is-valid`);
    return response.data;
  }

  async getLesson(taskCode) {
    try {
      const response = await API.get(`/public-api/perform/${taskCode}`);
      const lessonDetails = response.data;
      if (lessonDetails) {
        this.resetPerformance();
        runInAction(() => (this.lesson = lessonDetails));
      }
      return lessonDetails;
    } catch (e) {
      // TODO Should be a well-defined Axios rejection and handle only 404
      return null;
    }
  }

  async previewLesson(lessonId) {
    if (this.lesson.id !== lessonId) {
      const response = await API.post(`/api/preview/${lessonId}`);
      const lessonDetails = response.data;
      if (lessonDetails) {
        this.resetPerformance();
        runInAction(() => (this.lesson = lessonDetails));
      }
      return lessonDetails;
    }
    return null;
  }

  resetPerformance() {
    this.performance = {};
    this.phase = PERF_PHASE.INTRO;
    this.answer = {};
    this.activeMaterial = {};
    this.materials = [];
    this.question = {};
    this.materialsByStage = {};
    this.questionsByStage = {};
    this.pretestQuestions = [];
  }

  startPerformance(guestName) {
    return new Promise((resolve, reject) => {
      API.post(`/public-api/perform/${this.lesson.taskCode}/start`, guestName, {
        headers: { 'Content-Type': 'text/plain' }
      }).then(response => {
        if (response.data.guestUserId) {
          document.cookie = `guestUserId=${response.data.guestUserId};domain=.${
            window.location.hostname
          };path=/;secure;SameSite=none;`;
        }

        this.materialsByStage = response.data.materialsByStage;
        this.questionsByStage = response.data.questionsByStage;
        if (response.data.performance) {
          sendLTIStartEvent(response.data.performance.performanceId);
          if (
            response.data.pretestQuestions &&
            response.data.pretestQuestions.length > 0
          ) {
            // TODO This means the user will see the remaining question count,
            //  which means that after answering one of two questions and then refreshing will result in
            //  the user seeing as if there was only one pretest question in total. Might consider not deleting
            //  IN_PROGRESS_QUESTION rows and marking then as answered instead.
            this.pretestQuestionCount = response.data.pretestQuestions.length;
            this.pretestQuestions = response.data.pretestQuestions.map(q =>
              this.initQuestionAnswerFields(q)
            );
            this.phase = PERF_PHASE.PRETEST;
          }
          if (
            response.data.posttestQuestions &&
            response.data.posttestQuestions.length > 0
          ) {
            this.posttestQuestionCount = response.data.posttestQuestions.length;
            this.posttestQuestions = response.data.posttestQuestions.map(q =>
              this.initQuestionAnswerFields(q)
            );
          }
          this.setCurrentStepData(response.data);
        } else {
          // TODO notify user of reason for resetting (errorCode)
          // Also should refetch lesson details probably.
          this.rootStore.stores.appState.addMessage({
            warning: true,
            text: getI18n().t('notification.taskNotOpen')
          });
          this.resetPerformance();
        }
        resolve();
      });
    });
  }

  setIsPretestIntroShown(isPretestIntroShown) {
    this.isPretestIntroShown = isPretestIntroShown;
  }

  setIsPosttestIntroShown(isPosttestIntroShown) {
    this.isPosttestIntroShown = isPosttestIntroShown;
  }

  answerPretestQuestion() {
    return new Promise((resolve, reject) => {
      const answer = this.createAnswerRequest(this.activePretestQuestion);
      API.post(
        `/public-api/perform/${this.lesson.taskCode}/pretest/answer`,
        answer
      ).then(response => {
        const { pretestQuestions, isStagesSkipAllowed } = response.data;
        runInAction(() => {
          this.pretestQuestions = pretestQuestions.map(q =>
            this.initQuestionAnswerFields(q)
          );
          this.isStagesSkipAllowed = isStagesSkipAllowed;
        });
        resolve();
      });
    });
  }

  finishPretest() {
    this.phase = this.activeMaterial
      ? PERF_PHASE.MATERIAL
      : PERF_PHASE.QUESTION;
  }

  finishPosttest() {
    this.finishPerformance();
  }

  finishPerformance() {
    this.phase = PERF_PHASE.RESULT;
    sendLTIFinishEvent(this.performance.performanceId);
  }

  skipStages() {
    return new Promise((resolve, reject) => {
      API.post(
        `/public-api/perform/${this.lesson.taskCode}/pretest/skip-stages`
      ).then(response => {
        runInAction(() => {
          this.performance.status = 'FINISHED';
          this.finishPerformance();
        });
        resolve();
      });
    });
  }

  get answeredPretestQuestionCount() {
    if (!this.pretestQuestions || !this.pretestQuestionCount) {
      return 0;
    }
    return this.pretestQuestionCount - this.pretestQuestions.length;
  }

  get answeredPosttestQuestionCount() {
    if (!this.posttestQuestions || !this.posttestQuestionCount) {
      return 0;
    }
    return this.posttestQuestionCount - this.posttestQuestions.length;
  }

  continuePerformance() {
    const stageHadQuestion = !!this.question;
    return new Promise((resolve, reject) => {
      if (this.answerValidationError) {
        this.rootStore.stores.appState.addMessage({
          error: true,
          text: getI18n().t(
            `performance.answerValidation.${this.answerValidationError}`
          )
        });
        resolve({ error: this.answerValidationError });
        return;
      }
      API.post(
        `/public-api/perform/${this.lesson.taskCode}/continue`,
        this.createAnswerRequest(this.question)
      ).then(response => {
        this.questionsByStage = response.data.questionsByStage;
        this.posttestQuestions = response.data.posttestQuestions.map(q =>
          this.initQuestionAnswerFields(q)
        );
        if (this.posttestQuestionCount == null) {
          this.posttestQuestionCount = this.posttestQuestions.length;
        }
        if (response.data.performance) {
          runInAction(() => {
            if (stageHadQuestion && this.question.type !== 'OPEN') {
              this.startReviewPhase(response.data);
            } else {
              this.startPausePhase(response.data);
            }
          });
        } else {
          this.rootStore.stores.appState.addMessage({
            warn: true,
            text: getI18n().t('notification.taskNotOpen')
          });
          this.resetPerformance();
        }
        resolve();
      });
    });
  }

  answerPosttestQuestion() {
    return new Promise((resolve, reject) => {
      const answer = this.createAnswerRequest(this.activePosttestQuestion);
      API.post(
        `/public-api/perform/${this.lesson.taskCode}/posttest/answer`,
        answer
      ).then(response => {
        const { posttestQuestions } = response.data;
        runInAction(() => {
          this.posttestQuestions = posttestQuestions.map(q =>
            this.initQuestionAnswerFields(q)
          );
        });
        resolve();
      });
    });
  }

  get answerValidationError() {
    if (this.question && this.question.type === 'OPEN') {
      if (!this.question.openAnswer || this.question.openAnswer.length <= 0) {
        return 'OPEN_ANSWER_MISSING';
      }
    }
    return null;
  }

  get hasFailedQuestion() {
    return (
      this.continuePerformanceData &&
      this.continuePerformanceData.isQuestionFailed
    );
  }

  get someStageIsFailed() {
    // TODO Under which circumstance could stages be undefined?
    // Sentry: MASTERY-12
    return (
      this.performance &&
      this.performance.stages &&
      this.performance.stages.some(it => it.isFailed)
    );
  }

  get shouldRestrictPlayback() {
    switch (this.lesson.strictness) {
      case 'STRICT':
        return true;
      case 'MODERATE':
        return !this.someStageIsFailed;
      case 'UNRESTRICTED':
        return false;
      default:
        return true;
    }
  }

  startReviewPhase(performanceData) {
    this.continuePerformanceData = performanceData;
    runInAction(() => {
      this.correctAnswer = performanceData.correctAnswer;
    });
    this.phase = PERF_PHASE.REVIEW;
    this.wasPartiallyCorrect = this.isPartiallyCorrect(
      this.question,
      this.correctAnswer
    );
  }

  isPartiallyCorrect(question, correctAnswer) {
    if (!correctAnswer) {
      // Absence of correctAnswer means the user was fully correct
      return false;
    }
    switch (question.type) {
      case 'CHOOSE':
        return correctAnswer.answerOptions.some(correctOption => {
          return (
            correctOption.correct &&
            question.content.answerOptions.some(answeredOption => {
              return (
                correctOption.id === answeredOption.id &&
                answeredOption.selected
              );
            })
          );
        });
      case 'ORDER':
        return correctAnswer.orderedAnswers.some(correctOption => {
          const answeredIndex = question.content.orderedAnswers.findIndex(
            it => {
              return it.id === correctOption.id;
            }
          );
          return (
            answeredIndex > -1 && correctOption.orderNr === answeredIndex + 1
          );
        });
      case 'GROUP':
        return correctAnswer.answerGroupAnswers.some(correctOption => {
          return question.content.answerGroupAnswers.some(answeredOption => {
            return (
              correctOption.id === answeredOption.id &&
              correctOption.answerGroupId === answeredOption.groupId
            );
          });
        });
      default:
        return false;
    }
  }

  endReviewPhase() {
    this.setCurrentStepData(this.continuePerformanceData);
  }

  createAnswerRequest(question) {
    let questionAnswer;
    if (question && question.content) {
      questionAnswer = {};
      questionAnswer.questionId = question.id;
      if (question.content.answerOptions) {
        questionAnswer.answerOptions = question.content.answerOptions
          .filter(option => option.selected)
          .map(option => option.id);
      }
      if (question.content.orderedAnswers) {
        questionAnswer.orderedAnswers = sortBy(
          question.content.orderedAnswers,
          ['orderNr']
        ).map(orderedAnswer => orderedAnswer.id);
      }
      if (question.content.answerGroups) {
        questionAnswer.answerGroupAnswers = question.content.answerGroupAnswers.reduce(
          (answers, answer) => {
            answers[answer.id] = answer.groupId;
            return answers;
          },
          {}
        );
      }
      if (question.type === 'OPEN') {
        questionAnswer.openAnswer = question.openAnswer;
      }
    }
    return {
      performanceId: this.performance.performanceId,
      stageId: this.performance.currentStageId,
      answer: questionAnswer
    };
  }

  setCurrentStepData(data) {
    const previousStageId = this.performance.currentStageId;
    this.performance = data.performance;
    if (this.performance.status === 'FINISHED') {
      this.finishPerformance();
    } else if (
      this.performance.stages.every(
        s => s.isPassed && !s.isRepeat && !s.isFailed
      )
    ) {
      if (this.posttestQuestions && this.posttestQuestions.length > 0) {
        this.phase = PERF_PHASE.POSTTEST;
      } else {
        // This should not happen (all stages passed and performance not finished)
      }
    } else {
      const currentStage = data.performance.stages.find(
        s => s.id === data.performance.currentStageId
      );
      const currentStageMaterials = this.materialsByStage[
        this.performance.currentStageId
      ];
      if (
        currentStage.isRepeat ||
        (previousStageId === currentStage.id &&
          !currentStage.isPassed &&
          !currentStage.isFailed) ||
        !currentStageMaterials ||
        currentStageMaterials.length === 0
      ) {
        // Repetition questions and consecutive correct answers in same (not yet passed) stage
        // should not show materials before question.
        this.materials = [];
        this.activeMaterial = null;
      } else {
        this.materials = currentStageMaterials.sort(
          (m1, m2) => m1.orderNr - m2.orderNr
        );
      }

      // TODO Question phase but no question?
      this.initQuestion();
      if (this.materials && this.materials.length > 0) {
        this.activeMaterial = this.materials[0];
      }
      if (this.phase !== PERF_PHASE.PRETEST) {
        this.phase = this.activeMaterial
          ? PERF_PHASE.MATERIAL
          : PERF_PHASE.QUESTION;
      }
    }
  }

  initQuestion() {
    const currentStageQuestions = this.questionsByStage[
      this.performance.currentStageId
    ];
    if (!currentStageQuestions) {
      this.question = null;
      return;
    }
    const correctionQuestion = currentStageQuestions.find(
      it => it.progressType === 'CORRECTION'
    );
    const repetitionQuestion = currentStageQuestions.find(
      it => it.progressType === 'REPETITION'
    );
    const initialQuestion = currentStageQuestions.find(
      it => it.progressType === 'INITIAL'
    );
    const question = correctionQuestion
      ? correctionQuestion
      : repetitionQuestion ? repetitionQuestion : initialQuestion;

    if (!question) {
      this.question = null;
      return;
    }
    this.question = this.initQuestionAnswerFields(question);
  }

  initQuestionAnswerFields(question) {
    // Adding answer data to questions so MobX would observe them.
    // Could consider adding these fields in server and avoid this here.
    question.isFailed = false;
    switch (question.type) {
      case 'CHOOSE':
        question.content.answerOptions.forEach(answerOption => {
          answerOption.selected = false;
        });
        break;
      case 'ORDER':
        question.content.orderedAnswers.forEach(orderedAnswer => {
          orderedAnswer.orderNr = null;
        });
        break;
      case 'GROUP':
        question.content.answerGroupAnswers.forEach(answerGroupAnswer => {
          answerGroupAnswer.groupId = null;
        });
        break;
      case 'OPEN':
        question.content.openAnswer = '';
        break;
      default:
        const up = new Error('Unknown question type: ' + question.type);
        throw up;
    }
    return question;
  }

  toggleAnswerOption(answerOption) {
    answerOption.selected = !answerOption.selected;
  }

  onOpenAnswerChange(question, answer) {
    question.openAnswer = answer;
  }

  finishMaterial() {
    return new Promise((resolve, reject) => {
      const currentMaterialIdx = findIndex(this.materials, this.activeMaterial);
      if (currentMaterialIdx < this.materials.length - 1) {
        this.activeMaterial = this.materials[currentMaterialIdx + 1];
        resolve();
      } else if (this.question) {
        this.activeMaterial = null;
        this.phase = PERF_PHASE.QUESTION;
        resolve();
      } else {
        this.continuePerformance(this.lesson.taskCode).then(() => resolve());
      }
    });
  }

  startPausePhase(performanceData) {
    this.phase = PERF_PHASE.PAUSE;
    this.setCurrentStepData(performanceData);
  }

  endPause() {
    this.isPausePhase = false;
  }

  setContinueDisabled(isDisabled) {
    this.isContinueDisabled = isDisabled;
  }

  setActiveStage(stage) {
    if (
      stage.id !== this.performance.currentStageId &&
      (!stage.isPassed || stage.isFailed || stage.isRepeat)
    ) {
      runInAction(() => {
        this.performance.currentStageId = stage.id;
        this.performance.currentStageNr = stage.orderNr;
        const stageMaterials = this.materialsByStage[
          this.performance.currentStageId
        ].sort((m1, m2) => m1.orderNr - m2.orderNr);
        this.materials = stageMaterials ? stageMaterials : [];
        if (this.materials.length > 0) {
          this.activeMaterial = this.materials[0];
          this.phase = PERF_PHASE.MATERIAL;
        } else {
          this.phase = PERF_PHASE.QUESTION;
        }
        this.initQuestion();
      });
    }
  }

  get paused() {
    return this.phase === PERF_PHASE.PAUSE;
  }

  /**
   * @returns Order nr of the currently active stage (starting at 1), -1 if not available.
   */
  get activeOrderNr() {
    if (!this.performance || !this.performance.stages) {
      return -1;
    }
    if (this.phase === PERF_PHASE.RESULT) {
      return -1;
    }
    const stage = this.performance.stages.find(
      s => s.id === this.performance.currentStageId
    );
    return stage ? stage.orderNr : -1;
  }

  get activeStage() {
    if (this.activeOrderNr < 1) return null;
    return this.performance.stages[this.activeOrderNr - 1];
  }

  get latestOrderNr() {
    const firstNotPassedStage = this.performance.stages.find(s => !s.isPassed);
    if (firstNotPassedStage) {
      return firstNotPassedStage.orderNr;
    } else {
      return this.performance.stages.length;
    }
  }

  get isInProgress() {
    return !!this.performance && this.performance.status === 'IN_PROGRESS';
  }

  get isPretestPhase() {
    return this.phase === PERF_PHASE.PRETEST;
  }

  get isReviewPhase() {
    return this.phase === PERF_PHASE.REVIEW;
  }

  get activePretestQuestion() {
    return this.pretestQuestions && this.pretestQuestions.length > 0
      ? this.pretestQuestions[0]
      : null;
  }

  get activePosttestQuestion() {
    return this.posttestQuestions && this.posttestQuestions.length > 0
      ? this.posttestQuestions[0]
      : null;
  }

  get isMaterialPhase() {
    return this.isInProgress && this.phase === PERF_PHASE.MATERIAL;
  }

  get isQuestionPhase() {
    return this.isInProgress && this.phase === PERF_PHASE.QUESTION;
  }

  get isResultPhase() {
    return !!this.performance && this.performance.status === 'FINISHED';
  }

  get isTaskClosed() {
    if (
      !this.lesson ||
      this.lesson.allowLatePerformance ||
      (!this.lesson.startTime && !this.lesson.endTime)
    ) {
      return false;
    }
    return !this.isWithinTaskTime;
  }

  get isWithinTaskTime() {
    return !this.isBeforeStartTime && !this.isAfterEndTime;
  }

  get isBeforeStartTime() {
    return this.lesson.startTime && moment().isBefore(this.lesson.startTime);
  }

  get isAfterEndTime() {
    return this.lesson.endTime && moment().isAfter(this.lesson.endTime);
  }
}

export default decorate(PerfStore, {
  lesson: observable,
  performance: observable,
  materials: observable,
  activeMaterial: observable,
  question: observable,
  isStagesSkipAllowed: observable,
  isPretestIntroShown: observable,
  isPosttestIntroShown: observable,
  isContinueDisabled: observable,
  answer: observable,
  correctAnswer: observable,
  wasPartiallyCorrect: observable,
  continuePerformanceData: observable,
  pretestQuestions: observable,
  pretestQuestionCount: observable,
  posttestQuestions: observable,
  posttestQuestionCount: observable,
  phase: observable,

  resetPerformance: action,
  startPerformance: action,
  continuePerformance: action,
  setIsPretestIntroShown: action,
  setIsPosttestIntroShown: action,
  answerPretestQuestion: action,
  answerPosttestQuestion: action,
  finishPretest: action,
  finishPosttest: action,
  skipStages: action,
  startReviewPhase: action,
  endReviewPhase: action,
  setCurrentStepData: action,
  initQuestion: action,
  setQuestionPhase: action,
  toggleAnswerOption: action,
  onOpenAnswerChange: action,
  finishMaterial: action,
  startPausePhase: action,
  endPause: action,
  setContinueDisabled: action,
  initQuestionAnswerFields: action,

  hasFailedQuestion: computed,
  someStageIsFailed: computed,
  shouldRestrictPlayback: computed,
  paused: computed,
  activeOrderNr: computed,
  activeStage: computed,
  latestOrderNr: computed,
  isInProgress: computed,
  isPretestPhase: computed,
  isMaterialPhase: computed,
  isQuestionPhase: computed,
  isReviewPhase: computed,
  isResultPhase: computed,
  isTaskClosed: computed,
  isWithinTaskTime: computed,
  isBeforeStartTime: computed,
  isAfterEndTime: computed,
  answeredPretestQuestionCount: computed,
  answeredPosttestQuestionCount: computed,
  activePretestQuestion: computed,
  activePosttestQuestion: computed,
  answerValidationError: computed
});
