import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { inject, observer } from 'mobx-react';

import PerformanceMaterial from '../materials/PerformanceMaterial';
import PerformanceQuestions from '../questions/PerformanceQuestions';
import PerformanceResult from '../PerformanceResult';
import PerformancePause from './PerformancePause';
import Pretest from './Pretest';
import { PERF_PHASE } from '../../../stores/PerfStore';
import Posttest from './Posttest';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Icon,
  Input,
  Message,
  Segment,
  Transition
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import RouteLeaveGuard from '../../common/RouteLeaveGuard';
import ReactQuill from 'react-quill';
import PerformanceProgress from './PerformanceProgress';
import PerformableLessonFooter from './PerformableLessonFooter';
import styled from 'styled-components';
import { loginInNewTab } from '../../../utils';
import { useIframeDetection } from '../../../utils/iframeUtils';
import PerformanceMessage from './PerformanceMessage';

const PerformanceContainer = styled.div`
  &&& {
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
    border: 0;
    margin: 0 !important;
    display: flex !important;
    flex-direction: column;
    z-index: 102;
  }
`;

const ContentContainer = styled.div`
  overflow: auto;
  height: 100%;
  padding-bottom: 2em;
`;

const FooterContainer = styled.div`
  width: 100%;
`;

function PerformableLessonContainer(props) {
  const [t] = useTranslation();
  const [isTaskNotFound, setTaskNotFound] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const params = useParams();
  const contentRef = useRef();
  const {
    lesson,
    isInProgress,
    phase,
    isBeforeStartTime,
    isAfterEndTime,
    isTaskClosed
  } = props.perfStore;
  const { taskCode, lessonId } = params;

  useEffect(
    () => {
      setLoading(true);
      const { taskCode, lessonId } = params;
      if (taskCode) {
        props.perfStore
          .getLesson(taskCode)
          .then(lessonDetails => {
            if (!lessonDetails) {
              setTaskNotFound(true);
            } else {
              if (props.auth.isAuthenticated) {
                props.invitationStore.checkInvitation(taskCode);
              }
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (lessonId) {
        props.perfStore
          .previewLesson(lessonId)
          .then(lessonDetails => {
            if (!lessonDetails) {
              setTaskNotFound(true);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [params, props.auth.isAuthenticated, props.invitationStore, props.perfStore]
  );

  useEffect(
    () => {
      if (contentRef && contentRef.current && phase !== PERF_PHASE.REVIEW) {
        contentRef.current.scrollTop = 0;
      }
    },
    [phase]
  );

  function handleStart(guestName) {
    // TODO Validate if eligible to start (guest)?
    const sanitizedGuestName = typeof guestName === 'string' ? guestName : null;
    props.perfStore.startPerformance(sanitizedGuestName);
  }

  return (
    <>
      <Helmet>
        <title>
          Mastery • {lesson.lessonName ? lesson.lessonName : 'Ülesanne'}
        </title>
      </Helmet>

      {isLoading ? (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>{t('loading')}</Message.Content>
        </Message>
      ) : (
        <>
          {!isInProgress &&
            phase !== PERF_PHASE.RESULT && (
              <Container>
                <LessonIntro
                  lessonId={lessonId}
                  lesson={lesson}
                  isTaskNotFound={isTaskNotFound}
                  taskCode={taskCode}
                  isBeforeStartTime={isBeforeStartTime}
                  isAfterEndTime={isAfterEndTime}
                  onStart={handleStart}
                  isTaskClosed={isTaskClosed}
                />
              </Container>
            )}
          <Transition
            transitionOnMount={true}
            visible={isInProgress || phase === PERF_PHASE.RESULT}
          >
            <PerformanceContainer>
              <RouteLeaveGuard
                when={isInProgress}
                shouldBlockNavigation={() => isInProgress}
                description={t('performance.routeLeaveGuardDesc')}
              />
              <PerformanceProgress />
              <ContentContainer ref={contentRef}>
                <Container>
                  <PhaseContent
                    phase={props.perfStore.phase}
                    material={props.perfStore.activeMaterial}
                  />
                </Container>
              </ContentContainer>
              <FooterContainer>
                <PerformanceMessage />
                <Segment raised style={{ marginTop: 0 }}>
                  <Container>
                    <PerformableLessonFooter />
                  </Container>
                </Segment>
              </FooterContainer>
            </PerformanceContainer>
          </Transition>
        </>
      )}
    </>
  );
}

const AuthenticationContainer = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 350px !important;
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    align-self: center;
  }
`;

const LessonIntro = inject('auth')(
  observer(props => {
    const [t] = useTranslation();
    const isIframe = useIframeDetection();
    const {
      auth,
      lessonId,
      lesson,
      isTaskNotFound,
      taskCode,
      isBeforeStartTime,
      isAfterEndTime,
      onStart,
      isTaskClosed
    } = props;
    const [guestName, setGuestName] = useState('');

    return (
      <>
        <Segment>
          <Header content={lesson.lessonName} />
          {isTaskNotFound && (
            <>
              <Header>
                {lessonId
                  ? t('performance.header.preview')
                  : t('performance.header.performance')}
              </Header>
              <Message
                negative
                content={
                  <Trans i18nKey="performance.taskCodeNotFound">
                    Avatud ülesannet koodiga <strong>{{ taskCode }}</strong> ei
                    leidu
                  </Trans>
                }
              />
            </>
          )}
          {isBeforeStartTime && (
            <Message warning>{t('performance.isBeforeStartTimeMsg')}</Message>
          )}
          {isAfterEndTime && (
            <Message warning>{t('performance.isAfterEndTimeMsg')}</Message>
          )}
          {lesson.description && (
            <ReactQuill
              value={lesson.description}
              modules={{ toolbar: false }}
              readOnly
            />
          )}
          {auth.isAuthenticated && (
            <Button
              style={{ marginTop: '1em' }}
              primary
              onClick={onStart}
              content={t('performance.footer.startBtn')}
              disabled={!lesson || !lesson.id || isTaskClosed}
            />
          )}
        </Segment>

        {!auth.isAuthenticated &&
          !isTaskNotFound &&
          !isBeforeStartTime &&
          !isAfterEndTime && (
            <AuthenticationContainer>
              <Message info attached="top" style={{ width: '100%' }}>
                {lesson.isAuthenticationRequired
                  ? t('performance.authModal.authenticationRequired')
                  : t(
                      'performance.authModal.authenticationOrGuestNameRequired'
                    )}
              </Message>
              <Segment
                attached="bottom"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {!lesson.isAuthenticationRequired && (
                  <>
                    <Form>
                      <Form.Field>
                        <label>{t('performance.authModal.guestName')}</label>
                        <Input
                          value={guestName}
                          name="guestName"
                          maxLength={100}
                          onChange={e => setGuestName(e.target.value)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Button
                          fluid
                          onClick={() => onStart(guestName)}
                          disabled={!guestName || guestName.length === 0}
                          content={t('performance.authModal.continueAsGuest')}
                          icon="play"
                        />
                      </Form.Field>
                    </Form>
                    <Divider
                      content={t('home.divider.or')}
                      hidden
                      style={{ alignSelf: 'center' }}
                    />
                  </>
                )}

                <Button
                  fluid
                  primary
                  type="button"
                  onClick={isIframe ? loginInNewTab : () => auth.login()}
                  style={{
                    justifyContent: 'center',
                    marginTop: !lesson.isAuthenticationRequired ? '1em' : null
                  }}
                  content={t('performance.authModal.login')}
                />
              </Segment>
            </AuthenticationContainer>
          )}
      </>
    );
  })
);

const PhaseContent = observer(({ phase, material }) => {
  return phase === PERF_PHASE.PRETEST ? (
    <Pretest />
  ) : phase === PERF_PHASE.POSTTEST ? (
    <Posttest />
  ) : phase === PERF_PHASE.MATERIAL ? (
    <PerformanceMaterial material={material} />
  ) : phase === PERF_PHASE.QUESTION || phase === PERF_PHASE.REVIEW ? (
    <PerformanceQuestions />
  ) : phase === PERF_PHASE.PAUSE ? (
    <PerformancePause />
  ) : phase === PERF_PHASE.RESULT ? (
    <PerformanceResult />
  ) : null;
});

export default inject('perfStore', 'auth', 'invitationStore', 'appState')(
  observer(PerformableLessonContainer)
);
