import { action, computed, decorate, observable } from 'mobx';
import LessonOverview from './model/lessonOverview';
import PublicLesson from './model/publicLesson';
import {
  fetchLessons,
  fetchPublicLessons,
  getLessonOverview,
  saveLesson
} from '../../api/lessons';
import LessonListItem from './model/lessonListItem';
import sortBy from 'lodash/sortBy';

class LessonOverviewStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  lessons = []; // LessonListItem[]
  activeLesson = new LessonOverview(this);
  isLoadingLesson = false;

  publicLessons = []; // LessonListItem[]
  activePublicLesson = new PublicLesson(this);
  publicLessonsPage = {}; // Page<LessonListItem>

  async loadLesson(lessonId) {
    this.setLoadingLesson(true);
    this.setActiveLesson(new LessonOverview(this));
    try {
      const { data } = await getLessonOverview(lessonId);
      const lesson = new LessonOverview(this, data.id);
      lesson.updateFromJson(data);
      this.setActiveLesson(lesson);
    } catch (e) {
      this.rootStore.stores.appState.addMessage({
        error: true,
        text: 'Ei leidnud tundi'
      });
    } finally {
      this.setLoadingLesson(false);
    }
  }

  setLoadingLesson(isLoading) {
    this.isLoadingLesson = isLoading;
  }

  async loadLessons() {
    const response = await fetchLessons();
    response.data.forEach(json => this.updateFromServer(json));
  }

  get latestUpdatedLessons() {
    return sortBy(this.lessons, l => new Date(l.updated)).reverse();
  }

  get sortedActiveLessonTasks() {
    if (!this.activeLesson || !this.activeLesson.tasks) return [];
    return sortBy(
      this.activeLesson.tasks,
      task => new Date(task.created)
    ).reverse();
  }

  async loadPublicLessons(pageable) {
    const response = await fetchPublicLessons(pageable);
    response.data.content.forEach(json => this.updatePublicLesson(json));
    this.publicLessonsPage = response.data;
    return this.publicLessons;
  }

  createLesson(name) {
    return saveLesson({ name });
  }

  setActiveLesson(lessonOverview) {
    this.activeLesson = lessonOverview;
  }

  setActivePublicLesson(publicLesson) {
    this.activePublicLesson = publicLesson;
  }

  updateFromServer(json) {
    let lesson = this.lessons.find(lesson => lesson.id === json.id);
    if (!lesson) {
      lesson = new LessonListItem(this, json.id);
      this.lessons.push(lesson);
    }
    if (json.deleted) {
      this.removeLesson(lesson);
    } else {
      lesson.updateFromJson(json);
    }
    return lesson;
  }

  updatePublicLesson(json) {
    let lesson = this.publicLessons.find(lesson => lesson.id === json.id);
    if (!lesson) {
      lesson = new PublicLesson(this, json.id);
      this.publicLessons.push(lesson);
    }
    lesson.updateFromJson(json);
  }

  removeLesson(lesson) {
    this.lessons = this.lessons.filter(l => l.id !== lesson.id);
  }

  updateLesson(editableLesson) {
    const lessonOverview = this.lessons.find(l => l.id === editableLesson.id);
    if (lessonOverview) {
      lessonOverview.name = editableLesson.name;
    }
  }
}

export default decorate(LessonOverviewStore, {
  lessons: observable,
  activeLesson: observable,
  isLoadingLesson: observable,
  activePublicLesson: observable,
  publicLessons: observable,
  publicLessonsPage: observable,

  setActiveLesson: action,
  setActivePublicLesson: action,
  setLoadingLesson: action,
  updateFromServer: action,
  updatePublicLesson: action,
  removeLesson: action,
  updateLesson: action,

  latestUpdatedLessons: computed,
  sortedActiveLessonTasks: computed
});
