import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';
import YouTube from 'react-youtube';
import { inject, observer } from 'mobx-react';
import { autorun, computed, decorate } from 'mobx';
import moment from 'moment';
import VideoWrapper, {
  VideoWrapperPlaceholder
} from '../../common/VideoWrapper';
import './PerformanceVideo.css';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { useTranslation, withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import uniqueId from 'lodash/uniqueId';

const VideoContainer = styled.div`
  margin: auto;
  background-color: black;

  ${props =>
    props.expanded
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: 9999;
          max-width: 160vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
        `
      : css`
          // This is intended to keep video controls visible when they would exit viewport
          max-width: 100vh;
        `};
`;

class PerformanceVideo extends Component {
  state = {
    isPlaying: false,
    isPaused: true,
    isHidden: false,
    isError: false,
    isExpanded: false,
    reloadCount: 0,
    youtubePlayer: null,
    hasFinished: false,
    progress: 0,
    timeElapsed: 0,
    intervalId: null,
    videoDurationMs: 0,
    seekedTime: 0
  };

  get playbackOpts() {
    const { shouldRestrictPlayback } = this.props.perfStore;
    return {
      height: '390',
      width: '640',
      playerVars: {
        controls: shouldRestrictPlayback ? 0 : 1,
        disablekb: shouldRestrictPlayback ? 1 : 0,
        start: this.props.material.videoStart
          ? parseInt(this.props.material.videoStart, 10)
          : 0,
        end: this.props.material.videoEnd
          ? parseInt(this.props.material.videoEnd, 10)
          : undefined,
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    };
  }

  handleVideoEnded = () => {
    if (this.props.perfStore.shouldRestrictPlayback) {
      this.setState({
        isPlaying: false,
        isHidden: true,
        hasFinished: true,
        isPaused: true
      });
      this.props.perfStore.setContinueDisabled(false);
    }
  };

  handleOnReady = e => {
    this.setState({ youtubePlayer: e.target });
  };

  handleOnPlay = () => {
    if (!this.state.youtubePlayer) return;
    this.setElapsedTime();
    this.updateSeekedTime(this.state.youtubePlayer.getCurrentTime());
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
    this.setState({ isPlaying: true });
    this.setState({ intervalId: setInterval(this.setElapsedTime, 1000) });
  };

  handlePause = () => {
    if (!this.state.youtubePlayer) return;
    this.setElapsedTime();
    this.updateSeekedTime(this.state.youtubePlayer.getCurrentTime());
    if (this.state.isHidden) {
      this.setState({ isHidden: false });
      return;
    }
    if (this.state.isPaused) {
      this.state.youtubePlayer.playVideo();
      this.setState({ isPaused: !this.state.isPaused });
      this.setState({ intervalId: setInterval(this.setElapsedTime, 1000) });
    } else {
      this.state.youtubePlayer.pauseVideo();
      this.setState({ isPaused: !this.state.isPaused });
      clearInterval(this.state.intervalId);
    }
  };

  handleOnError = err => {
    if (this.state.reloadCount > 3) {
      this.showLoadingErrorMessage();
    }
    // TODO Should consider persisting failed video load so we could use it to notify the lesson creator.
    this.setState({ isError: true });
  };

  showLoadingErrorMessage = debounce(() => {
    this.props.appState.addMessage({
      text: this.props.t('performance.video.loadFailMsg'),
      warning: true,
      isPersistent: true
    });
  }, 500);

  handleReloadPlayer = () => {
    this.setState({
      isError: false,
      isPlaying: false,
      isHidden: true,
      youtubePlayer: null,
      hasFinished: false,
      seekedTime: 0,
      reloadCount: this.state.reloadCount + 1
    });
    setTimeout(() => {
      this.setState({ isHidden: false });
    });
  };

  handleStartPlayback = () => {
    if (this.state.youtubePlayer && !this.state.isHidden) {
      this.setState({ isPlaying: true });
      this.state.youtubePlayer.playVideo();
    } else {
      this.setState({ isHidden: false });
    }
  };

  setElapsedTime = () => {
    const time = this.getElapsedTime();
    if (time) {
      this.setState({ timeElapsed: time });
    }
  };

  getElapsedTime = () => {
    if (this.state.youtubePlayer && this.playbackOpts) {
      return (
        this.state.youtubePlayer.getCurrentTime() -
        this.playbackOpts.playerVars.start
      );
    }
    return null;
  };

  updateSeekedTime = time => {
    this.setState({
      seekedTime: {
        time: time - this.playbackOpts.playerVars.start,
        key: uniqueId('seek')
      }
    });
  };

  handleRewind = () => {
    if (!this.state.youtubePlayer) return;
    const currentTime = this.state.youtubePlayer.getCurrentTime();
    let seekedTime;
    if (
      currentTime > 10 &&
      currentTime - 10 > this.playbackOpts.playerVars.start
    ) {
      seekedTime = currentTime - 10;
      this.state.youtubePlayer.seekTo(seekedTime, true);
      if (!this.state.isPaused) {
        this.state.youtubePlayer.playVideo();
      }
    } else {
      seekedTime = this.playbackOpts.playerVars.start;
      this.state.youtubePlayer.seekTo(seekedTime, true);
      if (!this.state.isPaused) {
        this.state.youtubePlayer.playVideo();
      }
    }
    this.updateSeekedTime(seekedTime);
  };

  handleSeekToStart = () => {
    if (!this.state.youtubePlayer) return;
    let seekedTime = parseInt(this.props.material.videoStart, 10);
    this.state.youtubePlayer.seekTo(seekedTime);
    this.updateSeekedTime(seekedTime);
  };

  handleSeekToEnd = () => {
    if (!this.state.youtubePlayer) return;
    let seekedTime = parseInt(this.props.material.videoEnd, 10);
    this.state.youtubePlayer.seekTo(seekedTime);
    this.updateSeekedTime(seekedTime);
  };

  handleExpandToggle = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  formatElapsedTime = elapsedTimeInSeconds => {
    const elapsedTime = moment.utc(elapsedTimeInSeconds * 1000).format('mm:ss');
    let videoDurationInSeconds =
      this.playbackOpts.playerVars.end - this.playbackOpts.playerVars.start;
    const totalTime = moment.utc(videoDurationInSeconds * 1000).format('mm:ss');
    return `${elapsedTime} / ${totalTime}`;
  };

  formatSeconds = seconds => {
    return moment(seconds * 1000).format('mm:ss');
  };

  componentDidMount() {
    if (this.props.perfStore.shouldRestrictPlayback) {
      this.props.perfStore.setContinueDisabled(true);
    }
    this.disposer = autorun(() => {
      if (this.props.material) {
        // Reset video playback state upon active video change
        // (this is run every time the observable material has changed)
        this.setState({
          isPlaying: false,
          isHidden: false,
          youtubePlayer: null,
          hasFinished: false
        });
      }
    });
  }

  componentWillUnmount() {
    if (typeof this.disposer === 'function') {
      this.disposer();
    }
    clearInterval(this.state.intervalId);
    this.props.perfStore.setContinueDisabled(false);
  }

  render() {
    const { t } = this.props;
    const { isHidden, isPlaying, isError, hasFinished } = this.state;
    const { youtubeId } = this.props.material;
    const { shouldRestrictPlayback } = this.props.perfStore;
    const playerClassname = classNames({
      'performance-video': true,
      'performance-video--strict':
        shouldRestrictPlayback && !!this.state.youtubePlayer
    });
    return (
      <VideoContainer expanded={this.state.isExpanded}>
        {isHidden ? (
          <VideoWrapper>
            <VideoWrapperPlaceholder>
              <Button
                secondary
                onClick={this.handleStartPlayback}
                disabled={isPlaying}
                icon={hasFinished ? 'undo' : 'play'}
                labelPosition="left"
                content={
                  hasFinished
                    ? t('performance.video.watchAgain')
                    : t('performance.video.startWatching')
                }
              />
            </VideoWrapperPlaceholder>
          </VideoWrapper>
        ) : (
          <VideoWrapper>
            {shouldRestrictPlayback &&
              this.state.youtubePlayer && (
                <Button
                  className="video-cover"
                  onClick={this.handlePause}
                  inverted
                  icon={this.state.isPaused ? 'play' : 'pause'}
                  aria-label={
                    this.state.isPaused
                      ? t('performance.video.startPlayback')
                      : t('performance.video.stopPlayback')
                  }
                />
              )}

            <YouTube
              className={playerClassname}
              videoId={youtubeId}
              onReady={this.handleOnReady}
              onPlay={this.handleOnPlay}
              onEnd={this.handleVideoEnded}
              onError={this.handleOnError}
              opts={this.playbackOpts}
            />
          </VideoWrapper>
        )}
        {!isError &&
          this.state.youtubePlayer && (
            <VideoControls
              shouldRestrictPlayback={shouldRestrictPlayback}
              onSeekToStart={this.handleSeekToStart}
              onRewind={this.handleRewind}
              onPause={this.handlePause}
              onSeekToEnd={this.handleSeekToEnd}
              isPaused={this.state.isPaused}
              onExpand={this.handleExpandToggle}
              isExpanded={this.state.isExpanded}
              isPlaying={isPlaying}
              elapsedTime={this.state.timeElapsed}
              playbackOpts={this.playbackOpts}
              isHidden={this.state.isHidden}
              seekedTime={this.state.seekedTime}
            />
          )}
        {isError && (
          <Button
            icon="refresh"
            labelPosition="left"
            onClick={this.handleReloadPlayer}
            content={t('performance.video.retryLoad')}
          />
        )}
      </VideoContainer>
    );
  }
}

const VideoProgress = styled.div`
  height: 5px;
  background-color: #2e731b;
  transition: width 1s linear;
  width: ${props => props.progressPercent + '%'};
`;

const VideoControls = observer(
  ({
    shouldRestrictPlayback,
    onSeekToStart,
    onRewind,
    onPause,
    onSeekToEnd,
    onExpand,
    isPaused,
    isExpanded,
    isPlaying,
    elapsedTime,
    playbackOpts,
    isHidden,
    seekedTime // To provide a mechanism for the progress bar to re-render without transition
  }) => {
    const [t] = useTranslation();
    const [totalTime, setTotalTime] = useState(0);
    const [progressPercent, setProgressPercent] = useState(0);
    const [seekKey, setSeekKey] = useState(uniqueId('seek'));

    useEffect(
      () => {
        if (!playbackOpts || !playbackOpts.playerVars) return;
        const { start, end } = playbackOpts.playerVars;
        const videoDurationInSeconds = end - start;
        setTotalTime(videoDurationInSeconds);
      },
      [playbackOpts]
    );

    useEffect(
      () => {
        // Add 1s to elapsed time for the 1s width transition to not lag behind real progress
        const pct =
          elapsedTime < 1
            ? 0
            : Math.min((elapsedTime + 1) / totalTime * 100, 100);
        setProgressPercent(pct);
      },
      [elapsedTime]
    );

    useEffect(
      () => {
        const { time, key } = seekedTime;
        const pct = time < 1 ? 0 : Math.min(time / totalTime * 100, 100);
        setSeekKey(key);
        setProgressPercent(pct);
      },
      [seekedTime]
    );

    return (
      <>
        {/*<VideoProgress progressPercent={progressPercent} key={seekKey} />*/}
        <Button.Group attached="bottom">
          <Popup
            basic
            content={t('performance.video.rewindToStartAria')}
            position="top center"
            trigger={
              <Button
                secondary
                disabled={!isPlaying}
                onClick={onSeekToStart}
                icon="angle double left"
                aria-label={t('performance.video.rewindToStartAria')}
              />
            }
          />
          <Popup
            basic
            content={t('performance.video.rewindAria')}
            position="top center"
            trigger={
              <Button
                secondary
                disabled={!isPlaying}
                onClick={onRewind}
                icon="angle left"
                aria-label={t('performance.video.rewindAria')}
              />
            }
          />
          <Button
            secondary
            onClick={onPause}
            icon={isHidden ? 'undo' : isPaused ? 'play' : 'pause'}
            aria-label={
              isPaused
                ? t('performance.video.startPlayback')
                : t('performance.video.stopPlayback')
            }
          />
          {!shouldRestrictPlayback && (
            <Popup
              basic
              content={t('performance.video.seekToEndAria')}
              position="top center"
              trigger={
                <Button
                  secondary
                  disabled={!isPlaying}
                  onClick={onSeekToEnd}
                  icon="angle double right"
                  aria-label={t('performance.video.seekToEndAria')}
                />
              }
            />
          )}
          <Button
            secondary
            icon={isExpanded ? 'compress' : 'expand'}
            onClick={onExpand}
            aria-label={t('performance.video.expand')}
          />
          {shouldRestrictPlayback && (
            <Popup
              trigger={
                <Button
                  secondary
                  icon="question circle"
                  aria-label={t('performance.video.info')}
                />
              }
              on="click"
              content="YouTube video kontrollimist on antud tunni puhul piiratud. Mängimist saab kontrollida video all oleva riba pealt ning video sisu peale vajutades."
            />
          )}
        </Button.Group>
      </>
    );
  }
);

PerformanceVideo.propTypes = {
  material: PropTypes.object.isRequired
};

export default withTranslation()(
  inject('perfStore', 'appState')(
    observer(
      decorate(PerformanceVideo, {
        playbackOpts: computed
      })
    )
  )
);
