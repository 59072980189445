import React from 'react';

import Routes from '../../config/routes';
import MessageList from './MessageList';
import './App.css';
import { useTranslation } from 'react-i18next';

function App() {
  const { ready } = useTranslation();
  return (
    <>
      <MessageList />
      {/* Wait for translations to load before showing any app content */}
      {ready && <Routes />}
      {/*{process.env.REACT_APP_VERSION &&*/}
      {/*  !this.state.isInIframe && (*/}
      {/*    <span id="app-version">*/}
      {/*      Mastery v{process.env.REACT_APP_VERSION}*/}
      {/*    </span>*/}
      {/*  )}*/}
    </>
  );
}

export default App;
