import React, { useEffect, useState } from 'react';
import API from '../../api/API';
import { Header, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { fmtDateTime } from '../../utils/dateUtils';

function UserPerformances() {
  const [t] = useTranslation();
  const [performancesByTask, setPerformancesByTask] = useState([]);

  useEffect(() => {
    API.get('/api/performances').then(({ data }) => {
      setPerformancesByTask(data);
    });
  }, []);

  return (
    <>
      <Header>{t('myPerformances.header')}</Header>
      <List relaxed>
        {performancesByTask.map(taskPerformances => (
          <List.Item key={taskPerformances.taskId}>
            <List.Header>
              <Link to={`/perform/${taskPerformances.taskCode}`}>
                {taskPerformances.lessonName}
              </Link>
            </List.Header>
            <List.Description>
              {t('myPerformances.latestPerformanceStartTime')}:{' '}
              {fmtDateTime(taskPerformances.latestPerformanceStartTime)}
            </List.Description>
          </List.Item>
        ))}
      </List>
    </>
  );
}

export default UserPerformances;
