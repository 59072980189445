import { action, computed, decorate, observable } from 'mobx';
import TaskOverview from './model/taskOverview';
import { fetchTask, fetchTasks } from '../../api/tasks';
import orderBy from 'lodash/orderBy';

class TaskOverviewStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  tasks = [];
  activeTask = new TaskOverview(this);

  orderColumn = 'startTime';
  isDescending = true;

  async loadTask(taskId) {
    this.activeTask = new TaskOverview(this); // reset to avoid staleness
    const { data } = await fetchTask(taskId);
    this.setActiveTask(this.updateFromServer(data));
  }

  async loadTasks() {
    const response = await fetchTasks();
    response.data.forEach(json => this.updateFromServer(json));
  }

  setActiveTask(task) {
    this.activeTask = task ? task : new TaskOverview(this);
  }

  updateFromServer(json) {
    let task = this.tasks.find(t => t.id === json.id);
    if (!task) {
      task = new TaskOverview(this, json.id);
      this.tasks.push(task);
    }
    task.updateFromJson(json);
    return task;
  }

  removeTask(taskId) {
    this.tasks = this.tasks.filter(task => task.id !== taskId);
  }

  setTaskOrder(column) {
    if (this.orderColumn !== column) {
      this.isDescending = false;
      this.orderColumn = column;
    } else {
      this.isDescending = !this.isDescending;
    }
  }

  get orderedTasks() {
    return orderBy(
      this.tasks,
      [this.orderColumn],
      this.isDescending ? 'desc' : 'asc'
    );
  }
}

export default decorate(TaskOverviewStore, {
  tasks: observable,
  activeTask: observable,
  orderColumn: observable,
  isDescending: observable,

  orderedTasks: computed,

  setActiveTask: action,
  updateFromServer: action,
  removeTask: action,
  setTaskOrder: action
});
