import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup, Ref } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

/**
 * TODO i18n
 */
function CopyToClipboardIcon(props) {
  const [t] = useTranslation();
  const iconRef = useRef(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { textToCopy, message, onCopy } = props;

  function openPopup() {
    setPopupOpen(true);
    setTimeout(() => setPopupOpen(false), 1000);
  }

  return (
    <>
      <CopyToClipboard text={textToCopy} onCopy={onCopy}>
        <span>
          <Ref innerRef={iconRef}>
            <Icon
              title="Kopeeri"
              style={{ cursor: 'pointer' }}
              name="copy outline"
              onClick={openPopup}
            />
          </Ref>
        </span>
      </CopyToClipboard>
      <Popup
        position="top center"
        content={message ? message : t('copyToClipboardBtn.copiedMsg')}
        context={iconRef}
        open={isPopupOpen}
      />
    </>
  );
}

CopyToClipboardIcon.propTypes = {
  buttonLabel: PropTypes.string,
  textToCopy: PropTypes.string
};

export default CopyToClipboardIcon;
