import { useState } from 'react';

function useIframeDetection() {
  const [isIframe] = useState(isInIframe());
  return isIframe;
}

function isInIframe() {
  return window !== window.parent;
}

function postMessageToParent(message) {
  const parent = window.parent;
  if (isInIframe() && parent && typeof parent.postMessage === 'function') {
    parent.postMessage(message, process.env.REACT_APP_MASTERY_API);
  }
}

export { useIframeDetection, isInIframe, postMessageToParent };
