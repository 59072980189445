import React, { useEffect, useState } from 'react';
import {
  Confirm,
  Dropdown,
  Header,
  Icon,
  Menu,
  Message,
  Segment
} from 'semantic-ui-react';
import { useHistory, useRouteMatch } from 'react-router';
import { inject, observer } from 'mobx-react';
import TaskPerformances2 from './TaskPerformances2';
import { getTaskLink } from '../../../../utils/taskUtils';
import { cloneLesson } from '../../../../api/lessons';
import { useTranslation } from 'react-i18next';
import TaskForm from './TaskForm';
import TaskShareButton from './TaskShareButton';
import { Link } from 'react-router-dom';

function TaskOverview(props) {
  const [t] = useTranslation();
  const { activeTask } = props.taskOverviewStore;
  const taskLink = getTaskLink(activeTask.code);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(
    () => {
      const taskId = props.taskId ? props.taskId : match.params.taskId;
      if (taskId) {
        props.taskOverviewStore.loadTask(taskId);
      }
      return () => {
        props.taskOverviewStore.setActiveTask(null);
      };
    },
    [props.taskId, match.params.taskId, props.taskOverviewStore]
  );

  async function handleDelete() {
    try {
      await props.taskOverviewStore.activeTask.delete();
      setOpen(false);
      props.appState.addMessage({
        success: true,
        text: t('taskOverview.deleteMsg')
      });
      if (match.params.taskId) {
        history.replace('/tasks');
      }
      if (typeof props.onDelete === 'function') {
        props.onDelete(activeTask.id);
      }
    } catch (e) {
      // TODO
    }
  }

  function handleTaskSaved(savedTask) {
    // TODO Update store?
    props.appState.addMessage({
      success: true,
      text: t('taskOverview.taskSavedMsg')
    });
  }

  function handleEdit() {
    history.push(`/lessons/${activeTask.lessonId}/edit`);
  }

  function handleCreateLesson() {
    cloneLesson(activeTask.lessonId).then(response => {
      const newLessonId = response.data;
      history.push(`/lessons/${newLessonId}/edit`);
    });
  }

  return !activeTask || !activeTask.code ? (
    <Message icon>
      <Icon name="circle notched" loading />
      <Message.Content>{t('loading')}</Message.Content>
    </Message>
  ) : (
    <>
      <Header>
        <Header.Content>{activeTask.lessonName}</Header.Content>
        <Header.Subheader>
          <a href={taskLink} target="_blank" rel="noreferrer noopener">
            {taskLink}
          </a>
        </Header.Subheader>
      </Header>
      {/* TODO Task should have an description field for teacher to be able to give task specific instructions */}

      <Segment attached="top" secondary size="small">
        <Menu stackable secondary>
          <TaskShareButton
            primary
            taskCode={activeTask.code}
            lessonName={activeTask.lessonName}
          />
          {activeTask.originLessonId && (
            <Menu.Item as={Link} to={`/lessons/${activeTask.originLessonId}`}>
              {t('taskOverview.viewOriginLesson')}
            </Menu.Item>
          )}

          <Menu.Menu position="right">
            <Dropdown item icon="ellipsis vertical" text="Tegevused">
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleEdit}>
                  <Icon name="edit" /> {t('taskOverview.editBtn')}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleCreateLesson}>
                  <Icon name="clone" /> {t('taskOverview.createLessonBtn')}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setOpen(true)}>
                  <Icon name="trash" /> {t('taskOverview.deleteBtn')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
          <Confirm
            confirmButton={t('taskOverview.deleteConfirm.confirm')}
            cancelButton={t('taskOverview.deleteConfirm.cancel')}
            content={t('taskOverview.deleteConfirm.content')}
            open={open}
            onCancel={() => setOpen(false)}
            onConfirm={handleDelete}
          />
        </Menu>
      </Segment>
      <Segment attached="bottom">
        <TaskForm task={activeTask} readOnly onSuccess={handleTaskSaved} />
      </Segment>

      <Header as="h4">
        <Icon name="checkmark" />
        <Header.Content>{t('taskOverview.performances')}</Header.Content>
      </Header>
      <TaskPerformances2 task={activeTask} />
    </>
  );
}

export default inject('taskOverviewStore', 'appState')(observer(TaskOverview));
