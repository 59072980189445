import React from 'react';
import { Header, List, Message } from 'semantic-ui-react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const GuideImage = styled.img`
  max-width: 100%;
`;

const imageWidth = 800;

function LTIConfigurationGuide() {
  const { platform } = useParams();

  return (
    <>
      <Header>Liidestusjuhend: {platform}</Header>
      <p>
        Mastery kasutab õppesüsteemidega liidestumiseks{' '}
        <a
          href="https://www.imsglobal.org/spec/lti/v1p3/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LTI 1.3
        </a>{' '}
        ja{' '}
        <a
          href="https://www.imsglobal.org/lti-advantage-overview"
          target="_blank"
          rel="noopener noreferrer"
        >
          LTI Advantage
        </a>{' '}
        standardeid.
      </p>

      {platform === 'moodle' ? (
        <>
          <Message info>
            Liidestuse jaoks peab Moodle versioon olema{' '}
            <strong>&nbsp;>= 3.7</strong>
          </Message>
          <List ordered divided relaxed>
            <List.Item>
              <Link to="/lti-configuration" target="_blank">
                Lisa uus liidestus Mastery keskkonnas
              </Link>
            </List.Item>
            <List.Item>
              <p>Lisa Moodle sees 'External tool'</p>
              <GuideImage
                src={`https://res.cloudinary.com/mastery-assets/image/upload/w_${imageWidth},q_90/v1613235410/static/mastery_moodle_1_lyyutq.png`}
                alt="Moodle external tool creation"
              />
            </List.Item>
            <List.Item>
              <p>
                Kopeeri väärtused Mastery liidestusest Moodle välise tööriista
                sätetesse
              </p>
              <GuideImage
                src={`https://res.cloudinary.com/mastery-assets/image/upload/w_${imageWidth},q_90/v1613235410/static/mastery_moodle_3_xesano.png`}
                alt="Copy Mastery LTI tool configuration to Moodle"
              />
            </List.Item>
            <List.Item>
              <p>Luba Moodle välisel tööriistal saata hindeid</p>
              <GuideImage
                src={`https://res.cloudinary.com/mastery-assets/image/upload/w_${imageWidth},q_90/v1613235410/static/mastery_moodle_4_bhi049.png`}
                alt="Moodle external tool configuration (grading)"
              />
            </List.Item>
            <List.Item>
              <p>
                Kopeeri väärtused Moodle välisest tööriistast Mastery
                liidestusvormile
              </p>
              <GuideImage
                src={`https://res.cloudinary.com/mastery-assets/image/upload/w_${imageWidth},q_90/v1613235410/static/mastery_moodle_6_uh9ahb.png`}
                alt="Copy Moodle tool configuration to Mastery"
              />
            </List.Item>
            <List.Item>
              <p>
                Mastery sisu lisamiseks vali eelnevalt konfigureeritud väline
                tööriist ning kasuta sisu valimise nuppu
              </p>
              <GuideImage
                src={`https://res.cloudinary.com/mastery-assets/image/upload/w_${imageWidth},q_90/v1613235409/static/mastery_moodle_7_nol3mu.png`}
                alt="Adding previously configured Mastery LTI tool as content in Moodle"
              />
            </List.Item>
          </List>
        </>
      ) : (
        <Message>Tundmatu liidestus: {platform}</Message>
      )}
    </>
  );
}

export default LTIConfigurationGuide;
