import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const DragHandle = styled(Button).attrs({
  icon: 'arrows alternate',
  compact: true,
  circular: true,
  basic: true,
  title: 'Lohista järjekorra muutmiseks' // TODO i18n
})`
  &&& {
    cursor: move;
    white-space: nowrap;
  }
`;

export default DragHandle;
