import React from 'react';
import { inject, observer } from 'mobx-react';
import { Header } from 'semantic-ui-react';

import Question from './Question';
import AddButton from '../AddButton';
import { useTranslation } from 'react-i18next';

function Questions(props) {
  const [t] = useTranslation();
  const { stage } = props;
  return (
    <React.Fragment>
      <Header as="h4">{t('lessonEdit.questions.openQuestions')}</Header>
      <AddButton
        content={t('lessonEdit.questions.addOpenQuestionBtn')}
        onClick={() => stage.createQuestion('OPEN')}
      />
      {stage.openQuestions.map((question, index) => (
        <Question key={question.id} question={question} index={index} />
      ))}

      <Header as="h4">{t('lessonEdit.questions.automaticQuestions')}</Header>
      <AddButton
        content={t('lessonEdit.questions.addAutomaticQuestion')}
        onClick={() => stage.createQuestion()}
      />
      {stage.automaticQuestions.map((question, index) => (
        <Question key={question.id} question={question} index={index} />
      ))}
    </React.Fragment>
  );
}

export default inject('lessonEditStore')(observer(Questions));
