import React from 'react';
import { Container, Grid, Label, List } from 'semantic-ui-react';

function OrderAnswer(props) {
  const { answeredQuestion } = props;

  return (
    <Container>
      <List>
        {answeredQuestion.answer.options.map((option, idx) => (
          <List.Item key={`${answeredQuestion.eventId}oa${option.id}`}>
            <Grid>
              <Grid.Column width={1}>
                <Label color={option.isCorrect ? 'green' : 'red'} horizontal>
                  {idx + 1}.
                </Label>
              </Grid.Column>
              <Grid.Column width={15}>
                <span style={{ whiteSpace: 'pre-wrap' }}>{option.text}</span>
              </Grid.Column>
            </Grid>
          </List.Item>
        ))}
      </List>
    </Container>
  );
}

export default OrderAnswer;
