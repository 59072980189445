import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Button, Label } from 'semantic-ui-react';
import StageMaterialsModal from './StageMaterialsModal';
import ProgressCellContainer from './ProgressCellContainer';
import './ProgressCell.css';

function ProgressCell(props) {
  const { stage } = props;
  const { orderNr, isFailed, isPassed } = props.stage;
  const { activeOrderNr, performance, materialsByStage } = props.perfStore;
  const stageMaterials = materialsByStage[stage.id];
  const isActive = orderNr === activeOrderNr;
  const [isStageModalOpen, setIsStageModalOpen] = useState(false);

  function handleClick() {
    const { stage, perfStore } = props;
    if (stage.isPassed) {
      setIsStageModalOpen(true);
    } else if (perfStore.performance.strictness === 'UNRESTRICTED') {
      perfStore.setActiveStage(stage);
    }
  }

  const isPerformanceFinished =
    performance && performance.status === 'FINISHED';
  const isClickDisabled =
    !performance ||
    isPerformanceFinished ||
    (performance.strictness !== 'UNRESTRICTED' && !stage.isPassed) ||
    (stage.isFailed || stage.isRepeat || isActive);
  const color = isFailed
    ? 'red'
    : isActive ? 'blue' : isPassed || isPerformanceFinished ? 'green' : null;

  return (
    <>
      <ProgressCellContainer>
        <Label
          circular
          className={isActive ? 'active-cell' : null}
          as={Button}
          disabled={isClickDisabled}
          onClick={handleClick}
          color={color}
          content={stage.orderNr}
        />
      </ProgressCellContainer>
      <StageMaterialsModal
        stage={stage}
        materials={stageMaterials}
        open={isStageModalOpen}
        onClose={() => setIsStageModalOpen(false)}
      />
    </>
  );
}

ProgressCell.propTypes = {
  stage: PropTypes.object.isRequired
};

export default inject('perfStore')(observer(ProgressCell));
