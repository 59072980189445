import styled from 'styled-components';
import { Progress } from 'semantic-ui-react';

const FixedProgress = styled(Progress)`
  &&& {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .bar {
    border-radius: 0 !important;
  }
`;

export default FixedProgress;
