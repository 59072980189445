import { getUserSettings } from '../api/users';

const SETTINGS_KEY = 'mastery-user-settings';

export const UserSettingCode = {
  ANALYTICS__UI_USAGE: 'ANALYTICS__UI_USAGE'
};

export async function getSettingStringValue(code) {
  let storedSettings = window.localStorage.getItem(SETTINGS_KEY);
  if (storedSettings) {
    storedSettings = JSON.parse(storedSettings);
  }

  if (!storedSettings || shouldUpdateSettings(storedSettings.updated)) {
    const { data } = await getUserSettings();
    storedSettings = setLocalStorageSettings(data);
  }
  const setting = storedSettings.settings.find(it => it.code === code);
  return setting ? setting.value : null;
}

/**
 * @param iso8601UpdatedDate
 * @returns {boolean} Is the given date more than a day in the past since the current moment?
 */
function shouldUpdateSettings(iso8601UpdatedDate) {
  return (
    new Date().getTime() - new Date(iso8601UpdatedDate).getTime() >
    1000 * 60 * 60 * 24
  );
}

export function setLocalStorageSettings(settings) {
  const storedSettings = {
    settings,
    updated: new Date()
  };
  window.localStorage.setItem(SETTINGS_KEY, JSON.stringify(storedSettings));
  return storedSettings;
}
