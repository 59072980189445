import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Message } from 'semantic-ui-react';
import 'react-quill/dist/quill.snow.css';

import QuillEditor from '../../../common/QuillEditor';
import { useTranslation } from 'react-i18next';

function TextMaterial(props) {
  const [t] = useTranslation();
  const { material } = props;

  function handleChange(value) {
    props.material.updateField('textContent', value);
  }

  return (
    <Form>
      <Form.Field>
        {material.error && <Message negative>{material.error.message}</Message>}
        <label>{t('lessonEdit.stages.materials.textMaterialLabel')}</label>
        <QuillEditor onChange={handleChange} value={material.textContent} />
      </Form.Field>
    </Form>
  );
}

TextMaterial.propTypes = {
  material: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

export default observer(TextMaterial);
