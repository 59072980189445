import React from 'react';
import styled from 'styled-components';

const placeholderClass = 'video-wrapper-placeholder';

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 ratio */
  height: 0;
  background-color: black;

  & iframe,
  ${placeholderClass} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Placeholder = styled.div`
  background-color: black;
  height: 100%;
`;

export function VideoWrapperPlaceholder({ children }) {
  return <Placeholder className={placeholderClass}>{children}</Placeholder>;
}

export default VideoWrapper;
