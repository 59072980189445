import React, { useEffect, useState } from 'react';
import {
  createLTIConfiguration,
  deleteLTIConfiguration,
  getLTIConfigurations
} from '../../api/lti-client';
import {
  Button,
  Card,
  Confirm,
  Header,
  Icon,
  Message
} from 'semantic-ui-react';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router';
import { Link } from 'react-router-dom';
import LTIConfigurationForm from './LTIConfigurationForm';
import { Helmet } from 'react-helmet/es/Helmet';

function LTIAdmin() {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const configurationDetailMatch = useRouteMatch(`${path}/:configurationId`);
  const [configurations, setConfigurations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteConfirmId, setDeleteConfirmId] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getLTIConfigurations()
      .then(response => {
        setConfigurations(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  async function createConfiguration() {
    setIsCreating(true);
    try {
      const response = await createLTIConfiguration();
      const createdConfiguration = response.data;
      setConfigurations([...configurations, createdConfiguration]);
      history.push(`${path}/${createdConfiguration.id}`);
    } finally {
      setIsCreating(false);
    }
  }

  async function deleteConfiguration(id) {
    setIsDeleting(true);
    try {
      await deleteLTIConfiguration(id);
      setConfigurations(configurations.filter(it => it.id !== id));
      setDeleteConfirmId(null);
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Mastery • LTI liidestus</title>
      </Helmet>
      <Header as="h2">LTI liidestus</Header>
      {!configurationDetailMatch && (
        <>
          <Message info icon>
            <Icon name="info circle" />
            <Message.Content>
              Enne liidestuse lisamist tutvu juhendiga:
              <Message.List>
                <Message.Item>
                  <Link to="/lti-guide/moodle" target="_blank">
                    Moodle
                  </Link>
                </Message.Item>
              </Message.List>
            </Message.Content>
          </Message>
          <Button
            primary
            onClick={createConfiguration}
            disabled={isCreating}
            loading={isCreating}
          >
            Lisa uus liidestus
          </Button>
          {configurations.map(configuration => (
            <Card key={configuration.id} fluid>
              <Card.Content>
                <Card.Header>
                  {configuration.description || 'Kirjelduseta liidestus'}
                </Card.Header>
                <Card.Meta>{configuration.tool.initiateLoginUrl}</Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <Button
                  icon="settings"
                  as={Link}
                  to={`${url}/${configuration.id}`}
                  content="Muuda"
                />
                <Button
                  negative
                  basic
                  content="Kustuta"
                  onClick={() => setDeleteConfirmId(configuration.id)}
                />
              </Card.Content>
            </Card>
          ))}
          <Confirm
            open={deleteConfirmId != null}
            header="LTI liidestuse kustutamine"
            content="Kas oled kindel, et soovid liidestuse kustutada?"
            confirmButton={
              <Button disabled={isDeleting} negative>
                Kustuta
              </Button>
            }
            cancelButton="Sulge"
            onConfirm={() => deleteConfiguration(deleteConfirmId)}
            onCancel={() => setDeleteConfirmId(null)}
          />
        </>
      )}

      {isLoading && (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>Laen liidestusi...</Message.Content>
        </Message>
      )}

      <Switch>
        <Route path={`${path}/:configurationId`}>
          <LTIConfigurationView configurations={configurations} />
        </Route>
      </Switch>
    </>
  );
}

function LTIConfigurationView({ configurations }) {
  const { configurationId } = useParams();
  const [configuration, setConfiguration] = useState(null);

  useEffect(
    () => {
      if (configurations && configurationId) {
        let _configuration = configurations.find(
          it => it.id === configurationId
        );
        if (_configuration) {
          setConfiguration(_configuration);
        }
      }
    },
    [configurations, configurationId]
  );

  return configuration ? (
    <LTIConfigurationForm configuration={configuration} />
  ) : null;
}

export default LTIAdmin;
