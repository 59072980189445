import React from 'react';
import { Segment } from 'semantic-ui-react';
import { Preview } from 'react-dnd-multi-backend';
import { useTranslation } from 'react-i18next';

function PreviewSegment() {
  const [t] = useTranslation();

  function generatePreview(type, item, style) {
    const newStyle = {
      ...style,
      width: item.width ? item.width : 100,
      height: item.height ? item.height : 50,
      opacity: 0.5
    };
    return (
      <Segment textAlign="center" style={newStyle}>
        {t('draggablePreviewMsg')}
      </Segment>
    );
  }

  return <Preview generator={generatePreview} />;
}

export default PreviewSegment;
