import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DropTarget } from 'react-dnd';
import { Input, List, Segment } from 'semantic-ui-react';

import AnswerGroupAnswer from './AnswerGroupAnswer';
import AddButton from '../../AddButton';
import { withTranslation } from 'react-i18next';
import DeleteButton from '../../DeleteButton';

const answerGroupTarget = {
  drop(props, monitor, component) {
    const answer = monitor.getItem().answer;
    if (props.group.answers.includes(answer)) return;
    answer.moveToGroup(props.group);
  }
};

class AnswerGroup extends Component {
  handleAdd = () => {
    this.props.group.addAnswer('');
  };

  render() {
    const { canDrop, isOver, connectDropTarget, group, t } = this.props;
    const isActive = canDrop && isOver;

    const style = {
      backgroundColor: isActive ? '#add0ae' : '',
      border: group.isFilled ? '' : '1px solid yellow'
    };

    return connectDropTarget(
      <div style={style}>
        <Segment.Group>
          <Segment>
            <Input
              fluid
              placeholder={t(
                'lessonEdit.questions.groupQuestion.groupNamePlaceholder'
              )}
              name="text"
              value={group.text}
              onChange={(e, { name, value }) => {
                group.updateField(name, value);
              }}
            >
              <input />
              <DeleteButton onClick={() => group.remove()} />
            </Input>
          </Segment>
          <Segment>
            <List>
              {group.answers.map((answer, idx) => (
                <List.Item key={idx}>
                  <AnswerGroupAnswer
                    answer={answer}
                    group={group}
                    onAnswerChanged={this.handleAnswerChanged}
                  />
                </List.Item>
              ))}
              <AddButton
                disabled={!group.isPersisted}
                disabledPopupMessage={t(
                  'lessonEdit.questions.groupQuestion.disabledPopupMsg'
                )}
                content={t('lessonEdit.questions.groupQuestion.addAnswerBtn')}
                onClick={this.handleAdd}
              />
            </List>
          </Segment>
        </Segment.Group>
      </div>
    );
  }
}

AnswerGroup.propTypes = {};

export default withTranslation()(
  DropTarget('answerGroup', answerGroupTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }))(observer(AnswerGroup))
);
