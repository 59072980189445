import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Button } from 'semantic-ui-react';
import { inject } from 'mobx-react';

class AddGroupButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  handleClick = () => {
    this.setState({
      redirect: true
    });
    this.props.groupStore.resetActiveGroup();
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/group" />;
    }
    return (
      <Button
        content="Lisa uus grupp"
        icon="plus"
        labelPosition="left"
        primary
        onClick={this.handleClick}
      />
    );
  }
}

export default inject('groupStore')(AddGroupButton);
