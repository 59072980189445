import { Accordion } from 'semantic-ui-react';
import styled from 'styled-components';

const FlexAccordionTitle = styled(Accordion.Title)`
  &&&&& {
    display: flex;
    align-items: center;

    & > .ui.button:last-child {
      margin-left: auto;
    }
    & > *:not(:last-child) {
      margin-right: 1em;
    }
  }
`;

export default FlexAccordionTitle;
