import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Icon, List, Message } from 'semantic-ui-react';
import './AssetSelector.css';
import { getUserAssets } from '../../api/assets';
import { fmtDateTime } from '../../utils/dateUtils';
import { getAssetTypeIconName } from '../../utils/lessonUtils';

function AssetList({ type, onSelect }) {
  const [t] = useTranslation();
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAssets = useCallback(
    () => {
      setIsLoading(true);
      getUserAssets(type).then(
        ({ data }) => {
          setAssets(data);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [type]
  );

  useEffect(() => fetchAssets(), [fetchAssets]);

  return (
    <>
      <Header as="h3">{t('assetList.header')}</Header>
      {assets && assets.length > 0 ? (
        <List selection>
          {assets.map(asset => (
            <List.Item
              key={asset.id}
              onClick={() => onSelect(asset.url, asset.name)}
            >
              <List.Icon name={getAssetTypeIconName(type)} />
              <List.Content>
                <List.Header>{asset.name}</List.Header>
                <List.Description>
                  {fmtDateTime(asset.created)}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      ) : isLoading ? (
        <Message icon>
          <Icon name="circle notched" loading />
          {t('assetList.loading')}
        </Message>
      ) : (
        <Message>{t('assetList.noData')}</Message>
      )}
    </>
  );
}

export default AssetList;
