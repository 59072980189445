import { Icon } from 'semantic-ui-react';
import React from 'react';

function GripIcon() {
  return (
    <Icon.Group>
      <Icon fitted name="ellipsis vertical" style={{ paddingRight: '10px' }} />
      <Icon name="ellipsis vertical" />
    </Icon.Group>
  );
}

export default GripIcon;
