import React, { useState } from 'react';
import { Button, Container, Header, Message, Select } from 'semantic-ui-react';
import { EmailSimulation, sendTestMail } from '../../api/admin';

const simulationTypes = [
  { key: 'success', value: EmailSimulation.SUCCESS, text: 'SUCCESS' },
  { key: 'bounce', value: EmailSimulation.BOUNCE, text: 'BOUNCE' },
  { key: 'complaint', value: EmailSimulation.COMPLAINT, text: 'COMPLAINT' },
  { key: 'current', value: EmailSimulation.CURRENT_USER, text: 'CURRENT_USER' }
];

function AdminDashboard() {
  const [emailSendStatus, setEmailSendStatus] = useState('WAIT');
  const [simulation, setSimulation] = useState(EmailSimulation.SUCCESS);

  function onTestMailSend() {
    setEmailSendStatus('LOADING');
    sendTestMail(simulation).then(
      () => setEmailSendStatus('SENT'),
      () => setEmailSendStatus('ERROR')
    );
  }

  function handleSimulationChange(e, { value }) {
    setSimulation(value);
  }

  return (
    <Container>
      <Header>Admin</Header>
      <Select
        options={simulationTypes}
        onChange={handleSimulationChange}
        value={simulation}
      />
      <Button
        attached="right"
        loading={emailSendStatus === 'LOADING'}
        disabled={emailSendStatus === 'LOADING'}
        onClick={() => onTestMailSend()}
      >
        Send test mail
      </Button>
      {emailSendStatus === 'ERROR' && (
        <Message negative>Failed to send test email</Message>
      )}
      {emailSendStatus === 'SENT' && (
        <Message positive>Test email sent</Message>
      )}
    </Container>
  );
}

export default AdminDashboard;
