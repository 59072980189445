import { action, decorate, observable } from 'mobx';
import EditableLesson from './model/lesson';
import { fetchLesson } from '../../api/lessons';

class LessonEditStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  lesson = new EditableLesson(this);
  activeStageOrderNr = 0;

  async loadLesson(id) {
    this.lesson.setLoading(true);
    const response = await fetchLesson(id);
    this.updateFromServer(response.data);
    this.lesson.setLoading(false);
  }

  updateFromServer(json) {
    if (!this.lesson || this.lesson.id !== json.id) {
      let lesson = new EditableLesson(this, json.id);
      lesson.updateFromJson(json);
      this.lesson = lesson;
    }
  }

  resetLesson() {
    this.lesson = new EditableLesson(this);
  }

  setActiveStageOrderNr(orderNr) {
    // TODO Validate if lesson has the specified order nr stage?
    this.activeStageOrderNr = orderNr;
  }
}

export default decorate(LessonEditStore, {
  lesson: observable,
  activeStageOrderNr: observable,
  updateFromServer: action,
  resetLesson: action
});
