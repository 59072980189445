import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PdfViewer from '../../../common/PdfViewer';
import { Button, Segment } from 'semantic-ui-react';
import AssetSelector from '../../../common/AssetSelector';

function PdfMaterial({ material }) {
  const [t] = useTranslation();
  const [isAssetSelectorOpen, setIsAssetSelectorOpen] = useState(false);

  function handleAssetSelect(url) {
    if (url) {
      material.updateField('pdfUrl', url);
    }
    setIsAssetSelectorOpen(false);
  }

  return (
    <>
      <Button fluid onClick={() => setIsAssetSelectorOpen(true)}>
        {t('lessonEdit.stages.materials.pdf.openPdfSelect')}
      </Button>
      <AssetSelector
        type="PDF"
        onClose={handleAssetSelect}
        isOpen={isAssetSelectorOpen}
      />
      {material.pdfUrl && (
        <Segment>
          <PdfViewer file={material.pdfUrl} />
        </Segment>
      )}
    </>
  );
}

export default observer(PdfMaterial);
