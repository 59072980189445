import { action, computed, decorate, observable, runInAction } from 'mobx';
import debounce from 'lodash/debounce';
import remove from 'lodash/remove';
import uniqueId from 'lodash/uniqueId';
import {
  deleteAnswerGroup,
  deleteAnswerGroupAnswer,
  saveAnswerGroup,
  saveAnswerGroupAnswer
} from '../../../api';

class AnswerGroupAnswer {
  id = null;
  answerGroup = null;
  text = '';

  store = null;

  constructor(store, id, answerGroup, json) {
    this.id = id;
    this.store = store;
    this.answerGroup = answerGroup;
    this.updateFromJson(json);
  }

  persistChanges = debounce(async () => {
    const { data } = await saveAnswerGroupAnswer(this.asJson);
    if (typeof this.id === 'string') {
      this.id = data.id;
    }
  }, 500);

  updateFromJson(json) {
    this.text = json.text;
  }

  get asJson() {
    return {
      id: typeof this.id === 'string' ? null : this.id,
      text: this.text,
      answerGroupId: this.answerGroup.id
    };
  }

  updateField(name, value, persistChanges = true) {
    this[name] = value;
    if (persistChanges) this.persistChanges();
  }

  moveToGroup(group) {
    remove(this.answerGroup.answers, a => a.id === this.id);
    group.answers.push(this);
    this.answerGroup = group;
    this.persistChanges();
  }

  async remove() {
    if (typeof this.id !== 'string') {
      await deleteAnswerGroupAnswer(this.id);
    }
    runInAction(() => remove(this.answerGroup.answers, a => a.id === this.id));
  }
}

decorate(AnswerGroupAnswer, {
  answerGroup: observable,
  text: observable,

  asJson: computed,

  updateField: action,
  moveToGroup: action
});

class AnswerGroup {
  id = null;
  question = null;
  text = '';
  answers = [];

  store = null;

  constructor(store, id, question, json) {
    this.id = id;
    this.store = store;
    this.question = question;
    this.updateFromJson(json);
  }

  persistChanges = debounce(async () => {
    const { data } = await saveAnswerGroup(this.asJson);
    if (typeof this.id === 'string') {
      this.id = data.id;
    }
  }, 500);

  updateFromJson(json) {
    this.text = json.text;
    this.answers = json.answers
      ? json.answers.map(
          answer => new AnswerGroupAnswer(this.store, answer.id, this, answer)
        )
      : [];
  }

  get asJson() {
    return {
      id: this.isPersisted ? this.id : null,
      text: this.text,
      questionId: this.question.id
    };
  }

  get isFilled() {
    return this.answers.length > 0;
  }

  get isPersisted() {
    return typeof this.id === 'number';
  }

  updateField(name, value, persistChanges = true) {
    this[name] = value;
    if (persistChanges) this.persistChanges();
  }

  async remove() {
    if (typeof this.id !== 'string') {
      await deleteAnswerGroup(this.id);
    }
    runInAction(() =>
      remove(this.question.answerGroups, a => a.id === this.id)
    );
  }

  addAnswer(text) {
    let answer = new AnswerGroupAnswer(this.store, uniqueId('aga'), this, {
      text
    });
    runInAction(() => this.answers.push(answer));
  }
}

export default decorate(AnswerGroup, {
  id: observable,
  question: observable,
  text: observable,
  answers: observable,

  asJson: computed,
  isFilled: computed,
  isPersisted: computed,

  updateField: action
});
