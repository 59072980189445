import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Form, Icon, Segment } from 'semantic-ui-react';
import YouTube from 'react-youtube';
import debounce from 'lodash/debounce';
import Range from 'rc-slider/lib/Range';
import Handle from 'rc-slider/lib/Handle';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import styled from 'styled-components';
import VideoWrapper from '../../../common/VideoWrapper';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { withTranslation } from 'react-i18next';

const VideoRangeContainer = styled.div`
  display: flex;
`;
const StyledRange = styled(Range)`
  margin-right: 10px;
  margin-left: 10px;
`;
const VideoRangeButton = styled(Button)``;

class VideoMaterial extends Component {
  state = {
    videoEditOpts: {
      playerVars: {
        autoplay: 0,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        start: this.props.material.videoStart,
        end: this.props.material.videoEnd
      }
    },
    youtubeLink: '',
    selectedYoutubeId: '',
    isPlayerReady: false,
    videoStart: this.props.material.videoStart
      ? this.props.material.videoStart
      : 0,
    videoEnd: this.props.material.videoEnd
      ? this.props.material.videoEnd
      : Number.MAX_VALUE
  };

  handleSelectVideo = e => {
    e.preventDefault();
    let youtubeId = this.state.youtubeLink.includes('http')
      ? this.extractYoutubeId(this.state.youtubeLink)
      : this.state.youtubeLink;
    if (youtubeId !== this.props.material.youtubeId) {
      this.props.material.updateField('youtubeId', youtubeId);
      this.props.material.updateField('videoStart', 0);
      this.props.material.updateField('videoEnd', 1);
    }
  };

  extractYoutubeId = youtubeUrl => {
    const shortLinkDomain = 'youtu.be/';
    const idParamStart = youtubeUrl.includes(shortLinkDomain)
      ? youtubeUrl.indexOf(shortLinkDomain) + shortLinkDomain.length
      : youtubeUrl.indexOf('v=') + 2;
    const nextParamStart = youtubeUrl.indexOf('&', idParamStart);
    const idParamEnd = nextParamStart > 0 ? nextParamStart : youtubeUrl.length;
    return youtubeUrl.substring(idParamStart, idParamEnd);
  };

  onReady = e => {
    const youtubePlayer = e.target;
    this.setState({
      youtubePlayer
    });
    if (this.props.material.videoEnd === 1) {
      // Special case for first load of video: set video end to video duration
      this.props.material.updateField('videoEnd', youtubePlayer.getDuration());
    }
  };

  handleIncrement = e =>
    this.handleAdjustTiming(
      e,
      1,
      this.state.activeHandleIndex === 0 ? 'videoStart' : 'videoEnd'
    );

  handleDecrement = e =>
    this.handleAdjustTiming(
      e,
      -1,
      this.state.activeHandleIndex === 0 ? 'videoStart' : 'videoEnd'
    );

  handleAdjustTiming = (e, amount, property) => {
    e.preventDefault();
    const unrounded = this.props.material[property] + amount;
    const newTiming = Math.round(unrounded * 100) / 100;

    if (newTiming < 0 || newTiming > this.state.youtubePlayer.getDuration())
      return;

    // TODO make more intuitive
    this.setState({ [property]: newTiming });
    this.props.material.updateField(property, newTiming);
    this.state.youtubePlayer.seekTo(newTiming);
    this.state.youtubePlayer.pauseVideo();
  };

  handleStateChange = (e, { name, value }) => this.setState({ [name]: value });

  handleRangeChange = ([videoStart, videoEnd]) => {
    let seekTime;
    if (videoStart !== this.state.videoStart) {
      this.setState({
        videoStart: videoStart
      });
      seekTime = videoStart;
    }
    if (videoEnd !== this.state.videoEnd) {
      this.setState({
        videoEnd: videoEnd
      });
      seekTime = videoEnd;
    }
    if (videoStart === videoEnd) {
      //if videStart and end are the same then the video is 5 sec long.
      this.setState({
        videoEnd: videoStart + 5,
        videoStart: videoStart
      });
    }
    this.updateVideoTime(seekTime);
  };

  updateVideoTime = debounce(seekTime => {
    this.props.material.updateField('videoStart', this.state.videoStart);
    this.props.material.updateField('videoEnd', this.state.videoEnd);
    if (seekTime != null) {
      this.state.youtubePlayer.seekTo(seekTime);
      this.state.youtubePlayer.pauseVideo();
    }
  }, 300);

  renderHandle = props => {
    let { value, dragging, index, style, ...restProps } = props;
    if (index === this.state.activeHandleIndex) {
      style = {
        backgroundColor: '#96dbfa'
      };
    }
    return (
      <Tooltip
        key={index}
        placement="bottom"
        overlay={moment.utc(value * 1000).format('mm:ss')}
      >
        <Handle
          value={value}
          dragging={dragging ? 'true' : 'false'}
          onClick={() => this.onHandleClick(index)}
          style={style}
          {...restProps}
        />
      </Tooltip>
    );
  };

  onHandleClick = index => {
    this.setState({ activeHandleIndex: index });
  };

  continueFromLastVideo = () => {
    let previousVideo = this.props.material.previousVideo;
    this.props.material.updateField('youtubeId', previousVideo.youtubeId);
    this.props.material.updateField('videoStart', previousVideo.videoEnd);
    this.setState({
      youtubeLink: previousVideo.youtubeId,
      videoStart: previousVideo.videoEnd,
      videoEnd: Number.MAX_VALUE
    });
  };

  componentDidMount() {
    if (this.props.material.youtubeId) {
      this.setState({
        youtubeLink: this.props.material.youtubeId
          ? `https://www.youtube.com/watch?v=${this.props.material.youtubeId}`
          : ''
      });
    }
  }

  render() {
    const { material, t } = this.props;
    const {
      videoStart,
      videoEnd,
      videoEditOpts,
      youtubeLink,
      youtubePlayer,
      isPlayerReady
    } = this.state;
    return (
      <Form>
        <Form.Input
          required
          placeholder={t('lessonEdit.stages.materials.videoPlaceholder')}
          label={
            <span>
              <Icon name="youtube" />
              {t('lessonEdit.stages.materials.videoLabel')}
            </span>
          }
          name="youtubeLink"
          action={{
            content: t('lessonEdit.stages.materials.videoSelectBtn'),
            onClick: this.handleSelectVideo
          }}
          value={youtubeLink}
          onChange={this.handleStateChange}
        />
        {material.previousVideo && (
          <Form.Group>
            <Form.Button
              content={t(
                'lessonEdit.stages.materials.videoContinueFromPreviousBtn'
              )}
              onClick={this.continueFromLastVideo}
            />
          </Form.Group>
        )}
        {material.youtubeId && (
          <Segment inverted textAlign="center">
            <VideoWrapper>
              <YouTube
                className={isPlayerReady ? 'hidden-player' : ''}
                videoId={material.youtubeId}
                onReady={this.onReady}
                opts={videoEditOpts}
              />
            </VideoWrapper>
          </Segment>
        )}
        {youtubePlayer && (
          <VideoRangeContainer>
            <VideoRangeButton
              circular
              icon="angle double left"
              size="medium"
              onClick={this.handleDecrement}
            />
            <StyledRange
              defaultValue={[material.videoStart, material.videoEnd]}
              value={[videoStart, videoEnd]}
              max={youtubePlayer.getDuration()}
              // onBeforeChange={this.handleBeforeRangeChange}
              onChange={this.handleRangeChange}
              handle={this.renderHandle}
              allowCross={false}
            />
            <VideoRangeButton
              circular
              icon="angle double right"
              size="medium"
              onClick={this.handleIncrement}
            />
          </VideoRangeContainer>
        )}
      </Form>
    );
  }
}

VideoMaterial.propTypes = {
  material: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

export default withTranslation()(observer(VideoMaterial));
