import React, { useEffect, useState } from 'react';
import TaskChooser from '../common/TaskChooser';
import { Button, Card, Header, Icon, Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { fetchTeacherDashboard } from '../../api/dashboard';
import sortBy from 'lodash/sortBy';
import { postMessageToParent } from '../../utils/iframeUtils';

function LTIDeeplink({ auth }) {
  const { isAuthenticated } = auth;
  const [recentTasks, setRecentTasks] = useState([]);
  const [isLoadingTasks, setLoadingTasks] = useState(true);

  useEffect(
    () => {
      if (isAuthenticated) {
        setLoadingTasks(true);
        fetchTeacherDashboard().then(
          response => {
            setLoadingTasks(false);
            setRecentTasks(
              sortBy(response.data.tasks, 'created')
                .reverse()
                .slice(0, 5)
            );
          },
          () => {
            setLoadingTasks(false);
          }
        );
      }
    },
    [isAuthenticated]
  );

  function handleTaskChoose(taskCode) {
    postMessageToParent({ taskCode });
  }

  // TODO i18n
  return (
    <div>
      <Header>Vali ülesanne</Header>
      <TaskChooser
        onChoose={handleTaskChoose}
        messageText="Ülesande valimiseks sisesta kood"
      />
      {isAuthenticated && (
        <>
          <Header>Hiljutised ülesanded</Header>
          {recentTasks && recentTasks.length > 0 ? (
            recentTasks.map(task => (
              <Card key={task.code} fluid>
                <Card.Content>
                  <Card.Header>{task.taskName}</Card.Header>
                  <Card.Meta>{task.code}</Card.Meta>
                </Card.Content>
                <Card.Content extra>
                  <Button
                    color="green"
                    floated="right"
                    onClick={() => handleTaskChoose(task.code)}
                  >
                    Vali
                  </Button>
                </Card.Content>
              </Card>
            ))
          ) : isLoadingTasks ? (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>Laen ülesandeid...</Message.Content>
            </Message>
          ) : (
            <Message>Ei leidnud ülesandeid</Message>
          )}
        </>
      )}
    </div>
  );
}

export default inject('auth')(observer(LTIDeeplink));
