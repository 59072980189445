import API from './API';

const BASE_PATH = '/api/tasks';

export const fetchTask = taskId => {
  return API.get(`${BASE_PATH}/${taskId}`);
};

export const fetchOpenAnswers = taskId => {
  return API.get(`${BASE_PATH}/${taskId}/open-answers`);
};

export const fetchTasks = () => {
  return API.get(BASE_PATH);
};

export const invite = (taskId, invitedUsers, userGroupIds) => {
  return API.post(`${BASE_PATH}/${taskId}/invite`, {
    invitedUsers,
    userGroupIds
  });
};

export const unInviteGroup = (taskId, groupId) => {
  return API.post(`${BASE_PATH}/${taskId}/uninviteGroup/${groupId}`);
};

export const unInviteUser = (taskId, userId) => {
  return API.post(`${BASE_PATH}/${taskId}/uninviteUser/${userId}`);
};

/**
 *
 * @param taskId
 * @param dto - task details in the shape of:
 * {
 *   taskName,
 *   startTime,
 *   endTime,
 *   isAuthenticationRequired,
 *   allowLatePerformance,
 *   autoEnrollmentGroup
 * }
 * @returns {Promise}
 */
export const saveTask = (taskId, dto) => {
  return API.post(`${BASE_PATH}/${taskId}`, dto);
};

export const deleteTask = taskId => {
  return API.delete(`${BASE_PATH}/${taskId}`);
};

export const fetchPerformance = (taskId, performanceId) => {
  return API.get(`${BASE_PATH}/${taskId}/performances/${performanceId}`);
};
