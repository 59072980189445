import React from 'react';
import { Form, Header } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import TestQuestionPicker from './TestQuestionPicker';
import { useTranslation } from 'react-i18next';

function LessonAdditions(props) {
  const [t] = useTranslation();
  const { lesson } = props.lessonEditStore;

  return (
    <>
      <Form>
        <Header>
          {t('lessonEdit.additions.pretest.header')}
          <Header.Subheader>
            {t('lessonEdit.additions.pretest.subheader')}
          </Header.Subheader>
        </Header>
        <Form.Checkbox
          label={t('lessonEdit.additions.pretest.toggle')}
          checked={lesson.isPretestEnabled}
          onChange={(e, { checked }) =>
            lesson.updateField('isPretestEnabled', checked)
          }
          toggle
        />
        {lesson.isPretestEnabled && (
          <TestQuestionPicker
            checkedQuestionIds={lesson.pretestQuestionIds}
            onQuestionToggle={questionId =>
              lesson.togglePretestQuestion(questionId)
            }
          />
        )}
        <Header>
          {t('lessonEdit.additions.posttest.header')}
          <Header.Subheader>
            {t('lessonEdit.additions.posttest.subheader')}
          </Header.Subheader>
        </Header>
        <Form.Checkbox
          label={t('lessonEdit.additions.posttest.toggle')}
          checked={lesson.isPosttestEnabled}
          onChange={(e, { checked }) =>
            lesson.updateField('isPosttestEnabled', checked)
          }
          toggle
        />
        {lesson.isPosttestEnabled && (
          <TestQuestionPicker
            checkedQuestionIds={lesson.posttestQuestionIds}
            onQuestionToggle={questionId =>
              lesson.togglePosttestQuestion(questionId)
            }
          />
        )}
      </Form>
    </>
  );
}

export default inject('lessonEditStore')(observer(LessonAdditions));
