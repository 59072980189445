import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import './PerformanceText.css';
import CustomQuill from '../../common/CustomQuill';

class PerformanceText extends Component {
  state = {};
  render() {
    return (
      <Container>
        <CustomQuill
          value={this.props.material.textContent}
          modules={{ toolbar: false }}
          readOnly
        />
      </Container>
    );
  }
}

PerformanceText.propTypes = {
  material: PropTypes.object.isRequired
};

export default observer(PerformanceText);
