import React from 'react';
import { inject, observer } from 'mobx-react';
import { Checkbox, Label, Segment } from 'semantic-ui-react';

import './PerfChooseAnswer.css';
import { useTranslation } from 'react-i18next';

function PerfChooseAnswer({ answerOption, perfStore }) {
  const [t] = useTranslation();
  const { isReviewPhase, toggleAnswerOption } = perfStore;

  function shouldShowCorrectness() {
    const { correctAnswer, isReviewPhase, lesson } = perfStore;
    if (!isReviewPhase) {
      return false;
    }
    if (!correctAnswer) {
      // Absence of correctAnswer means that the selected answer(s) were correct
      return answerOption.selected;
    }
    const isCorrect = correctAnswer.answerOptions.some(it => {
      return it.id === answerOption.id && it.correct;
    });
    if (!isCorrect) {
      return false;
    }
    return !(lesson.strictness === 'STRICT' && !answerOption.selected);
  }

  function renderCorrectnessLabel() {
    const labelContent = answerOption.selected
      ? t('performance.question.choice.correctLabel')
      : t('performance.question.choice.failedCorrectLabel');
    return (
      <Label
        attached="top right"
        color="green"
        icon="arrow left"
        content={labelContent}
      />
    );
  }

  return (
    <Segment
      fluid
      basic
      className={shouldShowCorrectness() ? 'option-segment--correct' : ''}
    >
      <Checkbox
        disabled={isReviewPhase}
        onChange={() => toggleAnswerOption(answerOption)}
        checked={answerOption.selected}
        label={answerOption.text}
      />
      {shouldShowCorrectness() && renderCorrectnessLabel(answerOption)}
    </Segment>
  );
}

export default inject('perfStore')(observer(PerfChooseAnswer));
