import styled from 'styled-components';

const StagesBarCellContainer = styled.div`
  z-index: 1;

  .ui.button.label {
    opacity: 1 !important;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
      color 0.3s ease-in-out;
  }

  .ui.button.label:hover {
    box-shadow: 0 0 0 2px lightgrey !important;
  }
`;

export default StagesBarCellContainer;
