import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container, Image, Segment } from 'semantic-ui-react';

class PerformanceImage extends Component {
  render() {
    return (
      <Container>
        <Segment inverted textAlign="center">
          <Image
            centered
            src={this.props.material.imageUrl}
            alt={this.props.material.name}
          />
        </Segment>
      </Container>
    );
  }
}

export default observer(PerformanceImage);
