import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { DropTarget } from 'react-dnd';
import { Accordion, Icon, Label, Segment } from 'semantic-ui-react';
import PerfGroupAnswer from './PerfGroupAnswer';

const target = {
  hover(props, monitor) {
    const answer = monitor.getItem().answer;
    if (props.group.id === answer.groupId) return;
    props.moveAnswer(answer, props.group);
  }
};

function PerfGroup(props) {
  const {
    canDrop,
    isOver,
    connectDropTarget,
    group,
    answers,
    question
  } = props;
  const isActive = canDrop && isOver;
  const { isReviewPhase } = props.perfStore;
  const [isExpanded, setExpanded] = useState(true);

  return connectDropTarget(
    <div>
      <Accordion>
        <Accordion.Title
          active={isExpanded}
          onClick={() => setExpanded(!isExpanded)}
        >
          <Icon name="dropdown" />
          {group.text}{' '}
          {answers &&
            answers.length > 0 && <Label circular>{answers.length}</Label>}
          {isActive && <Icon name="arrow left" />}
        </Accordion.Title>
        <Accordion.Content active={isExpanded}>
          <Segment placeholder style={{ minHeight: 0 }}>
            {answers &&
              answers.map(answer => (
                <PerfGroupAnswer
                  key={answer.id}
                  answer={answer}
                  isReviewPhase={isReviewPhase}
                  question={question}
                />
              ))}
          </Segment>
        </Accordion.Content>
      </Accordion>
    </div>
  );
}

export default DropTarget('perfGroup', target, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
}))(inject('perfStore')(observer(PerfGroup)));
