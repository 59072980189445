import React, { Component } from 'react';
import AddGroupButton from './AddGroupButton.js';
import { inject, observer } from 'mobx-react';
import GroupsListItem from './GroupsListItem';
import { Header, List } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

class Groups extends Component {
  componentDidMount() {
    this.props.groupStore.getGroups();
  }

  render() {
    const groups = this.props.groupStore.groups;
    return (
      <>
        <Helmet>
          <title>Mastery • Grupid</title>
        </Helmet>
        <Header>Grupid</Header>
        <AddGroupButton />
        <List selection relaxed>
          {groups.map(group => <GroupsListItem group={group} key={group.id} />)}
        </List>
      </>
    );
  }
}

export default inject('auth', 'groupStore')(observer(Groups));
