import debounce from 'lodash/debounce';

const IFRAME_SRC_REGEX = /<iframe.+?src="(?<iframeSrc>.+?)".+?\/?>/;
const embedTypeRegex = {
  ERR_JUPITER: /https:\/\/services\.err\.ee\/media\/embed\/\d+/
};

const extractIframeSrc = debounce((value, cb) => {
  const match = value.match(IFRAME_SRC_REGEX);
  if (!match || !match.groups) {
    cb(null);
    return;
  }
  const iframeSrc = match.groups.iframeSrc;
  if (!iframeSrc) {
    cb(null);
    return;
  }
  const srcWithProtocol = !iframeSrc.startsWith('http')
    ? 'https:' + iframeSrc
    : iframeSrc;
  try {
    cb(new URL(srcWithProtocol).href);
  } catch (e) {
    cb(null);
  }
}, 500);

const determineEmbedType = url => {
  if (!url) {
    return null;
  }
  let embedType = null;
  Object.keys(embedTypeRegex).forEach(key => {
    if (url.match(embedTypeRegex[key])) {
      embedType = key;
    }
  });
  return embedType;
};

export { extractIframeSrc, determineEmbedType };
