import React, { Component } from 'react';
import { Button, Dropdown, Form, List } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import API from '../../../../api/API';
import debounce from 'lodash/debounce';
import UserGroupSelect from './UserGroupSelect';
import { withTranslation } from 'react-i18next';

class InvitationForm extends Component {
  state = {
    selectedGroups: [],
    users: [],
    prevSearchQuery: '',
    selectedUsers: []
  };

  handleInvitedGroupsChange = selectedGroups => {
    this.setState({ selectedGroups });
    typeof this.props.onChange === 'function' &&
      this.props.onChange({
        selectedGroups,
        selectedUsers: this.state.selectedUsers
      });
  };

  handleSearchChange = debounce(async (e, { searchQuery }) => {
    if (searchQuery.length < 3) return;
    if (
      searchQuery.length > this.state.prevSearchQuery.length &&
      this.state.prevSearchQuery.length > 0 &&
      searchQuery.startsWith(this.state.prevSearchQuery)
    ) {
      // No need to fetch from server if filter has been constrained, for example:
      // krist -> kristjan
      return;
    }
    const { data } = await API.get(`/api/users?search=${searchQuery}`);
    this.setState({
      users: this.filterSelected(data),
      prevSearchQuery: searchQuery
    });
  }, 500);

  filterSelected = users =>
    users.filter(
      user => !this.state.selectedUsers.find(u => u.userId === user.userId)
    );

  handleRemove = id => {
    let selectedUsers = this.state.selectedUsers.filter(
      user => user.userId !== id
    );
    this.setState({ selectedUsers });
    typeof this.props.onChange === 'function' &&
      this.props.onChange({
        selectedGroups: this.state.selectedGroups,
        selectedUsers
      });
  };

  handleSelect = (e, { value }) => {
    const selectedUser = this.state.users.find(user => user.userId === value);
    const selectedUsers = this.state.selectedUsers.concat(selectedUser);
    this.setState({
      selectedUsers,
      users: [],
      prevSearchQuery: ''
    });
    typeof this.props.onChange === 'function' &&
      this.props.onChange({
        selectedGroups: this.state.selectedGroups,
        selectedUsers: selectedUsers
      });
  };

  componentDidMount() {
    this.props.groupStore.getGroups();
  }

  render() {
    const { t } = this.props;
    const { users, selectedUsers } = this.state;
    return (
      <Form>
        <UserGroupSelect multiple onChange={this.handleInvitedGroupsChange} />
        <Form.Field>
          <label>{t('taskOverview.invitationModal.sendSingleInvites')}</label>
          <Dropdown
            placeholder={t('taskOverview.invitationModal.enterEmail')}
            search
            selection
            options={users.map(user => ({
              text: user.email,
              value: user.userId
            }))}
            onSearchChange={this.handleSearchChange}
            onChange={this.handleSelect}
          />
        </Form.Field>
        <List relaxed>
          {selectedUsers.map(user => (
            <List.Item key={user.userId}>
              <List.Content floated="right">
                <Button negative onClick={() => this.handleRemove(user.userId)}>
                  {t('taskOverview.invitationModal.removeInvitee')}
                </Button>
              </List.Content>
              <List.Content>
                <List.Header>{user.email}</List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Form>
    );
  }
}

export default withTranslation()(
  inject('groupStore')(observer(InvitationForm))
);
