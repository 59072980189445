import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Grid,
  Header,
  Icon,
  Label,
  Message,
  Segment
} from 'semantic-ui-react';
import { fetchTeacherDashboard } from '../api/dashboard';
import './TeacherDashboard.css';
import { Link } from 'react-router-dom';
import { fmtDateTime } from '../utils/dateUtils';
import sortBy from 'lodash/sortBy';
import { getTaskLink } from '../utils/taskUtils';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import CopyToClipboardIcon from './common/CopyToClipboardIcon';
import { useHistory } from 'react-router';
import styled from 'styled-components';

function TeacherDashboard({ appState, lessonOverviewStore }) {
  const [t] = useTranslation();
  const history = useHistory();
  const [isMounted, setIsMounted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [isCreatingLesson, setIsCreatingLesson] = useState(false);

  useEffect(
    () => {
      setIsLoading(true);
      fetchTeacherDashboard()
        .then(response => {
          if (isMounted) {
            setTasks(
              sortBy(response.data.tasks, 'created')
                .reverse()
                .slice(0, 5)
            );
            setLessons(response.data.lessons);
          }
          return () => setIsMounted(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isMounted]
  );

  function createLesson() {
    setIsCreatingLesson(true);
    lessonOverviewStore
      .createLesson('Uus tund')
      .then(({ data }) => {
        const lessonPath = `/lessons/${data.id}/edit`;
        history.push(lessonPath);
        appState.addMessage({
          success: true,
          text: t('header.lessonCreatedMsg')
        });
      })
      .catch(() => {
        setIsCreatingLesson(false);
      });
  }

  return (
    <Segment>
      <Message
        header={t('home.welcome.header')}
        content={
          <p>
            {t('home.welcome.howToCreate')}
            <a
              href="https://sites.google.com/view/masteryops"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;{t('home.welcome.manualLink')}.
            </a>
          </p>
        }
      />

      <Grid columns="2" stackable>
        <Grid.Column>
          <TasksSection tasks={tasks} isLoading={isLoading} />
        </Grid.Column>
        <Grid.Column>
          <LessonsSection
            lessons={lessons}
            isLoading={isLoading}
            onLessonCreate={createLesson}
            isCreatingLesson={isCreatingLesson}
          />
        </Grid.Column>
      </Grid>
    </Segment>
  );
}

function LessonsSection({
  lessons,
  isLoading,
  onLessonCreate,
  isCreatingLesson
}) {
  const [t] = useTranslation();
  return (
    <Segment basic>
      <Header>{t('teacherDashboard.lessons.header')}</Header>
      {isLoading ? null : lessons.length === 0 ? (
        <Message>{t('teacherDashboard.lessons.noData')}</Message>
      ) : (
        <>
          <Button
            primary
            onClick={onLessonCreate}
            disabled={isCreatingLesson}
            loading={isCreatingLesson}
            style={{
              marginBottom: '1em',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            {t('header.createLesson')}
          </Button>
          <Card.Group>
            {lessons.map(lesson => (
              <Card fluid key={lesson.id}>
                <Card.Content>
                  <Card.Header>
                    <Link to={`/lessons/${lesson.id}`}>{lesson.name}</Link>
                  </Card.Header>
                  <Card.Meta>{fmtDateTime(lesson.updated)}</Card.Meta>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </>
      )}
    </Segment>
  );
}

const TaskLinkSpan = styled.span`
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function TasksSection({ tasks, isLoading }) {
  const [t] = useTranslation();

  function onCopy(id) {
    const element = document.querySelector(`#${id}`);
    if (element && typeof element.focus === 'function') {
      element.focus();
    }
  }

  return (
    <Segment basic>
      <Header>{t('teacherDashboard.tasks.header')}</Header>
      {isLoading ? null : tasks.length === 0 ? (
        <Message>{t('teacherDashboard.tasks.noData')}</Message>
      ) : (
        <Card.Group>
          {tasks.map(task => (
            <Card fluid key={task.id}>
              <Card.Content>
                <Card.Header>
                  <Link to={`/tasks/${task.id}`}>{task.lessonName}</Link>
                </Card.Header>
                <Card.Meta>{fmtDateTime(task.created)}</Card.Meta>
                <Card.Description>
                  {task.taskName !== task.lessonName && <p>{task.taskName}</p>}
                  <TaskLinkSpan>
                    <CopyToClipboardIcon
                      textToCopy={getTaskLink(task.code)}
                      onCopy={() => onCopy(`tasklink-${task.code}`)}
                    />
                    <a
                      id={`tasklink-${task.code}`}
                      href={getTaskLink(task.code)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {getTaskLink(task.code)}
                    </a>
                  </TaskLinkSpan>
                </Card.Description>
              </Card.Content>

              <Card.Content extra>
                <Label basic color="yellow">
                  <Icon name="edit" />
                  {task.performancesByStatus['IN_PROGRESS']
                    ? task.performancesByStatus['IN_PROGRESS']
                    : 0}
                </Label>
                <Label basic color="green" style={{ marginRight: '1em' }}>
                  <Icon name="check" />
                  {task.performancesByStatus['FINISHED']
                    ? task.performancesByStatus['FINISHED']
                    : 0}
                </Label>

                <Label style={{ marginLeft: 'auto' }}>
                  <CopyToClipboardIcon textToCopy={task.code} />
                  {task.code}
                </Label>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      )}
    </Segment>
  );
}

export default inject('appState', 'lessonOverviewStore')(TeacherDashboard);
