import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import UserGroupSelect from './UserGroupSelect';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { saveTask } from '../../../../api/tasks';
import InfoIcon from '../../../common/InfoIcon';
import { fmtDateTime } from '../../../../utils/dateUtils';

function TaskForm({ task, onSuccess, onChange, readOnly }) {
  const { t } = useTranslation();
  const [submitStatus, setSubmitStatus] = useState('PENDING');
  const [startTimeConstraints, setStartTimeConstraints] = useState({});
  const [endTimeConstraints, setEndTimeConstraints] = useState({});
  const [isStartTimeValid, setIsStartTimeValid] = useState(true);
  const [isEndTimeValid, setIsEndTimeValid] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(!!readOnly);

  const [taskName, setTaskName] = useState(
    task && task.taskName ? task.taskName : ''
  );
  const [startTime, setStartTime] = useState(
    task && task.startTime ? task.startTime : new Date()
  );
  const [endTime, setEndTime] = useState(
    task && task.endTime ? task.endTime : null
  );
  const [isAuthenticationRequired, setIsAuthenticationRequired] = useState(
    task ? !!task.isAuthenticationRequired : false
  );
  const [allowLatePerformance, setAllowLatePerformance] = useState(
    task ? !!task.allowLatePerformance : true
  );
  const [autoEnrollmentGroup, setAutoEnrollmentGroup] = useState(
    task && task.autoEnrollmentGroup
  );

  useEffect(
    () => {
      if (task && isSameDay(task.startTime, task.endTime)) {
        setTimeConstraints();
      }
    },
    [task]
  );

  useEffect(
    () => {
      setIsReadOnly(readOnly);
    },
    [readOnly]
  );

  useEffect(
    () => {
      if (typeof onChange !== 'function') {
        return;
      }
      if (!isStartTimeValid || !isEndTimeValid) {
        onChange(null);
      } else {
        onChange({
          taskName,
          startTime,
          endTime,
          isAuthenticationRequired,
          allowLatePerformance,
          autoEnrollmentGroup
        });
      }
    },
    [
      taskName,
      startTime,
      endTime,
      isAuthenticationRequired,
      allowLatePerformance,
      autoEnrollmentGroup
    ]
  );

  async function handleSubmit() {
    if (isReadOnly) {
      return;
    }
    setSubmitStatus('IN_PROGRESS');
    try {
      const saveDTO = {
        taskName,
        startTime,
        endTime,
        isAuthenticationRequired,
        allowLatePerformance,
        autoEnrollmentGroup
      };
      await saveTask(task.id, saveDTO);
      setSubmitStatus('SUCCESS');
      if (readOnly) {
        setIsReadOnly(true);
      }
      if (typeof onSuccess === 'function') {
        onSuccess(saveDTO);
      }
    } catch (e) {
      setSubmitStatus('ERROR');
    }
  }

  function handleStartTimeChange(value) {
    setIsStartTimeValid(!value || moment.isMoment(value));
    // TODO Possible to set invalid date (with regards to constraints) by typing in input...
    const onlyTimeChanged = isSameDay(startTime, value);
    if (isSameDay(value, endTime)) {
      const newStartTime = onlyTimeChanged ? value : assignTime(value, endTime);
      setTimeConstraints(newStartTime, endTime);
      setStartTime(newStartTime);
    } else {
      clearTimeConstraints();
      setStartTime(value);
    }
  }

  function handleEndTimeChange(value) {
    setIsEndTimeValid(!value || moment.isMoment(value));
    // TODO Possible to set invalid date (with regards to constraints) by typing in input...
    const onlyTimeChanged = isSameDay(endTime, value);
    if (isSameDay(value, startTime)) {
      const newEndTime = onlyTimeChanged ? value : assignTime(value, startTime);
      setTimeConstraints(startTime, newEndTime);
      setEndTime(newEndTime);
    } else {
      clearTimeConstraints();
      setEndTime(value);
    }
  }

  function assignTime(toDate, fromDate) {
    let fromMoment = moment(fromDate);
    return moment(toDate)
      .hours(fromMoment.hours())
      .minutes(fromMoment.minutes())
      .toDate();
  }

  function isSameDay(date1, date2) {
    if (!date1 || !date2) return false;
    return moment(date1).isSame(moment(date2), 'd');
  }

  function isSameOrAfterStartTime(dateWithNoTime) {
    return (
      !startTime ||
      moment(dateWithNoTime).isSameOrAfter(moment(startTime).startOf('day'))
    );
  }

  function isSameOrBeforeEndTime(dateWithNoTime) {
    return (
      !endTime ||
      moment(dateWithNoTime).isSameOrBefore(moment(endTime).startOf('day'))
    );
  }

  function clearTimeConstraints() {
    setEndTimeConstraints({});
    setStartTimeConstraints({});
  }

  function setTimeConstraints(startTime, endTime) {
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);
    setEndTimeConstraints(
      !startMoment
        ? {}
        : {
            hours: {
              min: startMoment.hours()
            },
            minutes: {
              min: startMoment.minutes()
            }
          }
    );
    setStartTimeConstraints(
      !endMoment
        ? {}
        : {
            hours: {
              max: endMoment.hours()
            },
            minutes: {
              max: endMoment.minutes()
            }
          }
    );
  }

  function cancelEdit() {
    setTaskName(task.taskName);
    setStartTime(task.startTime);
    setEndTime(task.endTime);
    setIsAuthenticationRequired(task.isAuthenticationRequired);
    setAllowLatePerformance(task.allowLatePerformance);
    setAutoEnrollmentGroup(task.autoEnrollmentGroup);
    setIsReadOnly(true);
  }

  return isReadOnly ? (
    <Form>
      {taskName && (
        <Form.Field>
          <label htmlFor="taskName">{t('taskForm.taskName.label')}</label>
          {taskName}
        </Form.Field>
      )}

      <Form.Checkbox
        inline
        label={t('taskForm.authenticationRequired')}
        name="isAuthenticationRequired"
        id="isAuthenticationRequired"
        checked={isAuthenticationRequired}
      />

      <Form.Field>
        <label>{t('taskForm.startTime.label')}</label>
        {fmtDateTime(startTime)}
      </Form.Field>

      {endTime && (
        <Form.Field>
          <label>{t('taskForm.endTime.label')}</label>
          {fmtDateTime(endTime)}
        </Form.Field>
      )}

      {endTime && (
        <Form.Checkbox
          inline
          label={t('taskForm.latePerformance')}
          name="allowLatePerformance"
          id="allowLatePerformance"
          checked={allowLatePerformance}
        />
      )}

      {autoEnrollmentGroup && (
        <Form.Field>
          <label>{t('taskForm.enrollmentGroup.label.readOnly')}</label>
          <UserGroupSelect
            readOnly
            label={t('taskForm.enrollmentGroup')}
            selectedGroup={autoEnrollmentGroup}
            onChange={groupId => setAutoEnrollmentGroup(groupId)}
          />
        </Form.Field>
      )}

      {task &&
        task.id && (
          <Form.Field>
            <Button
              key="taskEditBtn"
              type="button"
              onClick={() => setIsReadOnly(false)}
            >
              {t('taskForm.editBtn')}
            </Button>
          </Form.Field>
        )}
    </Form>
  ) : (
    <Form success={submitStatus === 'SUCCESS'} error={submitStatus === 'ERROR'}>
      <Form.Field>
        <label htmlFor="taskName">
          <InfoIcon content={t('taskForm.taskName.info')} />
          {t('taskForm.taskName.label')}
        </label>
        <Input
          type="text"
          name="taskName"
          id="taskName"
          value={taskName}
          onChange={e => setTaskName(e.target.value)}
        />
      </Form.Field>

      <Form.Checkbox
        inline
        label={t('taskForm.authenticationRequired')}
        name="isAuthenticationRequired"
        id="isAuthenticationRequired"
        checked={isAuthenticationRequired}
        onChange={() => setIsAuthenticationRequired(!isAuthenticationRequired)}
      />

      <Form.Field error={!isStartTimeValid}>
        <label>
          <InfoIcon content={t('taskForm.startTime.info')} />
          {t('taskForm.startTime.label')}
        </label>
        <Datetime
          value={startTime}
          isValidDate={isSameOrBeforeEndTime}
          timeConstraints={startTimeConstraints}
          onChange={handleStartTimeChange}
        />
      </Form.Field>

      <Form.Field error={!isEndTimeValid}>
        <label>
          <InfoIcon content={t('taskForm.endTime.info')} />
          {t('taskForm.endTime.label')}
        </label>
        <Datetime
          value={endTime}
          isValidDate={isSameOrAfterStartTime}
          timeConstraints={endTimeConstraints}
          onChange={handleEndTimeChange}
        />
      </Form.Field>

      {endTime && (
        <Form.Checkbox
          inline
          label={t('taskForm.latePerformance')}
          name="allowLatePerformance"
          id="allowLatePerformance"
          checked={allowLatePerformance}
          onChange={() => setAllowLatePerformance(!allowLatePerformance)}
        />
      )}

      <UserGroupSelect
        label={t('taskForm.enrollmentGroup.label.edit')}
        selectedGroup={autoEnrollmentGroup}
        onChange={groupId => setAutoEnrollmentGroup(groupId)}
      />

      {task &&
        task.id && (
          <Form.Field>
            {/* Cool gotcha: when this was type=submit, then clicking edit on readOnly form above triggered submit here */}
            {/* Adding different keys to the buttons seemed to do the trick. */}
            <Button
              key="saveTaskBtn"
              primary
              type="button"
              onClick={handleSubmit}
              loading={submitStatus === 'IN_PROGRESS'}
            >
              {t('taskForm.saveBtn')}
            </Button>
            {readOnly && (
              <Button
                key="cancelTaskEditBtn"
                type="button"
                onClick={cancelEdit}
              >
                {t('cancel')}
              </Button>
            )}
          </Form.Field>
        )}
    </Form>
  );
}

export default TaskForm;
