import { getSettingStringValue, UserSettingCode } from './userSettings';
import { hotjar } from 'react-hotjar';

export async function setupHotjar() {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  try {
    const isEnabledString = await getSettingStringValue(
      UserSettingCode.ANALYTICS__UI_USAGE
    );
    if (isEnabledString === 'true') {
      hotjar.initialize(
        process.env.REACT_APP_HOTJAR_HJID,
        process.env.REACT_APP_HOTJAR_HJSV
      );
    }
  } catch (e) {
    // TODO
  }
}
