import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import { inject, observer } from 'mobx-react';
import { Icon, Label, Popup } from 'semantic-ui-react';
import GripIcon from '../../common/GripIcon';

const perfOrderedAnswerSource = {
  beginDrag(props, monitor, component) {
    const boundingRect = findDOMNode(component).getBoundingClientRect();
    return {
      id: props.id,
      index: props.index,
      width: boundingRect.width,
      height: boundingRect.height
    };
  },
  canDrag(props) {
    return !props.isReviewPhase;
  }
};

const perfOrderedAnswerTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveAnswer(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

class PerfOrderAnswer extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func,
    isDragging: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    id: PropTypes.any.isRequired,
    orderedAnswer: PropTypes.object.isRequired,
    moveAnswer: PropTypes.func.isRequired
  };

  renderIcon = () => {
    if (!this.props.isReviewPhase) return <GripIcon />;
    const { index } = this.props;
    const correctOrderNr = this.getCorrectOrderNr();
    const isCorrect = correctOrderNr === index + 1;
    return isCorrect ? (
      <Icon color="green" name="check" />
    ) : (
      <Popup
        disabled={!correctOrderNr}
        content={correctOrderNr}
        trigger={<Icon color="red" name="x" />}
      />
    );
  };

  getCorrectOrderNr = () => {
    const { orderedAnswer, index } = this.props;
    const { correctAnswer } = this.props.perfStore;
    if (!correctAnswer) {
      // Absence of correctAnswer indicates that no mistake was made
      return index + 1;
    }
    const correctOrderedAnswer = correctAnswer.orderedAnswers
      ? correctAnswer.orderedAnswers.find(it => it.id === orderedAnswer.id)
      : null;
    if (!correctOrderedAnswer) {
      // Absence of answer in correctAnswer indicates that the answer was wrong,
      // but we shouldn't let the user know what the correct answer is.
      return null;
    }
    return correctOrderedAnswer.orderNr;
  };

  render() {
    const {
      connectDragSource,
      connectDropTarget,
      isDragging,
      orderedAnswer
    } = this.props;
    return connectDragSource(
      connectDropTarget(
        <div
          style={{
            opacity: isDragging ? 0 : 1,
            cursor: 'move',
            display: 'inline-block'
          }}
        >
          <Label>
            {this.renderIcon()}
            <span style={{ whiteSpace: 'pre-wrap' }}>{orderedAnswer.text}</span>
          </Label>
        </div>
      )
    );
  }
}

export default DropTarget(
  'perfOrderedAnswer',
  perfOrderedAnswerTarget,
  connect => ({
    connectDropTarget: connect.dropTarget()
  })
)(
  DragSource(
    'perfOrderedAnswer',
    perfOrderedAnswerSource,
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  )(inject('perfStore')(observer(PerfOrderAnswer)))
);
