import React, { useEffect, useState } from 'react';
import { Button, Card, Header, List, Segment } from 'semantic-ui-react';
import { fetchDashboardAssignments } from '../api/dashboard';
import sortBy from 'lodash/sortBy';
import './StudentDashboard.css';
import { fmtDateTime } from '../utils/dateUtils';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TaskChooser from './common/TaskChooser';
import { useHistory } from 'react-router';

const DateSpan = styled.span`
  margin-right: 1em;
  font-style: italic;
  opacity: 0.5;
`;

function StudentDashboard() {
  const [t] = useTranslation();
  const history = useHistory();
  const [isMounted, setIsMounted] = useState(true);
  const [assignments, setAssignments] = useState([]);

  useEffect(
    () => {
      fetchDashboardAssignments().then(response => {
        if (isMounted) {
          setAssignments(sortBy(response.data, 'taskStartTime').reverse());
        }
        return () => setIsMounted(false);
      });
    },
    [isMounted]
  );

  function performTask(taskCode) {
    history.push('/perform/' + taskCode);
  }

  return (
    <>
      <TaskChooser
        style={{ maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}
        onChoose={performTask}
        buttonText={t('home.taskCode.startBtn')}
      />

      <Segment
        style={{ maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Header>{t('studentDashboard.invitationsAndTasks.header')}</Header>

        {assignments.map(assignment => (
          <Card fluid key={assignment.taskCode}>
            <Card.Content>
              <span>{assignment.lessonName}</span>
              <Card.Description>
                {t('studentDashboard.invitationsAndTasks.assigner')}:{' '}
                {assignment.assigner}
              </Card.Description>

              <List>
                {assignment.taskEndTime && (
                  <List.Item>
                    <DateSpan>
                      {t('studentDashboard.invitationsAndTasks.taskEndTime')}:{' '}
                      {fmtDateTime(assignment.taskEndTime)}
                    </DateSpan>
                  </List.Item>
                )}
                <List.Item>
                  <DateSpan>
                    {t('studentDashboard.invitationsAndTasks.taskStartTime')}:{' '}
                    {fmtDateTime(assignment.taskStartTime)}
                  </DateSpan>
                </List.Item>
              </List>
            </Card.Content>
            <Card.Content extra>
              <Button
                style={{ float: 'right', minWidth: '90px' }}
                as={Link}
                to={`/perform/${assignment.taskCode}`}
              >
                {assignment.performanceStartTime
                  ? t('studentDashboard.invitationsAndTasks.continueBtn')
                  : t('studentDashboard.invitationsAndTasks.startBtn')}
              </Button>
            </Card.Content>
          </Card>
        ))}
      </Segment>
    </>
  );
}

export default StudentDashboard;
