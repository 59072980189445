import React from 'react';
import { Button, Icon, Label, Menu, Sticky } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import './LessonStatus.css';
import { Link } from 'react-router-dom';
import PublishButton from '../dashboard/PublishButton';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

function LessonStatus(props) {
  const [t] = useTranslation();
  const { lesson } = props.lessonEditStore;
  const { isInvalid, isSaving, id } = lesson;
  const { contextRef } = props;
  const popupMessage = isInvalid
    ? t('lessonEdit.footer.invalidMsg')
    : isSaving
      ? t('lessonEdit.footer.savingMsg')
      : t('lessonEdit.footer.savedMsg');
  const messageColor = isInvalid ? 'yellow' : 'green';
  const messageIcon = isInvalid
    ? 'exclamation circle'
    : isSaving ? 'circle notched' : 'check circle';
  const history = useHistory();

  function handlePublish(taskId) {
    history.push(`/tasks/${taskId}`);
  }

  return (
    <Sticky context={contextRef} offset={54}>
      <Menu borderless fluid id="lesson-status-container">
        <Menu.Item>
          {lesson.status === 'DRAFT' ? (
            <Link to={`/lessons/${id}`}>
              <Icon name="arrow left" /> {t('lessonEdit.footer.exitEdit')}
            </Link>
          ) : (
            <Button onClick={() => history.goBack()}>
              <Icon name="arrow left" /> {t('lessonEdit.footer.back')}
            </Button>
          )}
        </Menu.Item>
        <Menu.Item>
          <Label color={messageColor}>
            <Icon name={messageIcon} loading={isSaving} /> {popupMessage}
          </Label>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item
            disabled={isInvalid || isSaving}
            as={Link}
            to={isInvalid || isSaving ? '#' : `/preview/${id}`}
            target={isInvalid || isSaving ? null : '_blank'}
          >
            <Icon name="eye" /> {t('lessonEdit.footer.previewBtn')}
          </Menu.Item>
          {/* Only DRAFT lessons should be used to make new tasks, otherwise errors will be abound */}
          {lesson.status === 'DRAFT' && (
            <Menu.Item>
              <PublishButton
                primary
                disabled={isInvalid || isSaving}
                lessonId={lesson.id}
                lessonName={lesson.name}
                onPublish={handlePublish}
              />
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
    </Sticky>
  );
}

export default inject('lessonEditStore')(observer(LessonStatus));
