import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { List } from 'semantic-ui-react';

import AnswerGroup from './AnswerGroup';
import PreviewSegment from '../../../../common/PreviewSegment';
import AddButton from '../../AddButton';
import { withTranslation } from 'react-i18next';

class GroupQuestion extends Component {
  handleAdd = () => {
    this.props.question.addAnswerGroup('');
  };

  render() {
    const { t } = this.props;
    const groups = this.props.question.answerGroups;
    return (
      <List>
        <AddButton
          content={t('lessonEdit.questions.groupQuestion.addGroupBtn')}
          onClick={this.handleAdd}
        />
        {groups.map((group, idx) => (
          <List.Item key={idx}>
            <AnswerGroup group={group} />
          </List.Item>
        ))}
        <PreviewSegment />
      </List>
    );
  }
}

GroupQuestion.propTypes = {};

export default withTranslation()(observer(GroupQuestion));
