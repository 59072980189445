import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { observer } from 'mobx-react';
import { FORMATS, TOOLBAR_CONTAINER } from '../../config/quill';
import ImageUploadModal from './ImageUploadModal';

function QuillEditor(props) {
  const reactQuillRef = useRef();
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [selection, setSelection] = useState(null);
  const [modules] = useState({
    toolbar: {
      container: TOOLBAR_CONTAINER,
      handlers: {
        image: () => {
          setSelection(reactQuillRef.current.getEditor().getSelection());
          setImageModalOpen(true);
        }
      }
    }
  });

  function handleImageModalClose(result) {
    if (result && result.src) {
      const quillRef = reactQuillRef.current.getEditor();
      quillRef.insertEmbed(selection.index, 'image', result, 'user');
    }
    setImageModalOpen(false);
  }

  return (
    <>
      <ReactQuill
        ref={reactQuillRef}
        onChange={props.onChange}
        value={props.value}
        modules={modules}
        formats={FORMATS}
      />
      <ImageUploadModal
        isOpen={isImageModalOpen}
        onClose={handleImageModalClose}
      />
    </>
  );
}

export default observer(QuillEditor);
