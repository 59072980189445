import API from './API';

const BASE_PATH = '/api/users';

export const saveUserMetadata = metadata => {
  return API.post(`${BASE_PATH}/metadata`, metadata);
};

export const getUserSettings = () => {
  return API.get(`${BASE_PATH}/settings`);
};

export const saveUserSettings = settings => {
  return API.post(`${BASE_PATH}/settings`, settings);
};
