import AppState from './AppState';
import Auth from './Auth';
import PerfStore from './PerfStore';
import GroupStore from './GroupStore';
import LessonOverviewStore from './manage/LessonOverviewStore';
import LessonEditStore from './manage/LessonEditStore';
import InvitationStore from './InvitationStore';
import TaskOverviewStore from './manage/TaskOverviewStore';

export default class RootStore {
  constructor() {
    this.stores = {
      appState: new AppState(this),
      auth: new Auth(this),
      perfStore: new PerfStore(this),
      groupStore: new GroupStore(this),
      lessonOverviewStore: new LessonOverviewStore(this),
      taskOverviewStore: new TaskOverviewStore(this),
      lessonEditStore: new LessonEditStore(this),
      invitationStore: new InvitationStore(this)
    };
  }
}
