import { action, computed, decorate, observable } from 'mobx';
import debounce from 'lodash/debounce';
import remove from 'lodash/remove';
import uniqueId from 'lodash/uniqueId';
import Material from './material';
import Question from './question';
import { deleteStage, saveStage } from '../../../api';

class Stage {
  id = null;
  lesson = null;
  orderNr = null;
  name = '';
  materials = [];
  questions = [];

  store = null;

  constructor(store, id, lesson, json) {
    this.id = id;
    this.store = store;
    this.lesson = lesson;
    this.updateFromJson(json);
  }

  persistChanges = debounce(async () => {
    const { data } = await saveStage(this.asJson);
    if (typeof this.id === 'string') {
      this.id = data.id;
    }
  }, 500);

  updateFromJson(json) {
    this.orderNr = json.orderNr;
    this.name = json.name;
    this.materials = json.materials
      ? json.materials.map(
          material => new Material(this.store, material.id, this, material)
        )
      : [];
    this.questions = json.questions
      ? json.questions.map(
          question => new Question(this.store, question.id, this, question)
        )
      : [];
  }

  get asJson() {
    return {
      id: typeof this.id === 'string' ? null : this.id,
      name: this.name,
      lessonId: this.lesson.id,
      orderNr: this.orderNr
    };
  }

  get orderedMaterials() {
    return this.materials.sort((a, b) => a.orderNr - b.orderNr);
  }

  get latestVideo() {
    let reversedVideos = this.orderedMaterials
      .filter(m => m.type === 'VIDEO')
      .reverse();
    return reversedVideos[0];
  }

  get isFilled() {
    return (
      (this.materials &&
        this.materials.length > 0 &&
        this.materials.some(m => typeof m.id !== 'string')) ||
      (this.questions &&
        this.questions.length > 0 &&
        this.questions.some(q => typeof q.id !== 'string'))
    );
  }

  get openQuestions() {
    return this.questions ? this.questions.filter(q => q.type === 'OPEN') : [];
  }

  get automaticQuestions() {
    return this.questions ? this.questions.filter(q => q.type !== 'OPEN') : [];
  }

  updateField(name, value, persistChanges = true) {
    this[name] = value;
    if (persistChanges) this.persistChanges();
  }

  moveMaterial(from, to) {
    const movedAhead = from < to;
    const movedBehind = from > to;
    const toAdjust = [];
    this.orderedMaterials.forEach((m, index) => {
      let increment = movedAhead && index > from && index <= to;
      let decrement = movedBehind && index >= to && index < from;
      if (increment || decrement) {
        toAdjust.push(m);
      }
    });
    const moved = this.orderedMaterials[from];
    moved.updateField('orderNr', to + 1);
    toAdjust.forEach(material => {
      const newOrderNr = movedAhead
        ? material.orderNr - 1
        : material.orderNr + 1;
      // Only make server request for actually moved item, adjustments are made server-side
      material.updateField('orderNr', newOrderNr, false);
    });
  }

  createMaterial(orderNr = this.materials.length + 1) {
    this.materials
      .filter(material => material.orderNr >= orderNr)
      .forEach(material =>
        material.updateField('orderNr', material.orderNr + 1)
      );
    this.materials.push(
      new Material(this.store, uniqueId('material'), this, { orderNr })
    );
  }

  removeMaterial(material) {
    const { orderNr } = material;
    const materialsToPullForward = this.materials.filter(
      m => m.orderNr > orderNr
    );
    remove(this.materials, m => m.id === material.id);
    materialsToPullForward.forEach(m =>
      m.updateField('orderNr', m.orderNr - 1, false)
    );
  }

  createQuestion(type = null, index = this.questions.length) {
    const question = new Question(this.store, uniqueId('question'), this, {
      type
    });
    this.questions.splice(index, 0, question);
  }

  async remove() {
    if (typeof this.id !== 'string') {
      await deleteStage(this.id);
    }
    this.lesson.removeStage(this);
  }
}

export default decorate(Stage, {
  lesson: observable,
  orderNr: observable,
  name: observable,
  materials: observable,
  questions: observable,

  asJson: computed,
  orderedMaterials: computed,
  latestVideo: computed,
  isFilled: computed,
  openQuestions: computed,
  automaticQuestions: computed,

  moveMaterial: action,
  createMaterial: action,
  removeMaterial: action,
  createQuestion: action,
  remove: action
});
