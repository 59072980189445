import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Form,
  Header,
  Message
} from 'semantic-ui-react';
import uniqueId from 'lodash/uniqueId';
import { getUserSettings, saveUserSettings } from '../../api/users';
import { getRecallQuestions } from '../../api/recallQuestions';
import RecallQuestion from './RecallQuestion';

function RecallDashboard() {
  const [t] = useTranslation();
  const [isEmailReminderEnabled, setIsEmailReminderEnabled] = useState(false);
  const [recallQuestions, setRecallQuestions] = useState([]);
  //const [materials, setMaterials] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [isLoadMoreBtnVisible, setIsLoadMoreBtnVisible] = useState(false);

  useEffect(() => {
    getUserSettings().then(response => {
      const setting = response.data.find(
        it => it.code === 'EMAIL__SEND_SPACED_REPETITION_REMINDER'
      );
      if (setting) {
        const val = setting.value.toString().toLowerCase() === 'true';
        setIsEmailReminderEnabled(val);
      }
    });
    loadQuestions();
  }, []);

  function loadQuestions() {
    getRecallQuestions().then(response => {
      setRecallQuestions(response.data.questions);
      //setMaterials(response.data.materials);
      setActiveQuestion(response.data.questions[0]);
      setIsLoadMoreBtnVisible(false);
    });
  }

  function updateEmailReminderEnabled() {
    const newVal = !isEmailReminderEnabled;
    setIsEmailReminderEnabled(newVal);
    saveUserSettings([
      {
        code: 'EMAIL__SEND_SPACED_REPETITION_REMINDER',
        type: 'BOOLEAN',
        value: newVal
      }
    ]);
  }

  function handleNextQuestion() {
    if (activeQuestion) {
      const currentIdx = recallQuestions.findIndex(
        q => q.id === activeQuestion.id
      );
      if (currentIdx === recallQuestions.length - 1) {
        setActiveQuestion(null);
        setIsLoadMoreBtnVisible(true);
        return;
      }
      setActiveQuestion(recallQuestions[currentIdx + 1]);
    }
  }

  function handleShowMaterial(stageId) {
    // TODO Show materials in a modal, perhaps like StageMaterialsModal
  }

  return (
    <Container>
      <Header>{t('recall.header')}</Header>
      <Form>
        <Form.Field>
          <Checkbox
            toggle
            checked={isEmailReminderEnabled}
            onClick={updateEmailReminderEnabled}
            label={t('recall.enableEmailReminder')}
          />
        </Form.Field>
      </Form>
      <Divider />
      {activeQuestion && (
        <RecallQuestion
          key={uniqueId('activeRecallQuestion')}
          question={activeQuestion}
          onNextQuestion={handleNextQuestion}
          onShowMaterial={handleShowMaterial}
        />
      )}
      {recallQuestions &&
        recallQuestions.length === 0 && (
          <Message positive>{t('recall.noQuestions')}</Message>
        )}
      {isLoadMoreBtnVisible && (
        <Message positive>
          <p>{t('recall.allAnswered')}</p>
          <Button onClick={loadQuestions}>{t('recall.loadMore')}</Button>
        </Message>
      )}
    </Container>
  );
}

export default RecallDashboard;
