import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Embed, Form } from 'semantic-ui-react';
import {
  determineEmbedType,
  extractIframeSrc
} from '../../../../utils/embedUtils';

function EmbedMaterial({ material }) {
  const [t] = useTranslation();
  const [html, setHtml] = useState('');
  const [isUnknownEmbed, setIsUnknownEmbed] = useState(false);

  function handleHtmlChange(e, { value }) {
    setIsUnknownEmbed(false);
    setHtml(value);
    extractIframeSrc(value, iframeUrl => {
      const embedType = determineEmbedType(iframeUrl);
      if (!embedType || !iframeUrl) {
        setIsUnknownEmbed(true);
        return;
      }
      material.updateField('embedType', embedType);
      material.updateField('embedSrc', iframeUrl);
    });
  }

  return (
    <>
      {!material.embedSrc && (
        <Form>
          <Form.TextArea
            label={t('lessonEdit.stages.materials.embed.embedSrcLabel')}
            rows={3}
            value={html}
            onChange={handleHtmlChange}
            placeholder={t(
              'lessonEdit.stages.materials.embed.embedSrcPlaceholder'
            )}
            error={
              isUnknownEmbed
                ? {
                    content: t('lessonEdit.stages.materials.embed.unknownEmbed')
                  }
                : null
            }
          />
        </Form>
      )}
      {material.embedType &&
        material.embedSrc && <Embed url={material.embedSrc} />}
    </>
  );
}

export default observer(EmbedMaterial);
