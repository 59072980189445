import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import Mousetrap from 'mousetrap';
import { PERF_PHASE } from '../../../stores/PerfStore';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

function PerformanceFooter(props) {
  const [t] = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { phase, isStagesSkipAllowed } = props.perfStore;

  useEffect(() => {
    Mousetrap.bind('mod+enter', e => {
      if (e.preventDefault) {
        e.preventDefault();
      }
      handleClick();
    });
    return () => {
      Mousetrap.unbind('mod+enter');
    };
    // TODO Could this bite us?
  }, []);

  async function awaitLoading(fn) {
    if (typeof fn !== 'function') {
      console.error('A function was not provided', fn);
      return;
    }
    try {
      setLoading(true);
      await fn();
    } finally {
      setLoading(false);
    }
  }

  function handleContinue() {
    awaitLoading(() => props.perfStore.finishMaterial());
  }

  function handleConfirmAnswers() {
    awaitLoading(() => props.perfStore.continuePerformance());
  }

  function handlePretestAnswer() {
    awaitLoading(() => props.perfStore.answerPretestQuestion());
  }

  function handleStartPosttest() {
    props.perfStore.setIsPosttestIntroShown(false);
  }

  function handlePosttestAnswer() {
    awaitLoading(() => props.perfStore.answerPosttestQuestion());
  }

  function handleFinishPosttest() {
    awaitLoading(() => props.perfStore.finishPosttest());
  }

  function handleStartPretest() {
    props.perfStore.setIsPretestIntroShown(false);
  }

  function handleFinishPretest() {
    awaitLoading(() => props.perfStore.finishPretest());
  }

  function handleSkipStages() {
    awaitLoading(() => props.perfStore.skipStages());
  }

  function handleReturnToLesson() {
    props.perfStore.resetPerformance();
  }

  function handleEndReview() {
    props.perfStore.endReviewPhase();
  }

  function handleClick() {
    const {
      paused,
      isPretestIntroShown,
      isPosttestIntroShown,
      activePretestQuestion,
      activePosttestQuestion,
      isStagesSkipAllowed,
      isInProgress,
      isContinueDisabled,
      isMaterialPhase,
      isReviewPhase,
      isTaskClosed,
      phase
    } = props.perfStore;
    if (phase === PERF_PHASE.RESULT) {
      handleReturnToLesson();
      return;
    }
    if (!isInProgress || isContinueDisabled || isLoading) {
      return;
    }
    if (phase === PERF_PHASE.PRETEST) {
      if (isPretestIntroShown) {
        handleStartPretest();
        return;
      }
      if (!activePretestQuestion && isStagesSkipAllowed) {
        handleSkipStages();
        return;
      }
      if (!activePretestQuestion && !isStagesSkipAllowed) {
        handleFinishPretest();
        return;
      }
      if (activePretestQuestion) {
        handlePretestAnswer();
        return;
      }
    }
    if (phase === PERF_PHASE.POSTTEST) {
      if (isPosttestIntroShown) {
        handleStartPosttest();
        return;
      }
      if (!activePosttestQuestion) {
        handleFinishPosttest();
        return;
      } else {
        handlePosttestAnswer();
        return;
      }
    }
    if (isMaterialPhase) {
      handleContinue();
      return;
    }
    if (phase === PERF_PHASE.QUESTION && !isTaskClosed) {
      handleConfirmAnswers();
      return;
    }
    if (isReviewPhase) {
      handleEndReview();
      return;
    }
    if (paused) {
      props.perfStore.endPause();
    }
  }

  function getPrimaryBtnProps() {
    const {
      isContinueDisabled,
      lesson,
      isTaskClosed,
      activePretestQuestion,
      activePosttestQuestion,
      isPretestIntroShown,
      isPosttestIntroShown,
      isStagesSkipAllowed
    } = props.perfStore;

    switch (props.perfStore.phase) {
      case PERF_PHASE.INTRO:
        return {
          content: t('performance.footer.startBtn'),
          disabled: !lesson || !lesson.id || isTaskClosed
        };
      case PERF_PHASE.PRETEST:
        if (isPretestIntroShown) {
          return {
            content: t('performance.footer.startPretestBtn')
          };
        }
        if (activePretestQuestion) {
          return {
            content: t('performance.footer.confirmBtn')
          };
        }
        return {
          content: isStagesSkipAllowed
            ? t('performance.footer.skipStagesBtn')
            : t('performance.footer.continueLessonBtn')
        };
      case PERF_PHASE.POSTTEST:
        if (isPosttestIntroShown) {
          return {
            content: t('performance.footer.startPosttestBtn')
          };
        }
        if (activePosttestQuestion) {
          return {
            content: t('performance.footer.confirmBtn')
          };
        }
        return {
          content: t('performance.footer.finishPosttest')
        };
      case PERF_PHASE.MATERIAL:
        return {
          content: t('performance.footer.continueBtn'),
          disabled: isContinueDisabled
        };
      case PERF_PHASE.QUESTION:
        return {
          content: t('performance.footer.confirmAnswersBtn'),
          disabled: isTaskClosed
        };
      case PERF_PHASE.RESULT:
        return {
          content: t('performance.footer.backToStartBtn')
        };
      default:
        return {
          content: t('performance.footer.continueBtn')
        };
    }
  }

  const primaryBtnProps = getPrimaryBtnProps();

  return (
    <PhaseButtons
      onClick={handleClick}
      primaryContent={primaryBtnProps.content}
      disabled={isLoading || primaryBtnProps.disabled}
      onExit={() => props.perfStore.resetPerformance()}
    >
      {phase === PERF_PHASE.PRETEST &&
        isStagesSkipAllowed && (
          <Button disabled={isLoading} onClick={handleFinishPretest}>
            {t('performance.footer.continueLessonBtn')}
          </Button>
        )}
    </PhaseButtons>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:nth-child(2) {
    margin-left: auto !important;
  }
`;

const PhaseButtons = observer(
  ({ onClick, disabled, primaryContent, children, onExit }) => {
    const [t] = useTranslation();
    return (
      <ButtonsContainer>
        <Button
          circular
          icon="cancel"
          title={t('performance.exit')}
          onClick={onExit}
        />
        {children}
        <Button
          primary
          onClick={onClick}
          disabled={disabled}
          content={primaryContent}
        />
      </ButtonsContainer>
    );
  }
);

export default inject('perfStore', 'appState')(observer(PerformanceFooter));
