import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import Mousetrap from 'mousetrap';

import './PerfChooseQuestion.css';
import PerfChooseAnswer from './PerfChooseAnswer';

function PerfChooseQuestion(props) {
  const answerOptions = props.question.content.answerOptions;
  const answerOptionNrs = answerOptions.map((ao, index) => index + 1 + '');

  useEffect(
    () => {
      Mousetrap.bind(answerOptionNrs, e => {
        if (props.perfStore.isReviewPhase) {
          return;
        }
        const index = Number(e.key) - 1;
        const answerOption = answerOptions[index];
        props.perfStore.toggleAnswerOption(answerOption);
      });
      return () => {
        Mousetrap.unbind(answerOptionNrs);
      };
    },
    [answerOptionNrs, answerOptions, props.perfStore]
  );

  return (
    <Container>
      {answerOptions &&
        answerOptions.map(answerOption => (
          <PerfChooseAnswer key={answerOption.id} answerOption={answerOption} />
        ))}
    </Container>
  );
}

PerfChooseQuestion.propTypes = {
  question: PropTypes.object.isRequired
};

export default inject('perfStore')(observer(PerfChooseQuestion));
