import React, { useMemo, useState } from 'react';
import { Button, Header, Icon, Popup } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { cloneLesson, fetchPublicLessons } from '../../../../api/lessons';
import { fmtDateTime } from '../../../../utils/dateUtils';
import PaginatedTable from '../../../common/PaginatedTable';
import { Link, useHistory } from 'react-router-dom';
import { inject } from 'mobx-react';

function PublicLessonsList({ appState }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [fetchedPage, setFetchedPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, sortBy, search }) => {
      function buildSortParams(sortBy) {
        if (sortBy && sortBy.length === 1 && sortBy[0].id) {
          return { sort: sortBy[0].id + `,${sortBy[0].desc ? 'desc' : 'asc'}` };
        }
        return {};
      }

      // Give this fetch an ID
      // TODO Is this relevant to prevent overfetching? (copied from react-table sample)
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      fetchPublicLessons({
        page: !!pageIndex ? pageIndex : 0,
        size: !!pageSize ? pageSize : 10,
        ...(!!search && { search }),
        ...buildSortParams(sortBy)
      })
        .then(response => {
          setFetchedPage(response.data);
        })
        .finally(() => {
          setLoading(false);
        });

      // // We'll even set a delay to simulate a server here
      // TODO Is this relevant to prevent overfetching? (copied from react-table sample)
      // setTimeout(() => {
      //   // Only update the data if this is the latest fetch
      //   if (fetchId === fetchIdRef.current) {
      //     const startRow = pageSize * pageIndex;
      //     const endRow = startRow + pageSize;
      //     setData(serverData.slice(startRow, endRow));
      //     setLoading(false);
      //   }
      // }, 1000);
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: t('lessons.table.header.lessonName'),
        accessor: 'name',
        Cell: ({ row, value }) => (
          <Link to={`/market/${row.original.id}`}>{value}</Link>
        )
      },
      {
        Header: t('lessons.table.header.creator'),
        id: 'creator',
        disableSortBy: true,
        accessor: lesson => {
          return lesson.creator && lesson.creator.familyName
            ? lesson.creator.familyName + ', ' + lesson.creator.givenName
            : '-';
        }
      },
      {
        Header: t('lessons.table.header.created'),
        accessor: 'created',
        Cell: ({ value }) => fmtDateTime(value)
      },
      {
        Header: t('lessons.table.header.updated'),
        accessor: 'updated',
        Cell: ({ value }) => fmtDateTime(value)
      },
      {
        id: 'actions',
        Cell: ({ row }) => {
          const lesson = row.original;
          return (
            <>
              <Popup
                content={t('lessons.table.previewPopup')}
                trigger={
                  <Button
                    icon
                    as={Link}
                    to={`/preview/${lesson.id}`}
                    target="_blank"
                  >
                    <Icon name="eye" />
                  </Button>
                }
              />
              <Popup
                content={t('lessons.table.clonePopup')}
                trigger={
                  <Button icon onClick={() => handleLessonClone(lesson.id)}>
                    <Icon name="copy" />
                  </Button>
                }
              />
            </>
          );
        }
      }
    ],
    []
  );

  const data = useMemo(
    () => {
      return fetchedPage ? fetchedPage.content : [];
    },
    [fetchedPage]
  );

  function handleLessonClone(lessonId) {
    cloneLesson(lessonId).then(response => {
      appState.addMessage({
        text: t('lessons.table.cloneMsg'),
        success: true
      });
      history.push(`/lessons/${response.data}`);
    });
  }

  return (
    <>
      <Helmet>
        <title>Mastery • Tundide varamu</title>
      </Helmet>
      <Header>{t('publicLessons.header')}</Header>
      <PaginatedTable
        globalFilterPlaceholder="Otsi tunni nime järgi"
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={fetchedPage ? fetchedPage.totalPages : 0}
      />
    </>
  );
}

export default inject('appState')(PublicLessonsList);
