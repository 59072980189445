import { action, computed, decorate, runInAction } from 'mobx';
import { cloneLesson, deleteLesson, publishLesson } from '../../../api/lessons';

class LessonListItem {
  id = null;
  status = null;
  name = '';
  description = '';
  userId = '';
  created = '';
  updated = '';
  isPublic = false;
  nrOfStages = 0;
  taskCopyCount = 0;

  invitedUsers = [];
  invitedGroups = [];

  store = null;

  constructor(store, id) {
    this.id = id;
    this.store = store;
  }

  updateFromJson(json) {
    this.name = json.name;
    this.status = json.status;
    this.description = json.description;
    this.userId = json.userId;
    this.created = json.created;
    this.updated = json.updated;
    this.isPublic = json.isPublic;
    this.taskCopyCount = json.taskCopyCount;
  }

  get isActive() {
    return this.store.activeLesson && this.store.activeLesson.id === this.id;
  }

  publish(lessonId, publicationData) {
    return publishLesson(lessonId, publicationData);
  }

  async clone() {
    const response = await cloneLesson(this.id);
    const newId = response.data;
    let clonedLesson = new LessonListItem(this.store, newId);
    clonedLesson.name = this.name;
    clonedLesson.status = this.status;
    clonedLesson.description = this.description;
    clonedLesson.userId = this.userId;
    clonedLesson.created = this.created;
    clonedLesson.updated = this.updated;
    clonedLesson.isPublic = false;
    clonedLesson.taskCopyCount = this.taskCopyCount;
    runInAction(() => this.store.lessons.push(clonedLesson));
    return clonedLesson;
  }

  async delete() {
    try {
      await deleteLesson(this.id);
      runInAction(() => {
        this.store.activeLesson = null;
        this.store.removeLesson(this);
      });
    } catch (e) {
      // TODO show error
    }
  }
}

export default decorate(LessonListItem, {
  isActive: computed,

  publish: action
});
