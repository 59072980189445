import React from 'react';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

function OpenQuestion() {
  const [t] = useTranslation();
  return <Message>{t('lessonEdit.questions.openQuestion.message')}</Message>;
}

export default OpenQuestion;
