import API from './API';

const BASE_PATH = '/api/dashboard';

export const fetchTeacherDashboard = () => {
  return API.get(BASE_PATH + '/teacher');
};

export const fetchDashboardAssignments = () => {
  return API.get(BASE_PATH + '/assignments');
};
