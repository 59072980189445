import React from 'react';
import { Button, Responsive } from 'semantic-ui-react';

const TypePicker = ({ types, onChoose }) => {
  function renderButtons() {
    return types.map(({ text, type, icon }, index) => (
      <Button
        key={index}
        icon={icon}
        content={text}
        onClick={() => onChoose(type)}
      />
    ));
  }

  return (
    <>
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
        <Button.Group basic fluid vertical>
          {renderButtons()}
        </Button.Group>
      </Responsive>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Button.Group basic fluid>
          {renderButtons()}
        </Button.Group>
      </Responsive>
    </>
  );
};

export default TypePicker;
