import React from 'react';

function AppLogo() {
  return (
    <img
      id="mastery-logo"
      alt="Mastery logo"
      src="https://res.cloudinary.com/tototoben/image/upload/v1538838335/mastery_qqdcik.png"
    />
  );
}

export default AppLogo;
