import React, { useEffect, useState } from 'react';
import { Message, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { fmtDateTime } from '../../utils/dateUtils';
import { sortByDate, sortByString } from '../../utils/tableUtils';
import { autorun } from 'mobx';
import TaskFilter from './TaskFilter';
import TaskLinkButton from './TaskLinkButton';
import { useTranslation } from 'react-i18next';

const columnNames = {
  startTime: 'startTime',
  endTime: 'endTime',
  lessonName: 'lessonName',
  taskName: 'taskName'
};
const columnDefs = {
  [columnNames.startTime]: {
    sort: sortByDate
  },
  [columnNames.endTime]: {
    sort: sortByDate
  },
  [columnNames.lessonName]: {
    sort: sortByString
  },
  [columnNames.taskName]: {
    sort: sortByString
  }
};

function TasksTable(props) {
  const [t] = useTranslation();
  const { tasks } = props;
  const [tableItems, setTableItems] = useState([]);
  const [sortColumn, setSortColumn] = useState('startTime');
  const [sortDirection, setSortDirection] = useState('descending');
  useEffect(
    () => {
      return autorun(() => {
        setTableItems(
          columnDefs.startTime
            .sort(props.tasks, columnNames.startTime)
            .reverse()
        );
      }); // autorun returns a disposer which will be called on component destroy
    },
    [props.tasks]
  );

  function handleSearch(filter) {
    const lowerCaseFilter = filter.toLowerCase();
    setTableItems(
      tasks.filter(it => {
        return (
          filter.length === 0 ||
          it.taskName.toLowerCase().includes(lowerCaseFilter) ||
          it.code.toLowerCase().includes(lowerCaseFilter) ||
          it.lessonName.toLowerCase().includes(lowerCaseFilter)
        );
      })
    );
  }

  function handleSort(column) {
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortDirection('ascending');
      const sortedItems = columnDefs[column].sort(tableItems, column);
      setTableItems(sortedItems);
      return;
    }
    setTableItems(tableItems.reverse());
    setSortDirection(
      sortDirection === 'ascending' ? 'descending' : 'ascending'
    );
  }

  return (
    <>
      <TaskFilter onSearch={handleSearch} />
      <Table sortable fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ width: '20%' }}
              content={t('tasks.table.code')}
            />
            <Table.HeaderCell
              content={t('tasks.table.lessonName')}
              sorted={
                sortColumn === columnNames.lessonName ? sortDirection : null
              }
              onClick={() => handleSort(columnNames.lessonName)}
            />
            <Table.HeaderCell
              content={t('tasks.table.taskName')}
              sorted={
                sortColumn === columnNames.taskName ? sortDirection : null
              }
              onClick={() => handleSort(columnNames.taskName)}
            />
            <Table.HeaderCell
              content={t('tasks.table.startTime')}
              sorted={
                sortColumn === columnNames.startTime ? sortDirection : null
              }
              onClick={() => handleSort(columnNames.startTime)}
            />
            <Table.HeaderCell
              content={t('tasks.table.endTime')}
              sorted={sortColumn === columnNames.endTime ? sortDirection : null}
              onClick={() => handleSort(columnNames.endTime)}
            />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableItems.map(task => (
            <Table.Row key={task.id}>
              <Table.Cell>
                <Link to={`/tasks/${task.id}`}>{task.code}</Link>
              </Table.Cell>
              <Table.Cell>
                <Link
                  to={`/lessons/${task.originLessonId}?taskCode=${task.code}`}
                >
                  {task.lessonName}
                </Link>
              </Table.Cell>
              <Table.Cell>{task.taskName}</Table.Cell>
              <Table.Cell>{fmtDateTime(task.startTime)}</Table.Cell>
              <Table.Cell>{fmtDateTime(task.endTime)}</Table.Cell>
              <Table.Cell style={{ textAlign: 'right', overflow: 'visible' }}>
                <TaskLinkButton taskCode={task.code} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {tableItems.length === 0 && <Message>{t('tasks.table.noData')}</Message>}
    </>
  );
}

export default inject('appState')(observer(TasksTable));
