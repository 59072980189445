import { action, computed, decorate, observable, runInAction } from 'mobx';
import {
  cloneLesson,
  deleteLesson,
  getLessonOverview,
  publishLesson
} from '../../../api/lessons';
import { isDescriptionEmpty } from '../../../utils/lessonUtils';

class LessonOverview {
  id = null;
  status = null;
  name = '';
  description = '';
  userId = '';
  created = '';
  updated = '';
  nrOfStages = 0;
  taskCopyCount = 0;

  tasks = [];
  invitedUsers = [];
  invitedGroups = [];

  store = null;

  constructor(store, id) {
    this.id = id;
    this.store = store;
  }

  updateFromJson(json) {
    this.name = json.name;
    this.status = json.status;
    this.description = json.description;
    this.userId = json.userId;
    this.created = json.created;
    this.updated = json.updated;
    this.tasks = json.tasks ? json.tasks : [];
  }

  get isActive() {
    return this.store.activeLesson && this.store.activeLesson.id === this.id;
  }

  get hasDescription() {
    return isDescriptionEmpty(this.description);
  }

  async setActive() {
    const { data } = await getLessonOverview(this.id);
    this.store.setActiveLesson(data);
  }

  publish(lessonId, publicationData) {
    return publishLesson(lessonId, publicationData);
  }

  async clone() {
    const response = await cloneLesson(this.id);
    const newId = response.data;
    let clonedLesson = new LessonOverview(this.store, newId);
    clonedLesson.name = this.name;
    clonedLesson.status = this.status;
    clonedLesson.description = this.description;
    clonedLesson.userId = this.userId;
    clonedLesson.created = this.created;
    clonedLesson.updated = this.updated;
    runInAction(() => this.store.lessons.push(clonedLesson));
    return clonedLesson;
  }

  async delete() {
    try {
      await deleteLesson(this.id);
      runInAction(() => {
        this.store.activeLesson = null;
        this.store.removeLesson(this);
      });
    } catch (e) {
      // TODO show error
    }
  }
}

export default decorate(LessonOverview, {
  invitedUsers: observable,
  invitedGroups: observable,

  isActive: computed,
  hasDescription: computed,

  setActive: action,
  publish: action
});
