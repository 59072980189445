import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';

function OpenAnswer(props) {
  return (
    <Form>
      <TextArea disabled value={props.answeredQuestion.answer.answerText} />
    </Form>
  );
}

export default OpenAnswer;
