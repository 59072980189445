import React from 'react';
import Groups from './components/Groups.js';
import GroupFooter from './components/GroupFooter.js';
import Group from './components/Group.js';

const groupRoutes = [
  {
    isPrivate: true,
    exact: true,
    path: '/groups',
    main: () => <Groups />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/group',
    main: () => <Group />,
    footer: () => <GroupFooter />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/groups/:id',
    main: () => <Group />,
    footer: () => <GroupFooter />
  }
];

export default groupRoutes;
