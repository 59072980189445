import { action, computed, decorate, observable, runInAction } from 'mobx';
import { checkInvitation, fetchUserInvitations } from '../api';

class InvitationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  invitations = [];

  get newInvitations() {
    return this.invitations.filter(inv => !inv.clicked);
  }

  async loadInvitations() {
    const { data } = await fetchUserInvitations();
    this.updateFromServer(data);
  }

  updateFromServer(invitationsJson) {
    this.invitations = invitationsJson.map(inv => {
      return new Invitation(inv);
    });
  }

  async checkInvitation(taskCode) {
    const hasInvitation = (await checkInvitation(taskCode)).data;
    if (hasInvitation) {
      runInAction(() => {
        this.invitations
          .filter(inv => inv.taskCode === taskCode)
          .forEach(inv => (inv.clicked = true));
      });
    }
  }
}

class Invitation {
  id = null;
  taskId = '';
  lessonName = '';
  performanceStatus = '';
  taskCode = '';
  clicked = false;

  constructor(json) {
    this.id = json.id;
    this.taskId = json.lessonId;
    this.lessonName = json.lessonName;
    this.performanceStatus = json.performanceStatus;
    this.clicked = json.clicked;
    this.taskCode = json.taskCode;
  }
}

decorate(Invitation, {
  clicked: observable
});

export default decorate(InvitationStore, {
  invitations: observable,
  newInvitations: computed,
  updateFromServer: action
});
