import React, { Component } from 'react';
import { List } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

class GroupsListItem extends Component {
  render() {
    const { group } = this.props;
    return (
      <List.Item as={Link} to={'/groups/' + group.id}>
        <List.Icon name="users" />
        <List.Content>
          <List.Header>{group.groupName}</List.Header>
        </List.Content>
      </List.Item>
    );
  }
}

export default inject('groupStore')(observer(GroupsListItem));
