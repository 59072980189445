import { action, computed, decorate } from 'mobx';
import { cloneLesson } from '../../../api/lessons';

class PublicLesson {
  id = null;
  status = null;
  name = '';
  description = '';
  userId = '';
  creator = null;
  created = '';
  updated = '';
  storeCopyCount = 0;

  store = null;

  constructor(store, id) {
    this.id = id;
    this.store = store;
  }

  updateFromJson(json) {
    this.name = json.name;
    this.status = json.status;
    this.description = json.description;
    this.userId = json.userId;
    this.creator = json.creator;
    this.created = json.created;
    this.updated = json.updated;
    this.storeCopyCount = json.storeCopyCount;
  }

  get isActive() {
    return this.store.activePublicLesson === this;
  }

  setActive() {
    this.store.activePublicLesson = this;
  }

  async clone() {
    try {
      const response = await cloneLesson(this.id);
      const newId = response.data;
      let clonedLesson = new PublicLesson(this.store, newId);
      clonedLesson.name = this.name;
      clonedLesson.status = this.status;
      clonedLesson.description = this.description;
      clonedLesson.userId = this.userId;
      clonedLesson.creator = this.creator;
      clonedLesson.created = this.created;
      clonedLesson.updated = this.updated;
      clonedLesson.storeCopyCount = this.storeCopyCount;
      return clonedLesson;
    } catch (e) {
      // TODO error handling
    }
  }
}

export default decorate(PublicLesson, {
  isActive: computed,
  setActive: action
});
