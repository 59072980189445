import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DragSource } from 'react-dnd';
import { Icon, Input, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import DeleteButton from '../../DeleteButton';

const LabelIcon = styled(Icon)`
  &&&&& {
    margin: 0;
  }
`;

const answerGroupSource = {
  beginDrag(props) {
    return {
      answer: props.answer
    };
  }
};

class AnswerGroupAnswer extends Component {
  render() {
    const { answer, isDragging, connectDragSource, t } = this.props;
    return connectDragSource(
      <div
        style={{
          opacity: isDragging ? 0.3 : 1,
          cursor: 'move'
        }}
      >
        <Input
          fluid
          name="text"
          value={answer.text}
          placeholder={t(
            'lessonEdit.questions.groupQuestion.answerPlaceholder'
          )}
          labelPosition="left"
          onChange={(e, { name, value }) => {
            answer.updateField(name, value);
          }}
        >
          <Label>
            <LabelIcon name="bars" />
          </Label>
          <input />
          <DeleteButton onClick={() => answer.remove()} />
        </Input>
      </div>
    );
  }
}

AnswerGroupAnswer.propTypes = {};

export default withTranslation()(
  DragSource('answerGroup', answerGroupSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))(observer(AnswerGroupAnswer))
);
