import sortBy from 'lodash/sortBy';

export function sortByNumber(items, column) {
  return sortBy(items, item => item[column]);
}

export function sortByDate(items, column) {
  return sortBy(items, item => new Date(item[column]));
}

export function sortByString(items, column) {
  return sortBy(items, item => item[column].toString());
}

export function sortByObjectString(items, column, field) {
  return sortBy(items, item => item[column][field].toString());
}

export function sortByBoolean(items, column, field) {
  return sortBy(items, item => item[column][field]);
}
