import React from 'react';
import { Button, Popup } from 'semantic-ui-react';

const AddButton = props => (
  <Popup
    disabled={!props.disabled}
    content={props.disabledPopupMessage}
    position="top center"
    trigger={
      <div>
        <Button
          positive
          basic
          icon="plus"
          onClick={props.onClick}
          content={props.content}
          disabled={props.disabled}
        />
      </div>
    }
  />
);

export default AddButton;
