import React, { useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import {
  Dimmer,
  Loader,
  Message,
  Pagination,
  Segment
} from 'semantic-ui-react';
import { observer } from 'mobx-react';
import './PdfViewer.css';
import { useResize } from '../../utils/hooks';

function PdfViewer({ file }) {
  const [t] = useTranslation();
  const containerRef = useRef();
  const { width } = useResize(containerRef);
  const [pageHeight, setPageHeight] = useState(0);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  function onLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setIsLoaded(true);
  }

  function onPageLoad({ height }) {
    setPageHeight(height);
  }

  return file ? (
    <div ref={containerRef}>
      <div style={{ minHeight: pageHeight }}>
        <Document
          file={file}
          onLoadSuccess={onLoadSuccess}
          error={
            <Message negative>
              <Message.Header>
                {t('pdfViewer.failedToLoad.header')}
              </Message.Header>
              <Message.Content>
                {t('pdfViewer.failedToLoad.content', { url: file })}
              </Message.Content>
            </Message>
          }
          loading={
            <Segment>
              <Dimmer inverted />
              <Loader inverted active>
                {t('pdfViewer.loading')}
              </Loader>
            </Segment>
          }
        >
          <Page
            onLoadSuccess={onPageLoad}
            pageNumber={pageNumber}
            width={width}
          />
        </Document>
      </div>
      {isLoaded && (
        <>
          <Pagination
            totalPages={numPages}
            activePage={pageNumber}
            onPageChange={(_, { activePage }) => setPageNumber(activePage)}
          />
        </>
      )}
    </div>
  ) : null;
}

export default observer(PdfViewer);
