import { action, computed, decorate, observable, toJS } from 'mobx';
import API from '../api/API';
import findIndex from 'lodash/findIndex';

class GroupStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  groups = [];

  group = {
    userId: '',
    name: '',
    users: [],
    code: ''
  };

  user = {
    userId: '',
    firstName: '',
    lastName: '',
    email: ''
  };

  get dropdownGroups() {
    return this.groups.map(group => {
      return {
        key: group.id,
        value: group.id,
        text: group.groupName
      };
    });
  }

  getGroups() {
    API.get(`/api/user-groups`).then(response => this.setGroups(response));
  }

  setGroups(response) {
    this.groups = response.data;
  }

  setGroup(response) {
    this.group = response.data;
  }

  saveGroup() {
    return new Promise((resolve, reject) =>
      API.post(`/api/user-groups`, toJS(this.group)).then(response => {
        this.resetActiveGroup();
        resolve(response);
      })
    );
  }

  async createGroup(groupName) {
    const response = await API.post(`/api/user-groups`, { name: groupName });
    this.getGroups();
    return response.data;
  }

  resetActiveGroup() {
    this.group = {
      userId: '',
      name: '',
      users: [],
      code: ''
    };
  }

  deleteGroup(groupId) {
    return new Promise((resolve, reject) =>
      API.delete(`/api/user-groups/${groupId}`).then(response => {
        this.removeGroup(groupId);
        resolve();
      })
    );
  }

  removeGroup(groupId) {
    const i = findIndex(this.groups, group => group.id === groupId);
    this.groups.splice(i, 1);
  }

  getGroupById(groupId) {
    this.resetActiveGroup();
    API.get(`/api/user-groups/${groupId}`).then(response => {
      this.setGroup(response);
    });
  }

  updateGroup(groupId) {
    return API.post(`/api/user-groups/${groupId}`, toJS(this.group));
  }

  removeEmail(userId) {
    this.group.users = this.group.users.filter(user => user.userId !== userId);
  }

  addEmail(email) {
    this.user = email;
    this.group.users.push(this.user);
  }

  updateGroupName(value) {
    this.group.name = value;
  }
}

export default decorate(GroupStore, {
  groups: observable,
  group: observable,
  user: observable,

  dropdownGroups: computed,

  getGroups: action,
  setGroups: action,
  setGroup: action,
  saveGroup: action,
  createGroup: action,
  resetActiveGroup: action,
  deleteGroup: action,
  removeGroup: action,
  getGroupById: action,
  updateGroup: action,
  removeEmail: action,
  clearEmail: action,
  updateGroupName: action
});
