import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Icon, Input, List } from 'semantic-ui-react';
import AddButton from '../../AddButton';
import { withTranslation } from 'react-i18next';
import DeleteButton from '../../DeleteButton';

class ChoiceQuestion extends Component {
  handleAddAnswer = () => {
    const isCorrectDefault = this.props.question.answerOptions.length === 0;
    this.props.question.addAnswerOption('', isCorrectDefault);
  };

  render() {
    const { t } = this.props;
    const { answerOptions } = this.props.question;
    return (
      <List>
        <AddButton
          disabled={!this.props.question.isSaved}
          disabledPopupMessage={t(
            'lessonEdit.questions.choiceQuestion.disabledPopupMsg'
          )}
          content={t('lessonEdit.questions.choiceQuestion.addAnswerBtn')}
          onClick={this.handleAddAnswer}
        />
        {answerOptions.map(option => (
          <List.Item key={option.id}>
            <Input
              fluid
              name="text"
              value={option.text}
              placeholder={t(
                'lessonEdit.questions.choiceQuestion.answerPlaceholder'
              )}
              onChange={(e, { name, value }) => {
                option.updateField(name, value);
              }}
            >
              <Button
                icon
                positive={option.correct}
                negative={!option.correct}
                onClick={() => option.updateField('correct', !option.correct)}
              >
                <Icon name={option.correct ? 'check' : 'dont'} />
              </Button>
              <input />
              <DeleteButton onClick={() => option.remove()} />
            </Input>
          </List.Item>
        ))}
      </List>
    );
  }
}

ChoiceQuestion.propTypes = {
  question: PropTypes.object.isRequired
};

export default withTranslation()(observer(ChoiceQuestion));
