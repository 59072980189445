import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Button, Label } from 'semantic-ui-react';
import StagesBarCellContainer from './StagesBarCellContainer';
import './StagesBarCell.css';

function StagesBarCell(props) {
  const { stage } = props;
  const { orderNr, isFilled } = props.stage;
  const isActive = orderNr === props.lessonEditStore.activeStageOrderNr; // TODO

  function handleClick() {
    // TODO Scroll to stage
    props.lessonEditStore.setActiveStageOrderNr(stage.orderNr);
  }

  return (
    <>
      <StagesBarCellContainer>
        <Label
          circular
          className={isActive ? 'active-cell' : null}
          as={Button}
          color={!isFilled ? 'yellow' : null}
          onClick={handleClick}
          content={orderNr}
        />
      </StagesBarCellContainer>
    </>
  );
}

StagesBarCell.propTypes = {
  stage: PropTypes.object.isRequired
};

export default inject('lessonEditStore')(observer(StagesBarCell));
