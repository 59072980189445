import { action, computed, decorate, observable } from 'mobx';
import debounce from 'lodash/debounce';
import { deleteOrderedAnswer, saveOrderedAnswer } from '../../../api';

class OrderedAnswer {
  id = null;
  question = null;
  orderNr = null;
  text = '';

  store = null;

  constructor(store, id, question, json) {
    this.id = id;
    this.store = store;
    this.question = question;
    this.updateFromJson(json);
  }

  persistChanges = debounce(async () => {
    const { data } = await saveOrderedAnswer(this.asJson);
    if (typeof this.id === 'string') {
      this.id = data.id;
    }
  }, 500);

  updateFromJson(json) {
    this.text = json.text;
    this.orderNr = json.orderNr;
  }

  get asJson() {
    return {
      id: typeof this.id === 'string' ? null : this.id,
      questionId: this.question.id,
      text: this.text,
      orderNr: this.orderNr
    };
  }

  updateField(name, value, persistChanges = true) {
    this[name] = value;
    if (persistChanges) this.persistChanges();
  }

  async remove() {
    if (typeof this.id !== 'string') {
      await deleteOrderedAnswer(this.id);
    }
    this.question.removeOrderedAnswer(this);
  }
}

export default decorate(OrderedAnswer, {
  question: observable,
  orderNr: observable,
  text: observable,

  asJson: computed,

  updateField: action,
  remove: action
});
