import React from 'react';
import { Prompt } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

export class RouteLeaveGuard extends React.Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false
  };

  showModal = location =>
    this.setState({
      modalVisible: true,
      lastLocation: location
    });

  closeModal = callback =>
    this.setState(
      {
        modalVisible: false
      },
      typeof callback === 'function' ? callback : () => null
    );

  handleBlockedNavigation = nextLocation => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }
    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { history } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            history.push(lastLocation.pathname);
          }
        );
      }
    });

  render() {
    const {
      t,
      when,
      header,
      description,
      confirmLabel,
      cancelLabel
    } = this.props;
    const trans = {
      header: header ? header : t('routeLeaveGuard.header'),
      confirmLabel: confirmLabel
        ? confirmLabel
        : t('routeLeaveGuard.confirmLabel'),
      cancelLabel: cancelLabel ? cancelLabel : t('routeLeaveGuard.cancelLabel')
    };
    const { modalVisible } = this.state;
    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <Modal size="small" open={modalVisible}>
          <Modal.Header>{trans.header}</Modal.Header>
          {description && (
            <Modal.Content>
              <Modal.Description>{description}</Modal.Description>
            </Modal.Content>
          )}
          <Modal.Actions>
            <Button onClick={this.closeModal} content={trans.cancelLabel} />
            <Button
              primary
              onClick={this.handleConfirmNavigationClick}
              content={trans.confirmLabel}
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(withRouter(RouteLeaveGuard));
