import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomQuill from '../common/CustomQuill';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Message,
  Segment,
  TextArea
} from 'semantic-ui-react';
import { answerRecallQuestion } from '../../api/recallQuestions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import uniqueId from 'lodash/uniqueId';
import { formatDate } from '../../utils/dateUtils';

function RecallQuestion({ question, onNextQuestion, onShowMaterial }) {
  const [t] = useTranslation();
  const [recallDifficulty, setRecallDifficulty] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [answerResponse, setAnswerResponse] = useState(null);
  const getInitialAnswer = useCallback(
    () => {
      switch (question.type) {
        case 'CHOOSE':
          return { answerOptions: [] };
        case 'ORDER':
          return { orderedAnswers: [] };
        case 'GROUP':
          return { answerGroupAnswers: {} };
        case 'OPEN':
          return { openAnswer: '' };
        default:
          return {};
      }
    },
    [question]
  );
  const [answer, setAnswer] = useState(getInitialAnswer());

  useEffect(
    () => {
      setAnswer(getInitialAnswer());
      setAnswerResponse(null);
      setIsSubmitting(false);
      setRecallDifficulty(null);
    },
    [getInitialAnswer]
  );

  function sendAnswer() {
    setIsSubmitting(true);
    const request = {
      difficulty: recallDifficulty,
      answer: {
        questionId: question.id,
        ...answer
      }
    };
    answerRecallQuestion(request).then(response => {
      setAnswerResponse(response.data);
    });
  }

  // function showMaterial() {
  //   if (typeof onShowMaterial === 'function') {
  //     onShowMaterial(question.stageId);
  //   }
  // }

  return (
    <>
      <CustomQuill
        value={question.text}
        modules={{ toolbar: false }}
        readOnly
      />

      {answerResponse && (
        <Message
          positive={answerResponse.isCorrect}
          negative={!answerResponse.isCorrect}
        >
          <Message.Header>
            {answerResponse.isCorrect ? (
              <p>{t('recall.correct')}</p>
            ) : (
              <p>{t('recall.mistake')}</p>
            )}
          </Message.Header>
          <p>
            {t('recall.nextDueDate')}:{' '}
            {formatDate(answerResponse.nextDueDate, 'dd.MM.yyyy')}
          </p>
        </Message>
      )}

      <Form onSubmit={sendAnswer}>
        {question.type === 'CHOOSE' && (
          <ChooseQuestion
            answerOptions={question.content.answerOptions}
            onChange={setAnswer}
            correctAnswer={answerResponse ? answerResponse.correctAnswer : null}
            isCorrect={answerResponse ? answerResponse.isCorrect : null}
            disabled={isSubmitting || answerResponse}
          />
        )}
        {question.type === 'ORDER' && (
          <OrderQuestion
            orderedAnswers={question.content.orderedAnswers}
            onChange={setAnswer}
            correctAnswer={answerResponse ? answerResponse.correctAnswer : null}
            isCorrect={answerResponse ? answerResponse.isCorrect : null}
            disabled={isSubmitting || answerResponse}
          />
        )}
        {question.type === 'GROUP' && (
          <GroupQuestion
            answerGroups={question.content.answerGroups}
            answerGroupAnswers={question.content.answerGroupAnswers}
            onChange={setAnswer}
            correctAnswer={answerResponse ? answerResponse.correctAnswer : null}
            isCorrect={answerResponse ? answerResponse.isCorrect : null}
            disabled={isSubmitting || answerResponse}
          />
        )}
        {question.type === 'OPEN' && (
          <OpenQuestion
            onChange={setAnswer}
            disabled={isSubmitting || answerResponse}
          />
        )}

        {!answerResponse && (
          <>
            <Divider />
            <Form.Group inline>
              <label>{t('recall.difficulty.label')}</label>
              <Form.Radio
                label={t('recall.difficulty.easy')}
                name="recallDifficulty"
                value="DIFFICULT"
                checked={recallDifficulty === 'DIFFICULT'}
                onChange={() => setRecallDifficulty('DIFFICULT')}
              />
              <Form.Radio
                label={t('recall.difficulty.moderate')}
                name="recallDifficulty"
                value="MODERATE"
                checked={recallDifficulty === 'MODERATE'}
                onChange={() => setRecallDifficulty('MODERATE')}
              />
              <Form.Radio
                label={t('recall.difficulty.difficult')}
                name="recallDifficulty"
                value="EASY"
                checked={recallDifficulty === 'EASY'}
                onChange={() => setRecallDifficulty('EASY')}
              />
            </Form.Group>
            <Button primary disabled={isSubmitting} type="submit">
              {t('recall.answer')}
            </Button>
          </>
        )}

        {answerResponse && (
          <>
            <Button type="button" onClick={onNextQuestion}>
              {t('recall.nextQuestion')}
            </Button>
            {/*<Button type="button" onClick={showMaterial}>{t('recall.showMaterial')}</Button>*/}
          </>
        )}
      </Form>
    </>
  );
}

function ChooseQuestion({
  answerOptions,
  onChange,
  correctAnswer,
  isCorrect,
  disabled
}) {
  const [t] = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);

  function onOptionChange(optionId, isChecked) {
    const _selectedOptions = !isChecked
      ? selectedOptions.filter(it => it !== optionId)
      : selectedOptions.concat(optionId);
    setSelectedOptions(_selectedOptions);
    onChange({ answerOptions: _selectedOptions });
  }

  return (
    <>
      {isCorrect == null && (
        <Segment secondary>{t('performance.question.choice.guide')}</Segment>
      )}
      {answerOptions.map(option => (
        <Segment key={`ao-${option.id}`}>
          <Checkbox
            onChange={(_, data) => onOptionChange(option.id, data.checked)}
            checked={selectedOptions.includes(option.id)}
            label={option.text}
            disabled={disabled}
          />
        </Segment>
      ))}
    </>
  );
}

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function OrderQuestion({
  orderedAnswers,
  onChange,
  correctAnswer,
  isCorrect,
  disabled
}) {
  const [t] = useTranslation();
  const [answers, setAnswers] = useState(orderedAnswers.slice());
  useEffect(
    () => {
      onChange({ orderedAnswers: orderedAnswers.map(oa => oa.id) });
    },
    [orderedAnswers, onChange]
  );

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const reordered = reorder(
      answers,
      result.source.index,
      result.destination.index
    );
    setAnswers(reordered);
    onChange({
      orderedAnswers: reordered.map(answer => answer.id)
    });
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={uniqueId('orderlist')}>
        {dropProvided => (
          <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
            {isCorrect == null && (
              <Segment secondary>
                {t('performance.question.order.guide')}
              </Segment>
            )}
            {answers.map((option, idx) => (
              <Draggable
                draggableId={`oa-${option.id}`}
                key={`oa-${option.id}`}
                index={idx}
                isDragDisabled={disabled}
              >
                {dragProvided => (
                  <div
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                  >
                    <Segment>{option.text}</Segment>
                  </div>
                )}
              </Draggable>
            ))}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function GroupQuestion({
  answerGroups,
  answerGroupAnswers,
  onChange,
  correctAnswer,
  isCorrect,
  disabled
}) {
  const [t] = useTranslation();

  function getId(prefix, answerId) {
    return `${prefix}-${answerId}`;
  }

  // TODO This should also probably allow actually to reorder answers in a group
  // (although it doesn't actually do anything) for a less confusing experience.
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const answer = answerGroupAnswers.find(answer => {
      return getId('aga', answer.id) === result.draggableId;
    });
    if (answer) {
      // TODO Probably should not mutate input but rather user props as inital state
      answer.answerGroupId = answerGroups.find(group => {
        return getId('ag', group.id) === result.destination.droppableId;
      }).id;

      onChange({
        answerGroupAnswers: answerGroupAnswers.reduce((result, answer) => {
          result[answer.id] = answer.answerGroupId;
          return result;
        }, {})
      });
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {isCorrect == null && (
        <Segment secondary>
          <p>{t('performance.question.group.guide')}</p>
          <Droppable droppableId={uniqueId('ungrouped')} isDropDisabled={true}>
            {dropProvided => (
              <div ref={dropProvided.innerRef}>
                {answerGroupAnswers
                  .filter(it => it.answerGroupId == null)
                  .map((answer, idx) => (
                    <Draggable
                      draggableId={getId('aga', answer.id)}
                      key={`aga-${answer.id}`}
                      index={idx}
                      isDragDisabled={disabled}
                    >
                      {dragProvided => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <Segment>{answer.text}</Segment>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {dropProvided.placeholder}
              </div>
            )}
          </Droppable>
        </Segment>
      )}
      {answerGroups.map(group => (
        <Droppable droppableId={getId('ag', group.id)} key={`ag-${group.id}`}>
          {provided => (
            <div ref={provided.innerRef}>
              <Segment>
                <p>{group.text}</p>
                {answerGroupAnswers
                  .filter(it => it.answerGroupId === group.id)
                  .map((answer, idx) => (
                    <Draggable
                      draggableId={getId('aga', answer.id)}
                      key={`aga-${answer.id}`}
                      index={idx}
                      isDragDisabled={disabled}
                    >
                      {dragProvided => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <Segment>{answer.text}</Segment>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </Segment>
            </div>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  );
}

function OpenQuestion({ onChange, disabled }) {
  const [t] = useTranslation();

  return (
    <Form.Field required>
      <label>{t('performance.question.open.label')}</label>
      <TextArea
        disabled={disabled}
        placeholder={t('performance.question.open.placeholder')}
        onChange={(e, data) => onChange({ openAnswer: data.value })}
      />
    </Form.Field>
  );
}

export default RecallQuestion;
