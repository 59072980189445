import React, { useCallback, useState } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import {
  Dropdown,
  Icon,
  Input,
  Pagination,
  Table,
  Transition
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

function PaginatedTable({
  columns,
  data,
  fetchData,
  loading,
  globalFilterPlaceholder,
  pageCount: controlledPageCount
}) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy, globalFilter }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      manualSortBy: true,
      manualGlobalFilter: true
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(
    () => {
      fetchData({ pageIndex, pageSize, sortBy, search: globalFilter });
    },
    [fetchData, pageIndex, pageSize, sortBy, globalFilter]
  );

  function handleSearch(e, { value }) {
    setSearch(value);
    doSearch(value);
  }

  const doSearch = useCallback(
    debounce(value => {
      setGlobalFilter(value);
    }, 500),
    []
  );

  return (
    <>
      <Input
        value={search}
        onChange={handleSearch}
        fluid
        icon="search"
        iconPosition="left"
        placeholder={globalFilterPlaceholder}
      />
      <Table sortable {...getTableProps()}>
        <Table.Header>
          {headerGroups.map(headerGroup => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Table.HeaderCell
                  {...column.getHeaderProps()}
                  sorted={
                    column.isSorted
                      ? column.isSortedDesc ? 'descending' : 'ascending'
                      : null
                  }
                  onClick={() =>
                    column.canSort && column.toggleSortBy(!column.isSortedDesc)
                  }
                >
                  {column.render('Header')}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Table.Cell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="1000">
              <span>{t('table.pageSize')}:</span>
              <Dropdown
                inline
                compact
                style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
                defaultValue={10}
                options={[10, 20, 30].map(pageSize => {
                  return {
                    value: pageSize,
                    text: pageSize
                  };
                })}
                onChange={(e, data) => setPageSize(Number(data.value))}
              />

              <Pagination
                defaultActivePage={1}
                totalPages={pageOptions.length}
                onPageChange={(e, data) => gotoPage(data.activePage - 1)}
                firstItem={{
                  content: <Icon name="angle double left" fitted />,
                  disabled: !canPreviousPage,
                  onClick: () => gotoPage(0)
                }}
                lastItem={{
                  content: <Icon name="angle double right" fitted />,
                  disabled: !canNextPage,
                  onClick: () => gotoPage(pageCount - 1)
                }}
                prevItem={{
                  content: <Icon name="angle left" fitted />,
                  disabled: !canPreviousPage,
                  onClick: previousPage
                }}
                nextItem={{
                  content: <Icon name="angle right" fitted />,
                  disabled: !canNextPage,
                  onClick: nextPage
                }}
              />
              <div style={{ display: 'inline-block' }}>
                <Transition visible={loading}>
                  <span>
                    <Icon
                      name="circle notch"
                      loading
                      style={{ marginLeft: '0.5em' }}
                    />
                    {t('loading')}
                  </span>
                </Transition>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
}

export default PaginatedTable;
