import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useQuery } from 'react-query';
import { cloneLesson, fetchPublicLesson } from '../../../../api/lessons';
import { Button, Header, Icon, Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { isDescriptionEmpty } from '../../../../utils/lessonUtils';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';

function PublicLessonOverview({ appState }) {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const lessonId = match.params.lessonId;
  const { isLoading, data } = useQuery(`public-lesson-${lessonId}`, () => {
    return fetchPublicLesson(lessonId).then(response => response.data);
  });

  function handleLessonClone(lessonId) {
    cloneLesson(lessonId).then(response => {
      appState.addMessage({
        text: t('lessons.table.cloneMsg'),
        success: true
      });
      history.push(`/lessons/${response.data}`);
    });
  }

  if (isLoading) {
    return t('loading');
  }

  return (
    <>
      <Header>
        {data.name}
        <Header.Subheader>
          Avaldaja:{' '}
          {data.creator && data.creator.familyName
            ? data.creator.familyName + ', ' + data.creator.givenName
            : '-'}
        </Header.Subheader>
      </Header>

      <Menu stackable secondary>
        <Menu.Item>
          <Button primary icon onClick={() => handleLessonClone(data.id)}>
            <Icon name="copy" /> {t('lessons.overview.cloneBtn')}
          </Button>
        </Menu.Item>
        <Menu.Item as={Link} to={`/preview/${data.id}`} target="_blank">
          <Icon name="eye" /> {t('lessons.overview.previewBtn')}
        </Menu.Item>
      </Menu>

      {!isDescriptionEmpty(data.description) && (
        <ReactQuill
          value={data.description}
          modules={{ toolbar: false }}
          readOnly
        />
      )}
    </>
  );
}

export default inject('appState')(PublicLessonOverview);
