import React from 'react';
import { Container, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

function PerformanceResultContainer() {
  const [t] = useTranslation();
  return (
    <Container>
      <Message success>{t('performance.result.successMsg')}</Message>
    </Container>
  );
}

export default PerformanceResultContainer;
