import { action, computed, decorate, observable, runInAction } from 'mobx';
import debounce from 'lodash/debounce';
import remove from 'lodash/remove';
import { deleteAnswerOption, saveAnswerOption } from '../../../api';

class AnswerOption {
  id = null;
  question = null;
  text = '';
  correct = false;

  store = null;

  constructor(store, id, question, json) {
    this.id = id;
    this.store = store;
    this.question = question;
    this.updateFromJson(json);
  }

  persistChanges = debounce(async () => {
    await this.save();
  }, 500);

  async save() {
    const { data } = await saveAnswerOption(this.asJson);
    if (typeof this.id === 'string') {
      this.id = data.id;
    }
  }

  updateFromJson(json) {
    this.text = json.text;
    this.correct = json.correct;
  }

  get asJson() {
    return {
      id: typeof this.id === 'string' ? null : this.id,
      questionId: this.question.id,
      text: this.text,
      correct: this.correct
    };
  }

  updateField(name, value, persistChanges = true) {
    this[name] = value;
    if (persistChanges) this.persistChanges();
  }

  async remove() {
    if (typeof this.id !== 'string') {
      await deleteAnswerOption(this.id);
    }
    runInAction(() =>
      remove(this.question.answerOptions, a => a.id === this.id)
    );
  }
}

export default decorate(AnswerOption, {
  question: observable,
  text: observable,
  correct: observable,

  asJson: computed,

  updateField: action
});
