import React, { useState } from 'react';
import i18n from 'i18next';
import { Dropdown } from 'semantic-ui-react';
import { getI18n } from 'react-i18next';

function LanguageSelect() {
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n.language ? i18n.language : 'et'
  );

  function handleLanguageChange(e, data) {
    getI18n()
      .changeLanguage(data.value)
      .then(() => {
        setSelectedLanguage(data.value);
      });
  }

  return (
    <Dropdown
      compact
      value={selectedLanguage}
      onChange={handleLanguageChange}
      options={[
        { key: 'et', value: 'et', text: 'Eesti' },
        { key: 'en', value: 'en', text: 'English' }
      ]}
    />
  );
}

export default LanguageSelect;
