import React, { Component } from 'react';
import { Divider, Form, Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import 'react-quill/dist/quill.snow.css';
import { withTranslation } from 'react-i18next';
import QuillEditor from '../../../common/QuillEditor';

class LessonForm extends Component {
  onLessonChange = (e, { name, value }) => {
    this.props.lessonEditStore.lesson.updateField(name, value);
  };

  handleDescriptionChange = value => {
    this.props.lessonEditStore.lesson.updateField('description', value);
  };

  componentWillUnmount() {
    this.props.lessonEditStore.resetLesson();
  }

  render() {
    const { t } = this.props;
    const { lesson } = this.props.lessonEditStore;
    return (
      <Form>
        <Form.Input
          label={t('lessonEdit.form.lessonName')}
          value={lesson.name}
          name="name"
          onChange={this.onLessonChange}
        />

        <Form.Field>
          <label>{t('lessonEdit.form.description')}</label>
          <QuillEditor
            onChange={this.handleDescriptionChange}
            value={lesson.description}
          />
        </Form.Field>

        <Divider />

        <Form.Checkbox
          label={t('lessonEdit.form.isPublic')}
          checked={lesson.isPublic}
          toggle
          onChange={(e, { checked }) => lesson.updateField('isPublic', checked)}
        />

        <Form.Group inline>
          <label>{t('lessonEdit.form.strictness')}</label>
          <Popup
            content={
              <ul>
                <li>{t('lessonEdit.form.strictPopupItem1')}</li>
                <li>{t('lessonEdit.form.strictPopupItem2')}</li>
              </ul>
            }
            trigger={
              <Form.Radio
                label={t('lessonEdit.form.STRICT')}
                name="strictness"
                value="STRICT"
                checked={lesson.strictness === 'STRICT'}
                onChange={this.onLessonChange}
              />
            }
          />
          <Popup
            content={
              <ul>
                <li>{t('lessonEdit.form.moderatePopupItem1')}</li>
                <li>{t('lessonEdit.form.moderatePopupItem2')}</li>
              </ul>
            }
            trigger={
              <Form.Radio
                label={t('lessonEdit.form.MODERATE')}
                name="strictness"
                value="MODERATE"
                checked={lesson.strictness === 'MODERATE'}
                onChange={this.onLessonChange}
              />
            }
          />
          <Popup
            content={
              <ul>
                <li>{t('lessonEdit.form.unrestrictedPopupItem1')}</li>
                <li>{t('lessonEdit.form.unrestrictedPopupItem2')}</li>
              </ul>
            }
            trigger={
              <Form.Radio
                label={t('lessonEdit.form.UNRESTRICTED')}
                name="strictness"
                value="UNRESTRICTED"
                checked={lesson.strictness === 'UNRESTRICTED'}
                onChange={this.onLessonChange}
              />
            }
          />
        </Form.Group>

        <Form.Group inline>
          <label>{t('lessonEdit.form.recallQuestionCount')}</label>
          <Form.Radio
            label="0"
            name="recallQuestionCount"
            value={0}
            checked={lesson.recallQuestionCount === 0}
            onChange={this.onLessonChange}
          />
          <Form.Radio
            label="1"
            name="recallQuestionCount"
            value={1}
            checked={lesson.recallQuestionCount === 1}
            onChange={this.onLessonChange}
          />
          <Form.Radio
            label="2"
            name="recallQuestionCount"
            value={2}
            checked={lesson.recallQuestionCount === 2}
            onChange={this.onLessonChange}
          />
        </Form.Group>
      </Form>
    );
  }
}

export default withTranslation()(
  inject('lessonEditStore')(observer(LessonForm))
);
