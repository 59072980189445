import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PerfQuestion from '../questions/PerfQuestion';
import { Message } from 'semantic-ui-react';
import { Trans, useTranslation } from 'react-i18next';
import FixedProgress from './FixedProgress';

function Pretest(props) {
  const [t] = useTranslation();
  const {
    pretestQuestionCount,
    answeredPretestQuestionCount,
    activePretestQuestion,
    isStagesSkipAllowed,
    isPretestIntroShown
  } = props.perfStore;
  useEffect(
    () => {
      props.perfStore.setIsPretestIntroShown(true);
    },
    [props.perfStore]
  );
  return (
    <div style={{ marginTop: '3em' }}>
      {isPretestIntroShown ? (
        <Message>
          <Message.Content>
            <p>
              <Trans i18nKey="pretest.intro.1">
                Selle õppetüki alguses tuleb sooritada <b>eeltest</b>.
              </Trans>
              <br />
              {t('pretest.intro.2')}
              <br />
              {t('pretest.intro.3')}
              <br />
              {t('pretest.intro.4')}
            </p>
          </Message.Content>
        </Message>
      ) : (
        <>
          <FixedProgress
            total={pretestQuestionCount}
            value={answeredPretestQuestionCount}
            progress="percent"
          />
          {activePretestQuestion ? (
            <div>
              <PerfQuestion question={activePretestQuestion} />
            </div>
          ) : (
            <Message>
              <Message.Content>
                {isStagesSkipAllowed
                  ? t('pretest.successMsg')
                  : t('pretest.failMsg')}
              </Message.Content>
            </Message>
          )}
        </>
      )}
    </div>
  );
}

export default inject('perfStore')(observer(Pretest));
