import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import { observer } from 'mobx-react';
import { Accordion, Form, Icon, Input } from 'semantic-ui-react';

import { getMaterialIconName } from '../../../../utils/lessonUtils';
import TextMaterial from './TextMaterial';
import ImageMaterial from './ImageMaterial';
import VideoMaterial from './VideoMaterial';
import TypePicker from './questions/TypePicker';
import FlexAccordionTitle from '../FlexAccordionTitle';
import DeleteButton from '../DeleteButton';
import DragHandle from '../DragHandle';
import { withTranslation } from 'react-i18next';
import PdfMaterial from './PdfMaterial';
import EmbedMaterial from './EmbedMaterial';

const materialSource = {
  beginDrag(props, monitor, component) {
    const boundingRect = findDOMNode(component).getBoundingClientRect();
    return {
      id: props.id,
      index: props.index,
      width: boundingRect.width,
      height: boundingRect.height
    };
  }
};

const materialTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.onMove(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

class Material extends Component {
  state = { isActive: !this.props.material.type };

  toggleActive = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  handleChooseType = type => {
    this.props.material.updateField('type', type);
  };

  handleDelete = e => {
    e.stopPropagation();
    this.props.material.remove();
  };

  handleChange = (e, { name, value }) => {
    this.props.material.updateField(name, value);
  };

  renderMaterialContent = material => {
    const { t } = this.props;
    switch (material.type) {
      case 'TEXT':
        return <TextMaterial material={material} />;
      case 'IMAGE':
        return <ImageMaterial material={material} />;
      case 'VIDEO':
        return <VideoMaterial material={material} />;
      case 'PDF':
        return <PdfMaterial material={material} />;
      case 'EMBED':
        return <EmbedMaterial material={material} />;
      default:
        return (
          <TypePicker
            onChoose={this.handleChooseType}
            types={[
              {
                type: 'TEXT',
                text: t('lessonEdit.stages.materials.TEXT'),
                icon: getMaterialIconName('TEXT')
              },
              {
                type: 'IMAGE',
                text: t('lessonEdit.stages.materials.IMAGE'),
                icon: getMaterialIconName('IMAGE')
              },
              {
                type: 'VIDEO',
                text: t('lessonEdit.stages.materials.VIDEO'),
                icon: getMaterialIconName('VIDEO')
              },
              {
                type: 'PDF',
                text: t('lessonEdit.stages.materials.PDF'),
                icon: getMaterialIconName('PDF')
              },
              {
                type: 'EMBED',
                text: t('lessonEdit.stages.materials.EMBED'),
                icon: getMaterialIconName('EMBED')
              }
            ]}
          />
        );
    }
  };

  render() {
    const { isActive } = this.state;
    const {
      material,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      isDragging,
      index,
      t
    } = this.props;
    const opacity = isDragging ? 0.3 : 1;

    return connectDragPreview(
      connectDropTarget(
        <div style={{ opacity }}>
          <Accordion styled={isActive}>
            <FlexAccordionTitle
              active={isActive}
              index={index}
              onClick={this.toggleActive}
            >
              {connectDragSource(
                <div>
                  <DragHandle content={index + 1} />
                </div>
              )}
              {isActive ? (
                <Input
                  onClick={e => e.stopPropagation()}
                  size="small"
                  style={{ width: '100%' }}
                  id={`material-name-${material.id}`}
                  name="name"
                  placeholder={t('lessonEdit.stages.materials.namePlaceholder')}
                  value={material.name}
                  onChange={this.handleChange}
                />
              ) : (
                <div>
                  <Icon name={getMaterialIconName(material.type)} />
                  <span>
                    {material.name
                      ? material.name
                      : t('lessonEdit.stages.materials.noName')}
                  </span>
                </div>
              )}
              <DeleteButton onClick={this.handleDelete} />
            </FlexAccordionTitle>
            <Accordion.Content active={isActive}>
              {material.type && (
                <Form>
                  <Form.TextArea
                    label={t('lessonEdit.stages.materials.instructionsLabel')}
                    placeholder={t(
                      'lessonEdit.stages.materials.instructionsPlaceholder'
                    )}
                    name="instructions"
                    value={material.instructions}
                    onChange={this.handleChange}
                    rows={2}
                  />
                </Form>
              )}
              {isActive && this.renderMaterialContent(material)}
            </Accordion.Content>
          </Accordion>
        </div>
      )
    );
  }
}

Material.propTypes = {
  material: PropTypes.object.isRequired,
  onMove: PropTypes.func.isRequired
};

export default withTranslation()(
  DropTarget('material', materialTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  }))(
    DragSource('material', materialSource, (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging()
    }))(observer(Material))
  )
);
