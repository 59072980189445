import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const DeleteButton = styled(Button).attrs({
  negative: true,
  circular: true,
  basic: true,
  icon: 'x',
  title: 'Kustuta'
})`
  &&&&& {
    box-shadow: unset !important;
  }
`;

export default DeleteButton;
