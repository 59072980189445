import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import {
  Button,
  Dimmer,
  Icon,
  Loader,
  Message,
  Modal,
  Segment
} from 'semantic-ui-react';
import './AssetSelector.css';
import { saveAsset } from '../../api/assets';
import { inject } from 'mobx-react';
import AssetList from './AssetList';

const FILE_SIZE_LIMIT_MB = 2;

function AssetSelector({ type, onClose, isOpen, appState }) {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback(
    files => {
      const file = files[0];
      if (file) {
        setIsLoading(true);
        saveAsset(file, file.name, type).then(
          ({ data }) => {
            setIsLoading(false);
            onClose(data);
          },
          () => {
            appState.addMessage({
              error: true,
              text: t('assetSelector.saveFailed')
            });
            setIsLoading(false);
          }
        );
      }
    },
    [appState, t, type, onClose]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections
  } = useDropzone({
    onDrop,
    accept: getAcceptByType(),
    maxSize: FILE_SIZE_LIMIT_MB * 1_000_000,
    disabled: isLoading
  });

  function getAcceptByType() {
    switch (type) {
      case 'PDF':
        return '.pdf';
      case 'IMAGE':
        return 'image/*';
      default:
        return '*';
    }
  }

  function bytesToMB(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2);
  }

  function translateRejections(fileErrors) {
    return fileErrors
      .map(error => {
        switch (error.code) {
          case 'file-too-large':
            return t('assetSelector.rejection.tooLarge', {
              maxSizeMB: FILE_SIZE_LIMIT_MB
            });
          case 'too-many-files':
            return t('assetSelector.rejection.tooMany');
          case 'file-invalid-type':
            return t('assetSelector.rejection.invalidType');
          default:
            return null;
        }
      })
      .filter(it => it != null)
      .join(', ');
  }

  return (
    <Modal id="asset-selector-modal" open={isOpen} onClose={() => onClose()}>
      <Modal.Header>{t('assetSelector.header')}</Modal.Header>
      <Modal.Content>
        {fileRejections &&
          fileRejections.length > 0 && (
            <Message negative>
              <Message.Header>
                {t('assetSelector.rejection.header')}
              </Message.Header>
              <Message.List>
                {fileRejections.map(({ file, errors }) => (
                  <Message.Item key={file.name}>
                    {file.name} ({bytesToMB(file.size)}MB):{' '}
                    {translateRejections(errors)}
                  </Message.Item>
                ))}
              </Message.List>
            </Message>
          )}
        <Segment>
          <div className="asset-dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                <p>{t('assetSelector.dragHere')}</p>
              </>
            ) : (
              <>
                <Icon name="file" size="large" />
                <p>{t('assetSelector.dragHereOrClick')}</p>
              </>
            )}
          </div>
          {isLoading && (
            <Dimmer active>
              <Loader>{t('assetSelector.loading')}</Loader>
            </Dimmer>
          )}
        </Segment>
        <AssetList type={type} onSelect={assetUrl => onClose(assetUrl)} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose()}>{t('assetSelector.close')}</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default inject('appState')(AssetSelector);
