import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Accordion, Icon } from 'semantic-ui-react';
import stripTags from 'striptags';
import truncate from 'lodash/truncate';

import 'react-quill/dist/quill.snow.css';

import { getQuestionIconName } from '../../../../utils/lessonUtils';
import ChoiceQuestion from './questions/ChoiceQuestion';
import OrderQuestion from './questions/OrderQuestion';
import GroupQuestion from './questions/GroupQuestion';
import OpenQuestion from './questions/OpenQuestion';
import TypePicker from './questions/TypePicker';
import FlexAccordionTitle from '../FlexAccordionTitle';
import DeleteButton from '../DeleteButton';
import { withTranslation } from 'react-i18next';
import QuillEditor from '../../../common/QuillEditor';

class Question extends Component {
  state = {
    isActive:
      !this.props.question.type ||
      (this.props.question.type === 'OPEN' && !this.props.question.text)
  };

  toggleActive = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  handleChooseType = type => {
    this.props.question.updateField('type', type);
  };

  handleDelete = (e, question) => {
    e.stopPropagation();
    question.remove();
  };

  renderQuestionContent = question => {
    switch (question.type) {
      case 'CHOOSE':
        return <ChoiceQuestion question={question} />;
      case 'ORDER':
        return <OrderQuestion question={question} />;
      case 'GROUP':
        return <GroupQuestion question={question} />;
      case 'OPEN':
        return <OpenQuestion question={question} />;
      default:
        return <span>Unknown question type!</span>;
    }
  };

  render() {
    const { question, index, t } = this.props;
    const { isActive } = this.state;
    return (
      <Accordion
        styled={isActive}
        style={{
          border: question.isFilled ? '' : '1px solid yellow'
        }}
      >
        <FlexAccordionTitle
          active={isActive}
          index={index}
          onClick={this.toggleActive}
        >
          <Icon name={getQuestionIconName(question.type)} />
          <span>{truncate(stripTags(question.text))}</span>
          {question.type == null && (
            <span>{t('lessonEdit.questions.chooseType')}</span>
          )}
          <DeleteButton onClick={e => this.handleDelete(e, question)} />
        </FlexAccordionTitle>
        <Accordion.Content active={isActive}>
          {question.type == null ? (
            <TypePicker
              onChoose={this.handleChooseType}
              types={[
                {
                  type: 'CHOOSE',
                  text: t('lessonEdit.questions.CHOOSE'),
                  icon: 'checkmark box'
                },
                {
                  type: 'ORDER',
                  text: t('lessonEdit.questions.ORDER'),
                  icon: 'ordered list'
                },
                {
                  type: 'GROUP',
                  text: t('lessonEdit.questions.GROUP'),
                  icon: 'columns'
                }
              ]}
            />
          ) : (
            <>
              <QuillEditor
                onChange={value => question.updateField('text', value)}
                value={question.text}
              />
              {this.renderQuestionContent(question)}
            </>
          )}
        </Accordion.Content>
      </Accordion>
    );
  }
}

export default withTranslation()(observer(Question));
