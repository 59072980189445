import API from './API';

const fetchUserInvitations = () => API.get(`/api/invitations`);

const checkInvitation = taskCode =>
  API.post(`/api/invitations/check-lesson/${taskCode}`);

export function getResourceIdentifier(resource) {
  return resource.id ? `/${resource.id}` : '';
}

const saveStage = async stage =>
  API.post(`/api/stages${getResourceIdentifier(stage)}`, stage);
const deleteStage = async stageId => API.delete(`/api/stages/${stageId}`);

const saveMaterial = async material =>
  API.post(`/api/materials${getResourceIdentifier(material)}`, material);
const deleteMaterial = async materialId =>
  API.delete(`/api/materials/${materialId}`);

const saveQuestion = async question =>
  API.post(`/api/questions${getResourceIdentifier(question)}`, question);
const deleteQuestion = async questionId =>
  API.delete(`/api/questions/${questionId}`);

const saveAnswerOption = async answerOption =>
  API.post(
    `/api/answer-options${getResourceIdentifier(answerOption)}`,
    answerOption
  );
const deleteAnswerOption = async answerOptionId =>
  API.delete(`/api/answer-options/${answerOptionId}`);

const saveOrderedAnswer = async orderedAnswer =>
  API.post(
    `/api/ordered-answers${getResourceIdentifier(orderedAnswer)}`,
    orderedAnswer
  );
const deleteOrderedAnswer = async orderedAnswerId =>
  API.delete(`/api/ordered-answers/${orderedAnswerId}`);

const saveAnswerGroup = async answerGroup =>
  API.post(
    `/api/answer-groups${getResourceIdentifier(answerGroup)}`,
    answerGroup
  );
const deleteAnswerGroup = async answerGroupId =>
  API.delete(`/api/answer-groups/${answerGroupId}`);

const saveAnswerGroupAnswer = async answerGroupAnswer =>
  API.post(
    `/api/answer-group-answers${getResourceIdentifier(answerGroupAnswer)}`,
    answerGroupAnswer
  );
const deleteAnswerGroupAnswer = async answerGroupAnswerId =>
  API.delete(`/api/answer-group-answers/${answerGroupAnswerId}`);

export {
  saveStage,
  deleteStage,
  saveMaterial,
  deleteMaterial,
  saveQuestion,
  deleteQuestion,
  saveAnswerOption,
  deleteAnswerOption,
  saveOrderedAnswer,
  deleteOrderedAnswer,
  saveAnswerGroup,
  deleteAnswerGroup,
  saveAnswerGroupAnswer,
  deleteAnswerGroupAnswer,
  fetchUserInvitations,
  checkInvitation
};
