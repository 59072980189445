import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Header } from 'semantic-ui-react';

import Material from './Material';
import AddButton from '../AddButton';
import { withTranslation } from 'react-i18next';

class Materials extends Component {
  handleMove = (sourceIndex, hoverIndex) => {
    this.props.stage.moveMaterial(sourceIndex, hoverIndex);
  };

  render() {
    const { stage, t } = this.props;
    return (
      <React.Fragment>
        <Header as="h4">{t('lessonEdit.stages.materials.header')}</Header>
        <AddButton
          content={t('lessonEdit.stages.materials.addMaterial')}
          onClick={() => stage.createMaterial()}
        />
        {stage.orderedMaterials &&
          stage.orderedMaterials.map((material, index) => (
            <Material
              key={material.id}
              material={material}
              onMove={this.handleMove}
              index={index}
            />
          ))}
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  inject('lessonEditStore')(observer(Materials))
);
