import React from 'react';
import { Header, Modal, Tab } from 'semantic-ui-react';
import PerformanceMaterial from '../materials/PerformanceMaterial';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StageName = styled.span`
  opacity: 0.7;
`;

function StageMaterialsModal({ stage, materials, open, onClose }) {
  const [t] = useTranslation();

  const materialPanes = materials
    ? materials.map((m, idx) => {
        return {
          menuItem: `${idx + 1}.`,
          pane: {
            key: m.id,
            content: <PerformanceMaterial material={m} />
          }
        };
      })
    : [];

  return (
    <Modal centered={false} open={open} onClose={onClose}>
      <Modal.Header>
        {stage.orderNr}. {t('stageMaterials.stage')}
        {stage.name && (
          <>
            : <StageName>{stage.name}</StageName>
          </>
        )}
      </Modal.Header>
      <Modal.Content>
        <Header as="h3">{t('stageMaterials.materials')}</Header>
        <Tab panes={materialPanes} renderActiveOnly={false} />
      </Modal.Content>
    </Modal>
  );
}

export default StageMaterialsModal;
