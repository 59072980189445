import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import StagesBarCell from './StagesBarCell';

const ConnectingLine = styled.span`
  &&& {
    min-width: 15px !important;
  }
  border-bottom: 4px solid #e8e8e8;
  display: inline-block;
  margin-left: -2px;
  margin-right: -2px;
  z-index: 0;
  transition: border-color 0.3s ease-in-out;
`;

const StagesBar = inject('lessonEditStore')(
  observer(({ lessonEditStore }) => {
    return (
      <PerformanceProgressInternal
        stages={lessonEditStore.lesson.orderedStages}
        activeStage={lessonEditStore.activeStage}
      />
    );
  })
);

const BarContainer = styled.div`
  margin-bottom: 0 !important;
  z-index: 0;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;

  & > *:first-child {
    margin-left: auto;
    padding-left: 5px;
  }
  & > *:last-child {
    margin-right: auto;
    padding-right: 5px;
  }
`;

const PerformanceProgressInternal = observer(({ stages }) => {
  return (
    <>
      <BarContainer>
        {stages.map((stage, idx) => (
          <React.Fragment key={`stagecell${idx}`}>
            <StagesBarCell stage={stage} />
            {idx < stages.length - 1 && <ConnectingLine />}
          </React.Fragment>
        ))}
      </BarContainer>
    </>
  );
});

export default StagesBar;
