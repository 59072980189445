import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Message } from 'semantic-ui-react';

function LoginInNewTab({ auth }) {
  useEffect(
    () => {
      if (auth.isInitialized && !auth.user) {
        auth.login();
      }
    },
    [auth, auth.isInitialized, auth.user, auth.login]
  );

  return auth.user ? (
    <Message success>
      <Message.Header>Sisselogimine õnnestus</Message.Header>
      Võid nüüd antud vahelehe sulgeda, naaseda tuldud lehele ning seda
      värskendada.
    </Message>
  ) : (
    'Login automaatselt sisse...'
  );
}

export default inject('auth')(observer(LoginInNewTab));
