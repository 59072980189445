import API from './API';

const BASE_PATH = '/api/recall';

export const getRecallQuestions = () => {
  return API.get(`${BASE_PATH}/questions`);
};

export const answerRecallQuestion = request => {
  return API.post(`${BASE_PATH}/answer`, request);
};
