// eslint-disable-next-line
import React from 'react';

function isLocalHost(hostname) {
  return !!(
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
}

function isWww(hostname) {
  return hostname.slice(0, 3) === 'www';
}

const SanitizeUrl = props => {
  let url = window.location.href;
  if (isWww(window.location.hostname)) {
    url = url.replace(
      window.location.hostname,
      window.location.hostname.slice(4)
    );
  }
  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.protocol === 'http:' &&
    !isLocalHost(window.location.hostname)
  ) {
    url = url.replace(/^http(?!s)/, 'https');
  }
  if (url !== window.location.href) {
    window.location.href = url;
    return null;
  }
  return props.children;
};

export default SanitizeUrl;
