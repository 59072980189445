import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Message, TransitionGroup } from 'semantic-ui-react';

import './MessageList.css';

class MessageList extends Component {
  handleActiveDismiss = message => {
    this.props.appState.removeActiveMessage(message);
  };

  handlePersistedDismiss = message => {
    this.props.appState.removePersistedMessage(message);
  };

  render() {
    return (
      <div className="messages-container">
        <TransitionGroup animation="fade down">
          {this.props.appState.persistedMessages.map((message, idx) => (
            <Message
              onDismiss={() => this.handlePersistedDismiss(message)}
              key={idx}
              content={message.text}
              error={message.error}
              success={message.success}
              warning={message.warning}
              info={message.info}
            />
          ))}
          {this.props.appState.activeMessages.map((message, idx) => (
            <Message
              onDismiss={() => this.handleActiveDismiss(message)}
              key={idx}
              content={message.text}
              error={message.error}
              success={message.success}
              warning={message.warning}
              info={message.info}
            />
          ))}
        </TransitionGroup>
      </div>
    );
  }
}

export default inject('appState')(observer(MessageList));
