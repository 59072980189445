import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Label } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

function UserGroupSelect(props) {
  const [t] = useTranslation();
  const {
    groupStore,
    onChange,
    multiple,
    label,
    selectedGroup,
    readOnly
  } = props;
  const [currentValues, setCurrentValues] = useState(
    selectedGroup ? [selectedGroup] : []
  );
  const [currentSingleValue, setCurrentSingleValue] = useState(selectedGroup);

  useEffect(
    () => {
      if (
        !groupStore.dropdownGroups ||
        groupStore.dropdownGroups.length === 0
      ) {
        groupStore.getGroups();
      }
    },
    [groupStore]
  );

  function handleMultiChange(event, data) {
    onChange(data.value);
    setCurrentValues(data.value);
  }

  function handleSingleChange(event, data) {
    onChange(data.value);
    setCurrentSingleValue(data.value);
  }

  function handleAddGroup(event, data) {
    groupStore.createGroup(data.value).then(groupId => {
      if (multiple) {
        setCurrentValues(currentValues.concat(groupId));
      } else {
        setCurrentSingleValue(groupId);
      }
    });
  }

  function findGroupName(groupId) {
    const group = groupStore.dropdownGroups.find(it => it.value === groupId);
    return group ? group.text : null;
  }

  if (readOnly) {
    const groupIds = multiple
      ? currentValues
      : [currentSingleValue].filter(it => !!it);
    return (
      <div>
        {groupIds
          .map(it => findGroupName(it))
          .map((groupName, idx) => (
            <Label key={groupName + idx}>{groupName}</Label>
          ))}
      </div>
    );
  }

  return (
    <Form.Field>
      {multiple && (
        <>
          <label>{t('userGroupSelect.label')}</label>
          <Dropdown
            disabled={readOnly}
            fluid
            multiple
            search
            selection
            onChange={handleMultiChange}
            options={groupStore.dropdownGroups}
            placeholder={t('userGroupSelect.placeholder')}
            allowAdditions
            additionLabel={t('userGroupSelect.additionLabel')}
            onAddItem={handleAddGroup}
            value={currentValues}
          />
        </>
      )}
      {!multiple && (
        <>
          <label>{label}</label>
          <Dropdown
            disabled={readOnly}
            fluid
            search
            selection
            clearable
            onChange={handleSingleChange}
            options={groupStore.dropdownGroups}
            placeholder={t('userGroupSelect.placeholder')}
            allowAdditions
            additionLabel={t('userGroupSelect.additionLabel')}
            onAddItem={handleAddGroup}
            value={currentSingleValue}
          />
        </>
      )}
    </Form.Field>
  );
}

export default inject('groupStore')(observer(UserGroupSelect));
