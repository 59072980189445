import React from 'react';

import LessonsList from './dashboard/LessonsList';
import TaskList from './dashboard/TaskList';
import LessonEdit from './edit/LessonEdit';
import LessonOverview from './dashboard/LessonOverview';
import TaskOverview from './dashboard/TaskOverview';
import PublicLessonsList from './market/PublicLessonsList';
import PublicLessonOverview from './market/PublicLessonOverview';

const routes = [
  {
    isPrivate: true,
    exact: true,
    path: '/lessons',
    main: () => <LessonsList />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/lessons/:lessonId',
    fullPage: () => <LessonOverview />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/tasks',
    main: () => <TaskList />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/tasks/:taskId',
    main: () => <TaskOverview />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/lessons/:id/edit',
    fullPage: () => <LessonEdit />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/market',
    privileges: ['teacher', 'admin'],
    main: () => <PublicLessonsList />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/market/:lessonId',
    privileges: ['teacher', 'admin'],
    main: () => <PublicLessonOverview />
  }
];

export default routes;
