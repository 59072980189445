import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch } from 'react-router';
import { inject, observer } from 'mobx-react';
import Logout from '../components/common/Logout';
import Home from '../components/common/Home';
import RouteContainer from './RouteContainer';

import manageRoutes from '../components/manage/routes';
import performingRoutes from '../components/perform/routes';
import groupRoutes from '../components/groups/routes';
import UserProfile from '../components/common/UserProfile';
import AppErrorBoundary from '../components/common/AppErrorBoundary';
import AdminDashboard from '../components/admin/AdminDashboard';
import { Roles } from '../stores/Auth';
import Privacy from '../components/common/Privacy';
import Terms from '../components/common/Terms';
import LTIDeeplink from '../components/lti/LTIDeeplink';
import LTIAdmin from '../components/lti/LTIAdmin';
import LoginInNewTab from '../components/common/LoginInNewTab';
import LTIConfigurationGuide from '../components/lti/LTIConfigurationGuide';
import HeaderForIframe from '../components/common/HeaderForIframe';
import Header from '../components/common/Header';
import { isInIframe } from '../utils/iframeUtils';
import AppFooter from '../components/common/AppFooter';

function Routes() {
  const [iframe] = useState(isInIframe());

  function renderRoute(route, index) {
    return (
      <RouteContainer
        key={index}
        isPrivate={route.isPrivate}
        privileges={route.privileges}
        isAdmin={route.isAdmin}
        exact={route.exact}
        path={route.path}
        main={route.main}
        footer={route.footer}
        fullPage={route.fullPage}
      />
    );
  }

  return (
    <Router>
      <AppErrorBoundary>
        {iframe ? <HeaderForIframe /> : <Header />}
        <Switch>
          <RouteContainer exact path="/" fullPage={Home} />
          <RouteContainer exact path="/privacy" main={Privacy} />
          <RouteContainer exact path="/terms" main={Terms} />
          <RouteContainer isPrivate path="/logout" main={Logout} />
          <RouteContainer path="/login-outside" main={LoginInNewTab} />
          <RouteContainer isPrivate path="/profile" main={UserProfile} />
          <RouteContainer
            isPrivate
            path="/admin"
            main={AdminDashboard}
            roles={[Roles.ADMIN]}
          />
          <RouteContainer path="/lti-deeplink" main={LTIDeeplink} />
          <RouteContainer
            path="/lti-configuration"
            main={LTIAdmin}
            isPrivate
            roles={[Roles.LTI_ADMIN]}
          />
          <RouteContainer
            path="/lti-guide/:platform"
            main={LTIConfigurationGuide}
            isPrivate
            roles={[Roles.LTI_ADMIN]}
          />
          {manageRoutes.map((route, index) =>
            renderRoute(route, index, ['manage'])
          )}
          {performingRoutes.map((route, index) => renderRoute(route, index))}
          {groupRoutes.map((route, index) => renderRoute(route, index))}
        </Switch>
      </AppErrorBoundary>
      <AppFooter />
    </Router>
  );
}

export default inject('auth', 'appState')(observer(Routes));
