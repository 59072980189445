import API from './API';
import { getResourceIdentifier } from './index';
import { saveAs } from 'file-saver';

const BASE_PATH = '/api/lessons';

export const fetchLessons = () => {
  return API.get(BASE_PATH);
};

export const fetchPublicLessons = params => {
  return API.get(`${BASE_PATH}/public?${new URLSearchParams(params)}`);
};

export const fetchPublicLesson = lessonId => {
  return API.get(`${BASE_PATH}/public/${lessonId}`);
};

export const fetchLesson = id => {
  return API.get(`${BASE_PATH}/${id}`);
};

export const cloneLesson = lessonId => {
  return API.post(`${BASE_PATH}/${lessonId}/clone`);
};

export const saveLesson = lesson => {
  return API.post(`${BASE_PATH}${getResourceIdentifier(lesson)}`, lesson);
};

export const deleteLesson = lessonId => {
  return API.delete(`${BASE_PATH}/${lessonId}`);
};

export const getLessonOverview = id => {
  return API.get(`${BASE_PATH}/${id}/overview`);
};

export const getLessonPDF = id => {
  return API.get(`${BASE_PATH}/${id}/pdf`, { responseType: 'blob' }).then(
    response => {
      const filename = getFilenameFromContentDisposition(response);
      saveAs(response.data, filename);
    }
  );
};

export const getLessonHTML = id => {
  return API.get(`${BASE_PATH}/${id}/html`, { responseType: 'blob' }).then(
    response => {
      const filename = getFilenameFromContentDisposition(response);
      saveAs(response.data, filename);
    }
  );
};

function getFilenameFromContentDisposition(response) {
  for (const header in response.headers) {
    if (
      response.headers.hasOwnProperty(header) &&
      header.toLowerCase() === 'content-disposition'
    ) {
      const matches = response.headers[header].match(
        /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/
      );
      if (matches && matches.length >= 2) {
        return matches[1];
      }
    }
  }
  return `mastery-lesson-${new Date().getTime()}.pdf`;
}

export const publishLesson = (
  lessonId,
  {
    taskName,
    autoEnrollmentGroup,
    isAuthenticationRequired,
    startTime,
    endTime,
    allowLatePerformance
  }
) => {
  return API.post(`${BASE_PATH}/${lessonId}/publish`, {
    taskName,
    autoEnrollmentGroup,
    isAuthenticationRequired,
    startTime,
    endTime,
    allowLatePerformance
  });
};
