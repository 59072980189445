import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { List } from 'semantic-ui-react';

import PreviewSegment from '../../../../common/PreviewSegment';
import OrderedAnswer from './OrderedAnswer';
import AddButton from '../../AddButton';
import { withTranslation } from 'react-i18next';

class OrderQuestion extends Component {
  moveAnswer = (dragIndex, hoverIndex) => {
    this.props.question.moveOrderedAnswer(dragIndex, hoverIndex);
  };

  handleAddAnswer = () => {
    this.props.question.addOrderedAnswer('');
  };

  render() {
    const { t } = this.props;
    const answers = this.props.question.actuallyOrderedAnswers;
    return (
      <List>
        <AddButton
          disabled={!this.props.question.isSaved}
          disabledPopupMessage={t(
            'lessonEdit.questions.orderQuestion.disabledPopupMsg'
          )}
          content={t('lessonEdit.questions.orderQuestion.addAnswerBtn')}
          onClick={this.handleAddAnswer}
        />
        {answers.map((answer, idx) => (
          <List.Item key={answer.id} style={{ cursor: 'move' }}>
            <OrderedAnswer
              id={answer.id}
              index={idx}
              answer={answer}
              moveAnswer={this.moveAnswer}
            />
          </List.Item>
        ))}
        <PreviewSegment />
      </List>
    );
  }
}

OrderQuestion.propTypes = {
  question: PropTypes.object.isRequired
};

export default withTranslation()(observer(OrderQuestion));
