import { action, decorate, observable } from 'mobx';
import debounce from 'lodash/debounce';
import remove from 'lodash/remove';
import { getI18n } from 'react-i18next';
import { axiosInstance } from '../api/API';
import { initLTIListener, isLTILaunchError } from '../utils/ltiUtils';

class AppState {
  user = {};
  isAuthError = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initInterceptors();
    this.handleLTIEvent = this.handleLTIEvent.bind(this);
    initLTIListener(this.handleLTIEvent);
  }

  initInterceptors() {
    // axiosInstance.interceptors.request.use(config => { // TODO
    //   this.setLoading(true);
    //   return config;
    // });

    axiosInstance.interceptors.response.use(
      response => {
        this.setLoading(false);
        return response;
      },
      axiosError => {
        this.setLoading(false);
        if (
          axiosError &&
          axiosError.response &&
          axiosError.response.status === 401
        ) {
          this.setAuthError(true);
        }
        if (
          axiosError &&
          axiosError.response &&
          axiosError.response.status === 500
        ) {
          this.addMessage({
            error: true,
            text: getI18n().t('notification.genericError')
          });
        }
        if (
          !axiosError ||
          !axiosError.response ||
          !axiosError.response.status
        ) {
          return Promise.reject(
            `${axiosError.config.method.toUpperCase()} ${axiosError.config.url}`
          );
        }
        const error = Error(
          `${
            axiosError.response.status
          } ${axiosError.config.method.toUpperCase()} ${axiosError.config.url}`
        );
        error.name = 'AxiosError';
        return Promise.reject(error);
      }
    );
  }

  setAuthError(isAuthError) {
    this.isAuthError = isAuthError;
  }

  // MESSAGES
  activeMessages = [];
  persistedMessages = [];

  addMessage = message => {
    if (message) {
      if (message.isPersistent) {
        if (!this.persistedMessages.some(it => it.text === message.text)) {
          this.persistedMessages.push(message);
        }
      } else {
        if (!this.activeMessages.some(it => it.text === message.text)) {
          this.activeMessages.push(message);
          setTimeout(() => {
            this.removeActiveMessage(message);
          }, 4000);
        }
      }
    }
  };

  removeActiveMessage = message => {
    remove(this.activeMessages, message);
  };

  removePersistedMessage = message => {
    remove(this.persistedMessages, message);
  };

  // LOADER
  loading = false;

  setLoading = debounce(loading => {
    this._setLoading(loading);
  }, 500); // Only set loading state if loading has not ended within 500ms

  _setLoading(loading) {
    this.loading = loading;
  }

  handleLTIEvent(ltiEvent) {
    if (!ltiEvent) return;
    if (ltiEvent.status && isLTILaunchError(ltiEvent.originEventType)) {
      this.addMessage({
        error: true,
        text: getI18n().t('notification.ltiLaunchError'),
        isPersistent: true
      });
    }
  }
}

export default decorate(AppState, {
  user: observable,
  isAuthError: observable,
  activeMessages: observable,
  persistedMessages: observable,
  loading: observable,

  setAuthError: action,
  addMessage: action,
  removeActiveMessage: action,
  _setLoading: action,
  handleLTIEvent: action
});
