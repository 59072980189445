import striptags from 'striptags';

const getMaterialIconName = materialType => {
  switch (materialType) {
    case 'VIDEO':
      return 'video';
    case 'IMAGE':
      return 'image';
    case 'TEXT':
      return 'file text outline';
    case 'PDF':
      return 'file pdf';
    case 'EMBED':
      return 'file code outline';
    default:
      return 'sticky note outline';
  }
};

const getQuestionIconName = questionType => {
  switch (questionType) {
    case 'CHOOSE':
      return 'checkmark';
    case 'ORDER':
      return 'ordered list';
    case 'GROUP':
      return 'columns';
    case 'OPEN':
      return 'edit';
    default:
      return 'question';
  }
};

const getAssetTypeIconName = assetType => {
  switch (assetType) {
    case 'PDF':
      return 'file pdf';
    case 'IMAGE':
      return 'image';
    default:
      return '';
  }
};

const getPerformableLessonUrl = (currentUrl, lessonId) => {
  let parts = currentUrl.split('/');
  return parts[0] + '//' + parts[2] + '/perform/' + lessonId;
};

const isDescriptionEmpty = description => {
  return !!(
    !description ||
    !/\S/.test(description) ||
    !/\S/.test(striptags(description))
  );
};

export {
  getMaterialIconName,
  getQuestionIconName,
  getPerformableLessonUrl,
  getAssetTypeIconName,
  isDescriptionEmpty
};
