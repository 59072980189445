import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Divider, Header, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Helmet } from 'react-helmet';
import LessonsTable from '../../LessonsTable';
import { withTranslation } from 'react-i18next';

class LessonsList extends Component {
  state = {
    newLessonId: null,
    newLessonName: ''
  };

  handleChange = (e, { value }) => {
    this.setState({ newLessonName: value });
  };

  createLesson = () => {
    const newLessonName =
      this.state.newLessonName.length === 0
        ? 'Uus tund'
        : this.state.newLessonName;
    this.props.lessonOverviewStore
      .createLesson(newLessonName)
      .then(({ data }) => {
        this.setState({ newLessonId: data.id });
      });
  };

  componentDidMount() {
    this.props.lessonOverviewStore.loadLessons();
  }

  render() {
    const { newLessonName, newLessonId } = this.state;
    const { lessons } = this.props.lessonOverviewStore;
    const { t } = this.props;
    return (
      <React.Fragment>
        {newLessonId && (
          <Redirect to={{ pathname: `/lessons/${newLessonId}/edit` }} />
        )}
        <Helmet>
          <title>Mastery • Tundide haldus</title>
        </Helmet>
        <Header>{t('lessons.header')}</Header>
        <Input
          fluid
          type="text"
          placeholder={t('lessons.createPlaceholder')}
          value={newLessonName}
          onChange={this.handleChange}
          action
        >
          <input />
          <Button
            icon="add"
            positive
            basic
            content={t('lessons.createBtn')}
            onClick={this.createLesson}
          />
          <Button
            as={Link}
            to="/market"
            icon="search"
            content={t('lessons.searchPublic')}
          />
        </Input>
        <Divider />
        <LessonsTable lessons={lessons} showDetailLink showTaskCopyCount />
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  inject('lessonOverviewStore', 'auth')(observer(LessonsList))
);
