import API from './API';

const BASE_PATH = '/api/assets';
const CLOUDINARY__CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
const CLOUDINARY__API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY;
const CLOUDINARY__UPLOAD_PATH = `https://api.cloudinary.com/v1_1/${CLOUDINARY__CLOUD_NAME}/upload`;

export const getUserAssets = type => {
  return API.get(BASE_PATH, {
    params: { type }
  });
};

export const saveAsset = (file, filename, type) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('assetName', filename);
  formData.append('assetType', type);
  return API.post(BASE_PATH, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

/** Might consider using Cloudinary Upload Widget instead of making requests like this */
export const saveToCloudinary = async ({ file, filename }) => {
  const timestamp = new Date().getTime();
  const filenameWithoutExt = filename.substring(0, filename.lastIndexOf('.'));
  const public_id = `${filenameWithoutExt}_${timestamp}`;
  const paramsToSign = {
    timestamp,
    public_id
  };
  const signature = (await getCloudinarySignature(paramsToSign)).data;
  const form = new FormData();
  form.append('api_key', CLOUDINARY__API_KEY);
  form.append('timestamp', timestamp.toString());
  form.append('public_id', public_id);
  form.append('signature', signature);
  form.append('file', file);
  // TODO Add metadata such as uploader?
  const response = await fetch(CLOUDINARY__UPLOAD_PATH, {
    method: 'POST',
    body: form
  });
  const { secure_url } = await response.json();
  // TODO Save other returned metadata from Cloudinary to our asset_reference table?
  await saveCloudinaryReference(secure_url, 'IMAGE', filename);
  return secure_url;
};

function getCloudinarySignature(params) {
  return API.post(`${BASE_PATH}/cloudinary-signature`, params);
}

export const saveCloudinaryReference = (url, type, filename) => {
  return API.post(`${BASE_PATH}/reference`, {
    url,
    type,
    filename
  });
};
