import React from 'react';
import { Form } from 'semantic-ui-react';

function ChoiceAnswer(props) {
  const { answeredQuestion } = props;

  return (
    <Form>
      {answeredQuestion.answer.options &&
        answeredQuestion.answer.options.map(answerOption => (
          <Form.Group key={`${answeredQuestion.eventId}ao${answerOption.id}`}>
            <Form.Checkbox
              style={{
                cursor: 'default'
              }}
              error={!answerOption.isCorrect}
              checked={answerOption.isChecked}
              label={answerOption.text}
            />
          </Form.Group>
        ))}
    </Form>
  );
}

export default ChoiceAnswer;
