import React from 'react';
import ReactDOM from 'react-dom';
import RootStore from './stores/RootStore';
import { Provider } from 'mobx-react';
import App from './components/common/App';
import 'moment/locale/et';
import 'semantic-ui-less/semantic.less';
import 'react-datetime/css/react-datetime.css';
import './index.css';
import './i18n';
import SanitizeUrl from './components/common/SanitizeUrl';
import * as Sentry from '@sentry/browser';
import Mousetrap from 'mousetrap';
import { pdfjs } from 'react-pdf';
import { QueryClient, QueryClientProvider } from 'react-query';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    // TODO check AppErrorBoundary
    release: 'mastery@' + process.env.REACT_APP_VERSION,
    dsn: 'https://11d0bde4dfa541398f7e596de346a542@sentry.io/1304939'
  });
}

const rootStore = new RootStore();

// Allow Mousetrap bindings to happen while in input fields,
// see 'Text fiels' and 'stopCallback' sections in docs: https://craig.is/killing/mice
Mousetrap.stopCallback = () => false;

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe');
//   axe(React, ReactDOM, 1000);
// }

const queryClient = new QueryClient();

const render = () => {
  ReactDOM.render(
    <SanitizeUrl>
      <QueryClientProvider client={queryClient}>
        <Provider rootStore={rootStore} {...rootStore.stores}>
          <App />
        </Provider>
      </QueryClientProvider>
    </SanitizeUrl>,
    document.getElementById('root')
  );
};

render();
