import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Container } from 'semantic-ui-react';

import CustomQuill, { TYPE } from '../../common/CustomQuill';
import PerfChooseQuestion from './PerfChooseQuestion';
import PerfOrderQuestion from './PerfOrderQuestion';
import PerfGroupQuestion from './PerfGroupQuestion';
import PerfOpenQuestion from './PerfOpenQuestion';

class PerfQuestion extends Component {
  render() {
    const { question } = this.props;
    return (
      <Container>
        <React.Fragment>
          <CustomQuill
            value={question.text}
            modules={{ toolbar: false }}
            variation={TYPE.ACTIVE}
            style={{ marginBottom: '1em' }}
            readOnly
          />
          {question.type === 'CHOOSE' && (
            <PerfChooseQuestion question={question} />
          )}
          {question.type === 'ORDER' && (
            <PerfOrderQuestion question={question} />
          )}
          {question.type === 'GROUP' && (
            <PerfGroupQuestion question={question} />
          )}
          {question.type === 'OPEN' && <PerfOpenQuestion question={question} />}
        </React.Fragment>
      </Container>
    );
  }
}

export default observer(PerfQuestion);
