import React from 'react';
import CopyToClipboardButton from '../common/CopyToClipboardButton';
import { useTranslation } from 'react-i18next';

function TaskLinkButton(props) {
  const [t] = useTranslation();
  const { taskCode } = props;

  return (
    <CopyToClipboardButton
      buttonLabel={t('tasks.taskLinkBtn')}
      textToCopy={window.location.hostname + '/perform/' + taskCode}
    />
  );
}

export default TaskLinkButton;
