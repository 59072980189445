import React from 'react';

import PerformableLesson from './common/PerformableLesson';
import UserPerformances from './UserPerformances';
import InvitationService from './common/InvitationService';
import RecallDashboard from './RecallDashboard';

const performanceRoutes = [
  {
    isPrivate: false,
    exact: true,
    path: '/perform/:taskCode',
    fullPage: () => <PerformableLesson />
  },
  {
    isPrivate: false,
    exact: true,
    path: '/perform/public/:taskCode',
    fullPage: () => <PerformableLesson />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/performances',
    main: () => <UserPerformances />
  },
  {
    isPrivate: false,
    exact: true,
    path: '/invitation/:invitationId',
    main: () => <InvitationService />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/preview/:lessonId',
    fullPage: () => <PerformableLesson />
  },
  {
    isPrivate: true,
    exact: true,
    path: '/recall',
    main: () => <RecallDashboard />
  }
];

const performingRoutes = [].concat(performanceRoutes);

export default performingRoutes;
