import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

function CopyToClipboardButton(props) {
  const [t] = useTranslation();
  const buttonRef = useRef(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { buttonLabel, textToCopy, message, icon, onCopy } = props;

  function openPopup() {
    setPopupOpen(true);
    setTimeout(() => setPopupOpen(false), 1000);
  }

  return (
    <CopyToClipboard text={textToCopy} onCopy={onCopy}>
      <Button
        icon
        onClick={openPopup}
        aria-label={`Kopeeri tekst: ${textToCopy}`}
      >
        <div ref={buttonRef}>
          <Icon name={icon ? icon : 'copy'} />
          <span> {buttonLabel}</span>
        </div>
        <Popup
          position="top center"
          content={message ? message : t('copyToClipboardBtn.copiedMsg')}
          context={buttonRef}
          open={isPopupOpen}
        />
      </Button>
    </CopyToClipboard>
  );
}

CopyToClipboardButton.propTypes = {
  buttonLabel: PropTypes.string,
  textToCopy: PropTypes.string
};

export default CopyToClipboardButton;
