import React, { Component } from 'react';
import { Button, Divider, Dropdown, Form, List } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { withRouter } from 'react-router';
import API from '../../../api/API';
import CopyToClipboardButton from '../../common/CopyToClipboardButton';

class GroupForm extends Component {
  state = {
    users: [],
    prevSearchQuery: '',
    groupUsers: []
  };

  handleRemove = userId => {
    this.props.groupStore.removeEmail(userId);
  };

  handleChange = (e, target) => {
    this.props.groupStore.updateGroupName(target.value);
  };

  handleSearchChange = debounce(async (e, { searchQuery }) => {
    if (searchQuery.length < 3) return;
    if (
      searchQuery.length > this.state.prevSearchQuery.length &&
      this.state.prevSearchQuery.length > 0 &&
      searchQuery.startsWith(this.state.prevSearchQuery)
    ) {
      // No need to fetch from server if filter has been constrained, for example:
      // krist -> kristjan
      return;
    }
    const { data } = await API.get(`/api/users?search=${searchQuery}`);
    this.setState({
      users: this.filterSelected(data),
      prevSearchQuery: searchQuery
    });
  }, 500);

  filterSelected = users =>
    users.filter(
      user =>
        !this.props.groupStore.group.users.find(u => u.userId === user.userId)
    );

  handleSelect = (e, { value }) => {
    const selectedUser = this.state.users.find(user => user.userId === value);
    this.setState({
      groupUsers: this.state.groupUsers.concat(selectedUser),
      users: [],
      prevSearchQuery: ''
    });
    this.props.groupStore.addEmail(selectedUser);
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.groupStore.getGroupById(this.props.match.params.id);
    }
    this.setState({
      groupUsers: this.props.groupStore.group.users
    });
  }

  render() {
    const group = this.props.groupStore.group;
    const { users } = this.state;
    return (
      <Form>
        <Form.Group>
          <Form.Input
            width="16"
            label="Grupi nimi"
            value={group.name}
            name="groupName"
            size="big"
            onChange={this.handleChange}
          />
        </Form.Group>
        {group.code && (
          <Form.Group>
            <Form.Input
              label="Jagamiskood"
              value={group.code}
              name="code"
              readOnly
              action={
                <CopyToClipboardButton
                  buttonLabel="Kopeeri"
                  textToCopy={group.code}
                />
              }
            />
          </Form.Group>
        )}
        <Divider horizontal>Lisa gruppi liikmeid</Divider>
        <Dropdown
          placeholder="Kirjuta siia e-posti aadress"
          search
          selection
          options={users.map(user => ({
            text: user.email,
            value: user.userId
          }))}
          onSearchChange={this.handleSearchChange}
          onChange={this.handleSelect}
        />
        <List relaxed>
          {group.users.map(user => (
            <List.Item key={user.userId}>
              <List.Content floated="right">
                <Button negative onClick={() => this.handleRemove(user.userId)}>
                  Eemalda
                </Button>
              </List.Content>
              <List.Content>
                <List.Header>{user.email}</List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Form>
    );
  }
}

export default withRouter(inject('groupStore')(observer(GroupForm)));
