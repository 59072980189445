import { action, computed, decorate, observable, runInAction } from 'mobx';
import debounce from 'lodash/debounce';
import remove from 'lodash/remove';
import uniqueId from 'lodash/uniqueId';
import AnswerOption from './answerOption';
import AnswerGroup from './answerGroup';
import OrderedAnswer from './orderedAnswer';
import { deleteQuestion, saveQuestion } from '../../../api';

class Question {
  id = null;
  stage = null;
  text = '';
  type = null;

  answerOptions = [];
  orderedAnswers = [];
  answerGroups = [];

  store = null;

  constructor(store, id, stage, json) {
    this.id = id;
    this.store = store;
    this.stage = stage;
    this.updateFromJson(json);
  }

  isValid() {
    return !(!this.type || !this.text);
  }

  persistChanges = debounce(async () => {
    if (!this.isValid()) return;
    const { data } = await saveQuestion(this.asJson);
    if (typeof this.id === 'string') {
      this.id = data.id;
    }
  }, 500);

  updateFromJson(json) {
    this.text = json.text ? json.text : '';
    this.type = json.type;
    this.answerOptions = json.answerOptions
      ? json.answerOptions.map(
          answerOption =>
            new AnswerOption(this.store, answerOption.id, this, answerOption)
        )
      : [];
    this.orderedAnswers = json.orderedAnswers
      ? json.orderedAnswers.map(
          orderedAnswer =>
            new OrderedAnswer(this.store, orderedAnswer.id, this, orderedAnswer)
        )
      : [];
    this.answerGroups = json.answerGroups
      ? json.answerGroups.map(
          answerGroup =>
            new AnswerGroup(this.store, answerGroup.id, this, answerGroup)
        )
      : [];
  }

  get asJson() {
    return {
      id: typeof this.id === 'string' ? null : this.id,
      stageId: this.stage.id,
      type: this.type,
      text: this.text
    };
  }

  get actuallyOrderedAnswers() {
    // TODO naming :S
    return this.orderedAnswers.sort((a, b) => a.orderNr - b.orderNr);
  }

  get isSaved() {
    return (!!this.text && !!this.type) || typeof this.id === 'number';
  }

  get isFilled() {
    return (
      this.orderedAnswers.length > 0 ||
      this.answerGroups.length > 0 ||
      this.answerOptions.length > 0 ||
      this.type === 'OPEN'
    );
  }

  updateField(name, value, persistChanges = true) {
    this[name] = value;
    if (persistChanges) this.persistChanges();
  }

  moveOrderedAnswer(from, to) {
    const movedAhead = from < to;
    const movedBehind = from > to;
    const answersToAdjust = [];
    this.actuallyOrderedAnswers.forEach((oa, index) => {
      let increment = movedAhead && index > from && index <= to;
      let decrement = movedBehind && index >= to && index < from;
      if (increment || decrement) {
        answersToAdjust.push(oa);
      }
    });
    const movedAnswer = this.actuallyOrderedAnswers[from];
    movedAnswer.updateField('orderNr', to + 1);
    answersToAdjust.forEach(answer => {
      const newOrderNr = movedAhead ? answer.orderNr - 1 : answer.orderNr + 1;
      // Only make server request for actually moved item, adjustments are made server-side
      answer.updateField('orderNr', newOrderNr, false);
    });
  }

  async remove() {
    if (typeof this.id !== 'string') {
      await deleteQuestion(this.id);
    }
    runInAction(() => remove(this.stage.questions, q => q.id === this.id));
  }

  async addAnswerOption(text, correct) {
    let answerOption = new AnswerOption(this.store, uniqueId('ao'), this, {
      correct,
      text
    });
    await answerOption.save();
    runInAction(() => this.answerOptions.push(answerOption));
  }

  async addOrderedAnswer(text) {
    let orderedAnswer = new OrderedAnswer(this.store, uniqueId('oa'), this, {
      orderNr: this.orderedAnswers.length + 1,
      text
    });
    await orderedAnswer.persistChanges();
    runInAction(() => this.orderedAnswers.push(orderedAnswer));
  }

  removeOrderedAnswer(answer) {
    const { orderNr } = answer;
    const answersToPullForward = this.orderedAnswers.filter(
      oa => oa.orderNr > orderNr
    );
    remove(this.orderedAnswers, oa => oa.id === answer.id);
    answersToPullForward.forEach(oa =>
      oa.updateField('orderNr', oa.orderNr - 1, false)
    );
  }

  addAnswerGroup(text) {
    let answerGroup = new AnswerGroup(this.store, uniqueId('ag'), this, {
      text
    });
    runInAction(() => this.answerGroups.push(answerGroup));
  }
}

export default decorate(Question, {
  id: observable,
  stage: observable,
  text: observable,
  type: observable,
  answerOptions: observable,
  orderedAnswers: observable,
  answerGroups: observable,

  asJson: computed,
  actuallyOrderedAnswers: computed,
  isSaved: computed,
  isFilled: computed,

  updateField: action,
  moveOrderedAnswer: action,
  removeOrderedAnswer: action
});
