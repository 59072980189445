import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Container, Loader, Message, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import AppErrorBoundary from '../components/common/AppErrorBoundary';
import { Trans, useTranslation } from 'react-i18next';

const LoadingMessage = styled(Message)`
  &&&&& {
    position: absolute;
    top: 2em;
    right: 2em;
    z-index: 9999;
  }
`;

const PrivateRoute = inject('auth')(
  observer(({ component, roles, auth, ...rest }) => {
    const { isInitialized, isAuthenticated } = auth;
    const [t] = useTranslation();
    const [roleCheckStatus, setRoleCheckStatus] = useState('LOADING');

    useEffect(
      () => {
        if (isInitialized && !isAuthenticated) {
          auth.login(window.location.pathname);
        }
      },
      [isInitialized, isAuthenticated, auth]
    );

    useEffect(
      () => {
        if (!isInitialized) return;
        if (!roles || !roles.length) {
          setRoleCheckStatus('PASSED');
        } else {
          auth.hasRbacRoles(roles).then(hasRoles => {
            setRoleCheckStatus(hasRoles ? 'PASSED' : 'FAILED');
          });
        }
      },
      [isInitialized, roles, auth]
    );

    if (roleCheckStatus === 'FAILED') {
      return (
        <Message negative>
          <Message.Header>{t('privateRoute.forbidden.header')}</Message.Header>
          <Message.Content>
            <Trans i18nKey="privateRoute.forbidden.content">
              Avalehele naasemiseks vajuta <a href="/">siia</a>.
            </Trans>
          </Message.Content>
        </Message>
      );
    }

    return !isInitialized || roleCheckStatus === 'LOADING' ? (
      <div style={{ height: '50px' }}>
        <Loader active />
      </div>
    ) : (
      <Route
        {...rest}
        render={props => React.createElement(component, props)}
      />
    );
  })
);

const RouteContainer = inject('appState')(
  observer(props => {
    const footerStyle = {
      backgroundColor: '#d3d3d3'
    };
    return (
      <AppErrorBoundary>
        {props.appState.loading && (
          <LoadingMessage info>Loading...</LoadingMessage>
        )}

        {props.fullPage && ( // DIY full page layout
          <main>
            {props.isPrivate ? (
              <PrivateRoute
                privileges={props.privileges}
                roles={props.roles}
                exact={props.exact}
                path={props.path}
                component={props.fullPage}
              />
            ) : (
              <Route
                exact={props.exact}
                path={props.path}
                component={props.fullPage}
              />
            )}
          </main>
        )}

        {!props.fullPage && ( // Managed layout with main segment that has constrained width
          <Container as="main">
            <Segment.Group>
              <Segment>
                {props.isPrivate ? (
                  <PrivateRoute
                    privileges={props.privileges}
                    roles={props.roles}
                    exact={props.exact}
                    path={props.path}
                    component={props.main}
                  />
                ) : (
                  <Route
                    exact={props.exact}
                    path={props.path}
                    component={props.main}
                  />
                )}
              </Segment>
              {props.footer && (
                <Segment clearing style={footerStyle}>
                  <Route
                    exact={props.exact}
                    path={props.path}
                    component={props.footer}
                  />
                </Segment>
              )}
            </Segment.Group>
          </Container>
        )}
      </AppErrorBoundary>
    );
  })
);

RouteContainer.propTypes = {
  exact: PropTypes.bool,
  isPrivate: PropTypes.bool,
  privileges: PropTypes.array,
  path: PropTypes.string.isRequired,
  main: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.object
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.object
  ]),
  fullPage: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.object
  ])
};

export default RouteContainer;
